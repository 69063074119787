<template lang="pug">
article.product-card(:class="{ buyer: isBuyer , cartable: sku && sku.cartable && !isPreApprovalBuyer }")
  template(v-if="!sku")
    a.image
      .contain.clp
    .caption
      a.product.clp.w-7 &nbsp;
      a.sku.clp.w-4 &nbsp;
    .metas.flex
      .case-price
        .clp.mw-3 &nbsp;
      .skus
        .clp.mw-2 &nbsp;

  template(v-else)
    nuxt-link.image(
      :to="skuLink(sku)"
      :title="sku.name"
      v-click="() => $amplitude.trackProduct('MOV_ALERT_MODAL')")
      .contain(v-image="imageUrl")
    .caption.pf-ellipsis
      .flex.fdc
        nuxt-link.product.pf-ellipsis(
          v-tooltip="sku.product_name"
          v-ellipsis
          :to="productLink"
          v-text="sku.product_name"
          v-click="() => $amplitude.trackProduct('MOV_ALERT_MODAL')")
        nuxt-link.sku.pf-ellipsis(
          v-tooltip="sku.name"
          v-ellipsis
          :to="skuLink(sku)"
          v-text="sku.name"
          v-click="() => $amplitude.trackProduct('MOV_ALERT_MODAL')")

    .metas(v-if="!isPreApprovalBuyer")
      template(v-if="sku.case_price_cents && !isHeadBuyer")
        .promotion-price.flex.aic(v-if="sku.cartPromotions && sku.cartPromotions.length")
          .current {{ sku.case_price_cents | promotionPrice(sku.cartPromotions[0]) }}
          .old {{ sku.case_price_cents | cents }}
          PriceChangeStamp(:variant="sku")
        .case-price(v-else)
          span {{ sku.case_price_cents | cents }}&nbsp;
            PriceChangeStamp(:variant="sku")
    .quantity
      el-input-number.pf-block(
        v-model.number="form.quantity"
        :min="0"
        :step="1"
        :disabled="sku.cartable === false"
        @change="val => debounceAddItemsToCart(sku, val)"
        size="small")
</template>

<script>
import { mapGetters } from 'vuex'
import { debounce } from 'lodash'
import { DEBOUNCE_DELAY } from '~/settings'
import ProductTagStamp from '~/components/stamps/ProductTagStamp'
import PromotionTag from '~/components/tags/PromotionTag'
import ExpressTag from '~/components/tags/ExpressTag'
import PriceChangeStamp from '~/components/stamps/PriceChangeStamp'

export default {
  components: {
    ProductTagStamp,
    PromotionTag,
    ExpressTag,
    PriceChangeStamp
  },

  props: {
    sku: { type: Object, default: () => {} }
  },

  data () {
    return {
      debounceAddItemsToCart: () => {},
      saving: false,
      form: { quantity: 0 }
    }
  },

  computed: {
    ...mapGetters({
      isBuyer: 'authentication/isBuyer',
      isHeadBuyer: 'authentication/isHeadBuyer',
      isPreApprovalBuyer: 'authentication/isPreApprovalBuyer',
      items: 'cart/items'
    }),

    productLink () {
      return this.$links
        .product({
          id: this.sku.product_id,
          name: this.sku.product_name,
          brand: this.sku.brand_name,
          query: {
            'q[tag_ids]': this.$route.query['q[tag_ids]'] || undefined
          }
        })
    },

    imageUrl () {
      const { image_urls, image_url, product_variant_small_image_url, primary_image_url, photo_url } = this.sku
      return image_urls?.product || image_url || product_variant_small_image_url || primary_image_url || photo_url
    },

    promotionType () {
      const { promotionType } = this.$options.filters
      if (!this.sku.promotion_types) {
        return null
      }
      if (this.sku.promotion_types.length === 1) {
        return promotionType(this.sku.promotion_types[0])
      } else {
        return 'Promotions'
      }
    }
  },

  mounted () {
    this.debounceAddItemsToCart = debounce(this.addItemsToCart, DEBOUNCE_DELAY)
  },

  methods: {
    skuLink (sku) {
      return this.$links.product({
        id: sku.product_id,
        name: sku.product_name,
        variant: sku.name,
        variantId: sku.id
      })
    },

    addItemsToCart (variant, quantity) {
      // this.$amplitude.track('CLICK_ADD_TO_CART', { zone: 'CART_PAGE_MOV_SOS_POPUP_QUANTITY_BOX', quantity })
      const cartItem = this.items.find(i => i.product_variant_id === variant.id)
      if (cartItem) {
        if (quantity === 0) {
          return this.$store.dispatch('cart/removeItem', cartItem).then(() => {
            this.$set(this.sku, 'cartPromotions', [])
          })
        }

        return this.$store.dispatch('cart/updateItem', { id: cartItem.id, quantity }).then((updatedCart) => {
          this.updateLineItemPromotions(updatedCart)
        })
      }

      return this.$workflow.confirmShortDatePromotionIfNeeded({ variants: [variant] })
        .then(() => {
          this.$store.dispatch('cart/addItems', {
            cart_items: [{ quantity, variants_region_id: variant.variants_region_id }]
          }).then((updatedCart) => {
            this.updateLineItemPromotions(updatedCart)
          })
        }).catch(() => {
          this.$set(this.form, 'quantity', 0)
        })
    },

    updateLineItemPromotions (cart) {
      this.$workflow.notify('Cart updated!', { topmost: true })
      const skuInCart = cart?.order?.line_items_attributes?.find(c => c.product_variant_id === this.sku.id)
      this.$set(this.sku, 'cartPromotions', skuInCart.promotions || [])
    }
  }

}
</script>

<style lang="scss" scoped>
article.product-card {
  background: white;
  box-sizing: content-box;
  border: 1.5px solid transparent;
  border-radius: $--radius;
  border-bottom: $--border-light;

  &:hover {
    z-index: 1;
    outline: 1.5px solid $--color-primary;
    border-radius: $--radius;
  }
  .image {
    display: block;
    padding: 10px;
    position: relative;
    .contain {
      width: 100%;
      height: 0;
      padding-top: 100%;
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
      border-radius: calc($--radius / 2);
    }
  }
  .caption {
    padding: 0 12px 6px;
    .product {
      font-size: 14px;
      line-height: 1.2em;
      font-weight: $--font-weight-bold;
      color: $--color-text-regular;
    }
    .sku {
      color: $--color-primary;
      font-size: 14px;
      line-height: 1.2em;
    }
  }
  .metas {
    font-size: 14px;
    line-height: 1em;
    padding: 6px 12px 12px;
    @include media(md) {
      font-size: 16px;
    }
    em {
      font-size: 12px;
      font-style: normal;
      color: $--color-text-secondary;
      margin-left: .35em;
    }
    .promotion-price {
      gap: 5px;
      .current {
        color: $--color-primary
      }
      .old {
        color: $--color-text-secondary;
        text-decoration: line-through;
        font-size: 13px;
        line-height: 1.4em
      }
    }
  }
  .quantity {
    padding: 0 12px 16px 12px;
  }
  .sku-name {
    margin-top: 0.5em;
    span {
      min-width: 20px;
      font-size: 14px;
      line-height: 1.2em;
    }
  }
}
</style>
