<template lang="pug">
el-popover(ref="popover" width="375" placement="bottom" popper-class="p-0" @show="handleShow" :visible-arrow="false")
  NotificationCard(ref="notiCard" show-filter show-header)
  el-button.bell-button(circle type="secondary" slot="reference" @click="$amplitude.track('CLICK_VENDOR_DASHBOARD_NOTIFICATIONS_POPOVER')")
    compoennt.bell-badge(is-dot :is="unreadNotifications ? 'el-badge' : 'div'")
      i.bx.bx-bell.bx-5
</template>

<script>
import { mapGetters } from 'vuex'
import NotificationCard from '~/components/notifications/NotificationCard'
export default {
  name: 'NotificationBox',

  components: {
    NotificationCard
  },

  computed: {
    ...mapGetters({
      unreadNotifications: 'notifications/unreadNotifications',
      hasChangeNotification: 'notifications/hasChangeNotification'
    })
  },
  methods: {
    handleShow () {
      if (!this.hasChangeNotification) return

      this.$refs.notiCard.setLoading(true)
      this.$refs.notiCard.reset()
      this.$refs.notiCard.debounced()
      this.$store.commit('notifications/updateHasChangeNotification', false)
    }
  }
}
</script>

<style lang="scss" scoped>
.bell-button {
  ::v-deep {
    @include media(sm-down) {
      border: none
    }
  }
}
.bell-badge {
  ::v-deep {
    @include media(md) {
      .el-badge__content{
        &.is-dot {
          width: 10px;
          height: 10px;
        }
        &.is-fixed {
          transform: translateY(-100%) translateX(175%);
        }
      }
    }
  }
}
</style>
