import { Notification, MessageBox } from 'element-ui'
import {
  ERROR_HANDLING_MANUAL,
  IS_PRODUCTION_SITE,
  TIMEOUT_MILLISECONDS,
  EMAILING_STATUS_CODE
} from '~/settings'

export default function (context) {
  const { $axios, store, route, error } = context
  $axios.defaults.timeout = TIMEOUT_MILLISECONDS

  $axios.onRequest(req => {
    const headers = store.getters['authentication/headers']
    if (headers) {
      req.headers = {
        ...req.headers,
        ...headers
      }
    }
    return req
  })

  $axios.onResponse(res => {
    // automatically show export via email alert on 202 requests
    if (res?.status === EMAILING_STATUS_CODE) {
      MessageBox({
        type: 'success',
        title: 'Requested!',
        message: 'It will take a few minutes to process your request. An email will be sent to the address on your profile when the exported file is ready to download.'
      })?.catch(e => {})
    }
  })

  $axios.onError(async e => {
    if (!e.response) {
      // eslint-disable-next-line no-console
      if (!IS_PRODUCTION_SITE) console.error(e)
      return
    }

    let errors = e.response.data.errors || e.response.data.error
    if (typeof errors === 'string') errors = [errors]
    if (Array.isArray(errors)) {
      errors = errors.map(i => {
        if (typeof i === 'string') return i
        if (i.messages && i.messages.length) return `${i.id ? `<strong>#${i.id}</strong> – ` : ''}${i.messages.join('</br>')}`
        return ''
      })
    }

    if (e.config[ERROR_HANDLING_MANUAL]) return Promise.reject(e)

    switch (e.response.status) {
      case 401: // authentication
        if (!IS_PRODUCTION_SITE)
          // eslint-disable-next-line no-console
          console.error({
            code: e.response.status,
            request: e.request.path,
            route: route.fullPath
          })

        await store.dispatch('authentication/logout', context)
        break
      case 403:
      case 404:
        if (!IS_PRODUCTION_SITE)
          // eslint-disable-next-line no-console
          console.error({
            code: e.response.status,
            request: e.request.path,
            route: route.fullPath
          })

        error({ statusCode: 404, message: 'Page not found' })
        break
      case 409: // data conflict
        break
      default: // others
        if (Array.isArray(errors))
          Notification.error({
            title: `Error ${e.response.status}`,
            dangerouslyUseHTMLString: true,
            message: errors ? errors.join('<br/>') : 'Unexpected API Error.',
            duration: 3000
          })
    }

    return Promise.reject(e)
  })
}
