<template lang="pug">
  el-dialog.delivery-instruction-modal(
    title="Delivery Instructions"
    visible
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    @close="$emit('close')")
    delivery-instruction(:order="order" :type="type")
    .modal-footer
      el-button(@click="$emit('close')" type='primary') OK
</template>
<script>
import DeliveryInstruction from '~/components/DeliveryInstruction'

export default {
  components: {
    DeliveryInstruction
  },
  props: {
    order: {
      type: Object,
      default: () => {}
    },
    invoice: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    type () {
      if (!this.invoice) return
      // eslint-disable-next-line camelcase
      return this.invoice.deliverable?.delivery_type
    }
  }
}
</script>
<style lang='scss' scoped>
.modal-footer {
  padding-top: 1.5rem;
  border-top: $--border-light;
  text-align: right;
}
</style>
