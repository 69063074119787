<script>
import { mapGetters } from 'vuex'
import Select from './BaseSelect'

export default {
  name: 'WarehouseSelect',
  extends: Select,

  props: {
    filterable: { type: Boolean, default: true },
    regionId: { type: Number, default: null }
  },

  computed: {
    ...mapGetters({
      warehouses: 'master/warehouses'
    }),

    options () {
      if (!this.regionId) return this.warehouses
      return this.warehouses.filter(i => i.region_id === this.regionId)
    }
  }
}
</script>
