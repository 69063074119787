import Vue from 'vue'

import * as GmapVue from 'gmap-vue'
import { GOOGLE_MAP_API_KEY } from '~/settings'

Vue.use(GmapVue, {
  load: {
    key: GOOGLE_MAP_API_KEY,
    libraries: 'places',
    installComponents: false
  }
})
