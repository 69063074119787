<template lang="pug">
.address-stamp
  template(v-if="inline") {{ address | address }}
  template(v-else)
    span.attn(v-if="address.attn") {{ address.attn }},&nbsp;
    span.street-1(v-if="address.street1") {{ address.street1 }},&nbsp;
    .street-2.pf-ellipsis(v-if="address.street2") {{ address.street2 }},&nbsp;
    span.city(v-if="address.city") {{ address.city }},&nbsp;
    span.state(v-if="address.address_state_id") {{ address.address_state_id | addressState }},&nbsp;
    span.state(v-if="!address.address_state_id && address.address_state_name") {{ address.address_state_name }},&nbsp;
    span.zip(v-if="address.zip") {{ address.zip }},&nbsp;
    span.country(v-if="address.country_name") {{ address.country_name }}
    .phone(v-if="showPhone && address.phone_number") {{ address.phone_number }}
</template>

<script>

export default {
  props: {
    address: {
      type: Object,
      required: true
    },

    showPhone: {
      type: Boolean,
      default: true
    },

    inline: {
      type: Boolean,
      default: false
    }
  }
}
</script>
