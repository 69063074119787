import Vue from 'vue'

export default function ({ store, isDev, app }, inject) {
  const bus = new Vue()

  if (isDev) {
    Vue.prototype.$bus = bus
    app.$bus = bus
    store.$bus = bus
  }

  inject('bus', bus)
}
