<template lang="pug">
el-dialog(
  custom-class="page__dialog sample-request-modal"
  visible
  :disabled="saving"
  :close-on-click-modal="false"
  @close="$emit('close')")

  .page__dialog-header(slot="title")
    .page__dialog-preview
      i.page__dialog-icon.bx.bxs-eyedropper
    .page__dialog-info
      .page__dialog-title Request sample
      .page__dialog-description(v-text="product.name")

  el-form(
    ref="form"
    :model="form"
    :rules="rules"
    :disabled="saving"
    label-position="top")

    el-form-item(
      label="Select flavors"
      prop="items")
      .skus-grid
        .sku(
          v-for="v, vk in skus"
          :key="vk"
          :class="{ selected: form.items.includes(v.variants_region_id) }"
          @click="toggleSku(v, vk)")
          .select(@click.stop)
            el-checkbox-group(v-model="form.items")
              el-checkbox(:label="v.variants_region_id") {{ '' }}
          .preview
            product-variant-stamp.plain(
              :data="v"
              showImage
              show-variant-name
              show-case-units
              show-badge
              show-price
              show-price-change
              show-code
              hide-pod-grow)

    el-form-item.boxed.comments(
      label="Comments"
      prop="sample_request.comment")
      el-input(
        type="textarea"
        rows="3"
        v-model="form.sample_request.comment")

    el-form-item(
      label="Shipping Address")
      el-switch(
        active-text="Use default name and store address"
        v-model="useDefaultAddress")

    .default-address(v-if="useDefaultAddress")
      .help We will deliver to your store address below:
      address(v-if="store")
        .store-name {{ store.name }}
        address-stamp(:address="store.address_attributes" inline)
        .store-phone {{ store.phone_number }}

    .custom-address(v-else)
      ShippingAddressForm(ref="addressForm" v-model="form.shipping_address")

  .page__dialog-footer(slot="footer")
    el-button(type="primary" @click="submit") Submit
</template>

<script>
import { mapGetters } from 'vuex'

import AddressStamp from '~/components/stamps/AddressStamp'
import ProductVariantStamp from '~/components/stamps/ProductVariantStamp'
import ShippingAddressForm from '~/components/samples/ShippingAddressForm'

export default {
  components: {
    AddressStamp,
    ProductVariantStamp,
    ShippingAddressForm
  },

  props: {
    product: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      useDefaultAddress: true,
      form: {
        items: [],
        sample_request: {},
        shipping_address: {}
      },
      rules: {
        items: {
          type: 'array',
          required: true,
          min: 1,
          message: 'Please select at least one flavor'
        }
      },
      saving: false
    }
  },

  computed: {
    ...mapGetters({
      currentUser: 'authentication/user',
      availabilities: 'master/productVariantAvailabilities',
      store: 'authentication/store'
    }),

    skus () {
      const allowed = this.availabilities
        .filter(i => i.samplable)
        .map(i => i.id)
      return Object.values(this.product.variants)
        .filter(i => allowed.includes(i.availability))
    },

    title () {
      return 'Sample Request - ' + this.product.name
    }
  },

  watch: {
    useDefaultAddress (val) {
      if (!val) {
        this.$nextTick(() => {
          this.$refs.focus && this.$refs.focus.focus()
        })
      }
    }
  },

  mounted () {
    this.resetSelectedSku()
  },

  methods: {
    resetSelectedSku () {
      if (this.skus.length === 1) this.toggleSku(this.skus[0])
    },

    toggleSku (sku) {
      const exists = this.form.items.includes(sku.variants_region_id)
      this.$set(
          this.form,
          'items',
          exists
            ? this.form.items.filter(id => id !== sku.variants_region_id)
            : [...this.form.items, sku.variants_region_id]
        )
    },

    validate () {
      return Promise.all([
        this.$refs.form.validate(),
        this.$refs.addressForm?.validate()
      ]).catch(e => Promise.reject(e))
    },

    submit () {
      this.validate()
        .then(() => {
          this.saving = true

          const payload = {
            ...this.form,
            product_id: this.product.id,
            items: this.form.items.map(id => ({ variants_region_id: id }))
          }

          if (this.useDefaultAddress) payload.shipping_address = undefined

          this.$api.buyer.createSampleRequest(payload)
            .then(res => {
              this.$emit('done', res)
              this.$emit('close')
            })
            .finally(() => {
              this.saving = false
            })
        })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .sample-request-modal {
    max-width: 600px;
  }
}
.default-address {
  address {
    font-style: normal;
    background: $--color-placeholder;
    border-radius: $--radius;
    padding: .75em 1.25em;
    margin-top: 1em;
    font-size: 13px;
    .store-name {
      font-weight: $--font-weight-bold;
      font-size: 14px;
    }
    .store-phone {
      color: $--color-primary;
    }
    > * {
      &:not(:first-child) {
        margin-top: .35em;
      }
    }
  }
}
.skus-grid {
  display: grid;
  grid-gap: 1em;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));

  > .sku {
    cursor: pointer;
    display: flex;
    padding: .5em;
    align-items: center;
    border: $--border-light;
    border-radius: $--radius;

    &.selected {
      border-color: $--color-primary;
    }
    > * {
      min-width: 0;
    }
    > .preview {
      flex: 1;
      ::v-deep {
        .variant-name {
          font-weight: $--font-weight-bold;
        }
      }
    }
    > .price {
      filter: blur(3px);
    }
  }
}
</style>
