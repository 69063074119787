import { datadogRum } from '@datadog/browser-rum'
import { DATADOG_RUM_CONFIG } from '~/settings'

export default function ({ store }, inject) {
  if (!DATADOG_RUM_CONFIG) {
    inject('rum', {
      resync: () => {},
      instance: {
        addAction: () => {}
      }
    })
    return
  }

  const resync = () => {
    const user = store.getters['authentication/rum']
    if (user) datadogRum.setUser(user)
    else datadogRum.removeUser()
  }

  datadogRum.init(DATADOG_RUM_CONFIG)
  datadogRum.startSessionReplayRecording()

  inject('rum', {
    resync,
    instance: datadogRum
  })
}
