<script>
import { mapGetters, mapActions } from 'vuex'
import Select from './BaseSelect'

export default {
  name: 'RegionSelect',
  extends: Select,

  props: {
    filterable: {
      type: Boolean,
      default: true
    },

    otherRegions: {
      type: Array,
      default: () => []
    },

    only: {
      type: [Array, String],
      default: 'all'
    },

    hideBlacklist: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapGetters({
      regions: 'master/regions',
      blacklistRegionsIds: 'master/blacklistRegionsIds',
      direct: 'master/directRegions',
      warehoused: 'master/warehousedRegions'
    }),

    options () {
      // Default is all regions
      let pickRegions = [...this.regions, ...this.otherRegions]

      // Filter by region types
      if (Array.isArray(this.only)) {
        pickRegions = pickRegions.filter(i => this.only.includes(i.id))
      } else if (this.only === 'warehoused') {
        pickRegions = [...this.warehoused]
      } else if (this.only === 'direct') {
        pickRegions = [...this.direct]
      }

      // Filter by blacklist regions
      if (this.hideBlacklist) {
        pickRegions = pickRegions.filter(i => !this.blacklistRegionsIds.includes(i.id))
      }

      return pickRegions
    }
  },

  methods: {
    ...mapActions({
      search: 'regions/search'
    })
  }
}
</script>
