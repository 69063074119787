<script>
import Select from './BaseSelect'

export default {
  name: 'BrandSelect',
  extends: Select,

  props: {
    api: {
      type: Boolean,
      default: true
    }
  },

  methods: {
    search (params = {}) {
      return this.$api.catalog.searchBrands(params)
    },
    fetch ({ id }) {
      return this.$api.catalog.getBrandDetails(id)
    }
  }
}
</script>
