<template lang="pug">
  .slip
    header
      slot(name="header")
        img(src="/img/logo.svg" class="logo" alt="Pod Foods")

    article
      slot

    footer
      slot(name="footer")
</template>

<script>
export default {
  name: 'Print',
  data () {
    return {}
  }
}
</script>
