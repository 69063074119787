<script>
import { mapGetters } from 'vuex'
import Select from './BaseSelect'
import { INBOUND_INVENTORY_DELIVERY_METHODS } from '~/settings'

export default {
  name: 'InboundDeliveryMethodSelect',
  extends: Select,

  props: {
    filterable: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    ...mapGetters({
      inboundInventoryDeliveryMethods: 'master/inboundInventoryDeliveryMethods',
      isLP: 'authentication/isLP'
    }),

    options () {
      // Note: Just hide "Small Package / Parcel" on Front-end for new Inbound, still show for old Inbound (Vendor Only) (spec #2704)
      if (this.isLP || this.inboundInventoryDeliveryMethods.find(i => i.id === this.value)?.name === INBOUND_INVENTORY_DELIVERY_METHODS.SMALL_PACKAGE_PARCEL) {
        return this.inboundInventoryDeliveryMethods
      }
      return this.inboundInventoryDeliveryMethods.filter(i => i.name !== INBOUND_INVENTORY_DELIVERY_METHODS.SMALL_PACKAGE_PARCEL)
    }
  }
}
</script>
