<script>
import { mapGetters } from 'vuex'
import Select from './BaseSelect'

export default {
  name: 'CountrySelect',
  extends: Select,

  props: {
    filterable: {
      type: Boolean,
      default: true
    },
    countryOptions: {
      type: Array,
      default: () => []
    }
  },

  computed: {
    ...mapGetters({
      countries: 'master/countries'
    }),
    options () {
      if (this.countryOptions.length) return this.countryOptions
      return this.countries
    }
  }
}
</script>
