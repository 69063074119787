<script>
import { DatePicker } from 'element-ui'
import { ELEMENT_DATE_FORMAT, ELEMENT_DATE_VALUE_FORMAT } from '~/settings'

const DATE_PICKER_PREFIX = 'date-picker-'
const POPPER_PREFIX = 'popper-'

export default {
  mixins: [DatePicker],

  props: {
    format: {
      type: String,
      default: ELEMENT_DATE_FORMAT
    },

    valueFormat: {
      type: String,
      default: ELEMENT_DATE_VALUE_FORMAT
    },

    placeholder: {
      type: String,
      default: 'MM/DD/YY'
    },

    popperClass: {
      type: String,
      default () {
        return `${POPPER_PREFIX}${this.seed}`
      }
    },

    pickerOptions: {
      type: Object,
      default () {
        return {
          cellClassName: date => 'date-' + this.$moment(date).format('M-D-Y')
        }
      }
    }
  },

  beforeCreate () {
    this.seed = (Date.now() * 10000 + Math.floor(Math.random() * 10000)).toString(36)
  },

  mounted () {
    this.$el.classList.add(`${DATE_PICKER_PREFIX}${this.seed}`)

    this.$refs.reference.$on('input', () => {
      const value = this.parseString(this.displayValue)

      if (this.userInput === '' || this.isValidValue(value)) {
        this.picker.value = value
      }
    })
  },

  methods: {
    blur () {
      this.refInput.forEach(input => input.blur())

      if (!this.picker.value) {
        this.picker.date = new Date()
      } else {
        this.picker.date = new Date(this.picker.value)
      }
    }
  }
}
</script>
