<template lang="pug">
el-dialog(
  custom-class="page__dialog moq-alert-modal"
  visible
  :disabled="saving"
  :close-on-click-modal="false"
  @close="handleClose")

  .page__dialog-header.page__dialog-header--separated(slot="title")
    .page__dialog-preview
      i.page__dialog-icon.bx.bxs-error

    .page__dialog-info
      .page__dialog-title MOQ Alert
      .page__dialog-description
        strong {{ added | number }}
        span &nbsp;{{ added > 1 ? 'items' : 'item' }} added to cart

  .groups
    .group(v-for="g, gk in groups" :key="gk")
      el-alert.mb-3(
        v-if="missing > 0"
        type="warning"
        size="small"
        :closable="false")
        .alert.pf-flex
          .notice(style="flex: 1;") Please add more cases to your order to meet the minimum number of cases required. This vendor may not fulfill if this minimum is not met. Thank you!
          .counter.ml-1
            strong {{ missing | number }}
            | &nbsp;{{ missing > 1 ? 'cases' : 'case' }}
            small more

      region-type-stamp.type(:type="gk" size="small")

      .skus
        .sku(
          v-for="v, vk in g"
          :key="vk")
          .preview
            product-variant-stamp.plain(
              :data="v"
              showImage
              read-only
              show-product
              show-variant-name
              show-price
              show-price-change
              show-case-units
              show-cartable
              show-promotion)
          .quantity
            el-input-number.pf-block(
              v-model="quantities[v.variants_region_id]"
              :min="0"
              :disabled="v.cartable === false"
              size="small")

  .page__dialog-footer(slot="footer")
    el-button(
      type="primary"
      @click="submit"
      :disabled="!submittable || saving") Update cart
</template>

<script>
import { groupBy, sortBy } from 'lodash'
import { REGION_TYPES } from '~/settings'

import RegionTypeStamp from '~/components/stamps/RegionTypeStamp'
import ProductVariantStamp from '~/components/stamps/ProductVariantStamp'

export default {
  components: {
    RegionTypeStamp,
    ProductVariantStamp
  },

  props: {
    product: { type: Object, required: true },
    variant: { type: Object, required: true },
    quantity: { type: Number, required: true },
    added: { type: Number, default: 0 },
    incomingPfdItem: { type: Object, default: null },
    preOrderItems: { type: Array, default: () => [] }
  },

  data () {
    return {
      saving: false,
      quantities: {}
    }
  },

  computed: {
    REGION_TYPES: () => REGION_TYPES,

    variants () {
      return Object
        .values(this.product.variants)
        .filter(i => i.region_type === this.regionType)
        .map(i => {
          if (i.id === this.incomingPfdItem?.id) {
            i.incomingQuantity = this.incomingPfdItem?.incomingQuantity
          }
          return i
        })
    },

    regionType () {
      return this.variant.region_type
    },

    submittable () {
      switch (this.regionType) {
        case REGION_TYPES.PFD:
          return this.missing <= 0
        case REGION_TYPES.WAREHOUSED:
          return this.delta !== 0
        default:
          return true
      }
    },

    cartableAvailabilities () {
      return this.$store
        .getters['master/productVariantAvailabilities']
        .filter(i => i.cartable)
        .map(i => i.id)
    },

    groups () {
      return groupBy(sortBy(this.variants, i => i.cartable ? -1 : 1), 'region_type')
    },

    delta () {
      return Object
        .values(this.quantities)
        .reduce((res, i) => res + i, 0)
    },

    missing () {
      const related = this.variants.map(i => i.variants_region_id)
      const added = this.$store.getters['cart/items']
        .filter(i => related.includes(i.variants_region_id))
        .reduce((res, i) => res + i.quantity, 0)
      const res = this.variant._moq - added - this.delta
      return Math.max(res, 0)
    }
  },

  watch: {
    variants: {
      immediate: true,
      handler (val = {}) {
        this.quantities = Object.values(val)
          .reduce((res, i) => ({
            ...res,
            [i.variants_region_id]: i.incomingQuantity || 0
          }), {})
      }
    }
  },

  methods: {
    handleTrack (event) {
      this.$amplitude.track(event, { product_id: this.product?.id })
    },

    handleClose () {
      this.handleTrack('CLICK_PRODUCT_DETAIL_ADD_TO_CART_CLOSE_MOQ_MOV_ALERT')
      this.$emit('close')
    },

    submit () {
      this.$amplitude.track('CLICK_ADD_TO_CART', {
        zone: 'MOQ_ALERT_ADD_TO_CART_POPUP',
        quantity: Object.values(this.quantities).reduce((sum, v) => sum + v, 0)
      })
      this.saving = true
      const variants = Object
        .values(this.product.variants)
        .filter(i => this.quantities[i.variants_region_id] > 0)
      const cartItems = Object
        .keys(this.quantities)
        .filter(id => this.quantities[id] > 0)
        .map(id => {
          const preOrderId = this.preOrderItems.find(i => i.variants_region_id === +id)?.id
          return {
            pre_order_line_item_id: preOrderId,
            variants_region_id: id,
            quantity: this.quantities[id]
          }
        })
      return this.$workflow
        .addItemsToCart({
          variants,
          cartItems
        })
        .then(res => {
          this.handleTrack('CLICK_PRODUCT_DETAIL_ADD_TO_CART_UPDATE_CART')
          this.$emit('done', res)
          this.$emit('close')
        })
        .finally(() => {
          this.saving = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .moq-alert-modal {
    max-width: 600px;
  }
}
.page__dialog-icon {
  color: $--color-warning;
  background: none;
  font-size: 36px;
}
.alert {
  .counter {
    font-size: 2em;
    line-height: 1em;
    font-weight: $--font-weight-bold;
    text-align: right;
    small {
      display: block;
      font-size: .5em;
      font-weight: $--font-weight-normal;
    }
  }
}
.groups {
  .group {
    &:not(:last-child) {
      margin-bottom: 1em;
    }
    .skus {
      margin-top: .5em;
      > .sku {
        display: flex;
        padding: .5em 0;
        align-items: center;
        &:not(:last-child) {
          border-bottom: $--border-light;
        }
        > * {
          min-width: 0;
        }
        > .preview {
          flex: 1;
          ::v-deep {
            .variant-name {
              font-weight: $--font-weight-bold;
            }
          }
        }
        > .quantity {
          flex: 0 120px;
          @include media(xs-only) {
            flex: 1;
          }
        }
        @include media(xs-only) {
          flex-direction: column;
          align-items: flex-start;
        }
      }
    }
  }
}
</style>
