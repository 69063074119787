
<template lang="pug">
  el-select.entity-select(
    autocomplete="new-password"
    ref="element"
    v-model="transfer"
    v-bind="{...$attrs, ...bind}"
    default-first-option
    :loading="filtering"
    :remote="api"
    :filterable="isFilterable"
    :remote-method="remote"
    :class="selectClass"
    :popper-class="popperClass"
    @change="change"
    @clear="clear"
  )
    slot
    el-option(v-if="none" :value="null" :label="none")

    el-option(v-if="limit" v-for="i, key in limit" :key="key" :label="i.product_name" :value="i.product_id")
      slot(name="option" v-bind="i")

    template(v-if="!limit")
      el-option(v-if="!api && options" v-for="i, key in options" :key="key" :label="i.product_name" :value="i.product_id")
        slot(name="option" v-bind="i")
      el-option(v-if="api && results" v-for="i, key in results" :key="key" :label="i.product_name" :value="i.product_id")
        slot(name="option" v-bind="i")
</template>

<script>
import Select from './BaseSelect'

export default {
  name: 'ProductSelect',
  extends: Select,

  props: {
    filterable: {
      type: Boolean,
      default: true
    },

    api: {
      type: Boolean,
      default: true
    }
  },

  methods: {
    params (q) {
      return {
        ...this.queries,
        term: q
      }
    },

    search (...args) {
      const service = this.$api.catalog.searchProducts(...args)

      return service || Promise.reject(new Error('No handler provided'))
    }
  }
}
</script>
