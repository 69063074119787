<template lang="pug">
#app.layout.layout--default
  announcements-bar
  static-header-bar

  #content
    nuxt

  static-footer-bar
</template>

<script>
import AnnouncementsBar from '~/components/menus/AnnouncementsBar'
import StaticHeaderBar from '~/components/menus/StaticHeaderBar'
import StaticFooterBar from '~/components/menus/StaticFooterBar'

export default {
  name: 'DefaultLayout',

  components: {
    AnnouncementsBar,
    StaticHeaderBar,
    StaticFooterBar
  }
}
</script>

<style lang="scss" scoped>
.layout {
  background: white;
}
</style>
