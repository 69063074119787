import AddressStateSelect from './AddressStateSelect'
import BrandSelect from './BrandSelect'
import HeadBuyerBrandSelect from './HeadBuyerBrandSelect'
import CountrySelect from './CountrySelect'
import PaymentMethodSelect from './PaymentMethodSelect'
import ProductCategorySelect from './ProductCategorySelect'
import ProductVariantSelect from './ProductVariantSelect'
import RegionSelect from './RegionSelect'
import RouteSelect from './RouteSelect'
import InboundDeliveryMethodSelect from './InboundDeliveryMethodSelect'
import BuyerCompanySelect from './BuyerCompanySelect'
import SubInvoiceSelect from './SubInvoiceSelect'
import ProductSelect from './ProductSelect'
import StoreSelect from './StoreSelect'
import RelevantStoreSelect from './RelevantStoreSelect'
import StoreTypeSelect from './StoreTypeSelect'
import WarehouseSelect from './WarehouseSelect'
import InventoryLotSelect from './InventoryLotSelect'

export default {
  AddressStateSelect,
  BrandSelect,
  HeadBuyerBrandSelect,
  CountrySelect,
  PaymentMethodSelect,
  ProductCategorySelect,
  ProductVariantSelect,
  RegionSelect,
  RouteSelect,
  StoreSelect,
  RelevantStoreSelect,
  StoreTypeSelect,
  InboundDeliveryMethodSelect,
  BuyerCompanySelect,
  SubInvoiceSelect,
  ProductSelect,
  WarehouseSelect,
  InventoryLotSelect
}
