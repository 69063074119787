import Vue from 'vue'
import Debug from '~/components/Debug'
import { IS_PRODUCTION_SITE } from '~/settings'

export default (context, inject) => {
  Vue.component('Debug', Debug)

  const debug = {
    log: (e) => {
      // eslint-disable-next-line no-console
      if (!IS_PRODUCTION_SITE) console.error(e)
    }
  }

  if (context.isDev) {
    Vue.prototype.$debug = debug
    context.app.$debug = debug
    context.store.$debug = debug
  }

  inject('debug', debug)
}
