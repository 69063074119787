<template lang="pug">
.delivery-instruction
  .ship-direct-to-store(
    v-if="type === DELIVERY_METHODS.SHIP_DIRECT_TO_STORE")
    p
      strong Ship this order directly to the store within five (5) days of order date.
    p Complete the fields below and ship to:
    p
      strong SHIP TO
      br
      template Store Name:&nbsp; {{ order.store_name }} % Pod Foods
      br
      template Address:&nbsp;
      address-stamp(:address="order.address_attributes" :showPhone="false")
    p(v-if="order.direct_receiving_note")
      strong STORE RECEIVING NOTE
      br
      span
        mark {{ order.direct_receiving_note }}
    div
      strong FULFILLMENT INSTRUCTIONS
      p
        strong Step 4/5: Print and include Pod Foods Invoice + Packing Slip &nbsp;
        | in parcel.
      p
        strong Step 5/5: Enter tracking information by clicking “View Delivery Details”&nbsp;
        | if you didn't add at the start. Orders without tracking information will not be paid.
      p
        strong Questions?
        br
        | Find complete fulfillment details on Pod Direct section of the&nbsp;
        a.linked(:href="BRAND_GUIDE_URL" target="_blank") Brand Guide&nbsp;
        | or email&nbsp;
        a.linked(:href="`mailto:${ORDERS_EMAIL}`") {{ ORDERS_EMAIL }}
  .self-deliver-to-store(
    v-if="type === DELIVERY_METHODS.SELF_DELIVER_TO_STORE")
    p
      strong Deliver all items on this invoice directly to store within five (5) days of order date:
    p
      strong DELIVER TO
      br
      template Store Name:&nbsp; {{ order.store_name }} % Pod Foods
      br
      template Address:&nbsp;
      address-stamp(:address="order.address_attributes" :showPhone="false")
    p
      strong STORE RECEIVING DETAILS
      br
      template All possible delivery days:&nbsp;
        span(
          v-for="i, k in order.preferred_delivery_week_day"
          :key="k") {{ i | capitalize }}{{ k < order.preferred_delivery_week_day.length - 1 ? ', ' : '' }}
      br
      template Receiving time:&nbsp;
        span(v-if="order.receiving_earliest_time && order.receiving_latest_time")
          | {{ order.receiving_earliest_time | timeWithoutTimezone }} - {{ order.receiving_latest_time | timeWithoutTimezone }}
        span(v-else)
    p(v-if="order.direct_receiving_note")
      strong STORE RECEIVING NOTE
      br
      span
        mark {{ order.direct_receiving_note }}
    div
      strong FULFILLMENT INSTRUCTIONS
      p
        strong Step 4/5: Print and include&nbsp;
        template Pod Foods Invoice + Packing Slip in parcel. Retain one packing slip to have it signed by store receiver as proof of delivery.
      p
        strong Step 5/5: Upload signed packing slip&nbsp;
        template as proof of delivery. Orders without proof of delivery will not be paid.
      p
        strong Questions?
        br
        template Find complete fulfillment details on Pod Direct section of the&nbsp;
        a.linked(:href="BRAND_GUIDE_URL" target="_blank")
          template Brand Guide&nbsp;
        template or email&nbsp;
        a.linked(:href="`mailto:${ORDERS_EMAIL}`") {{ ORDERS_EMAIL }}
</template>

<script>
import AddressStamp from '~/components/stamps/AddressStamp'
import { BRAND_GUIDE_URL, DELIVERY_METHODS, ORDERS_EMAIL } from '~/settings'

export default {
  components: {
    AddressStamp
  },

  props: {
    type: {
      type: String,
      default: ''
    },
    order: {
      type: Object,
      default: () => {}
    }
  },

  data () {
    return {
      ORDERS_EMAIL,
      DELIVERY_METHODS,
      BRAND_GUIDE_URL
    }
  }
}
</script>
<style lang="scss" scoped>
.delivery-instruction {
  word-break: break-word;
  padding: 1rem 1.5rem;
  background: rgba(176, 35, 134, 0.2);
  border-radius: 6px;
  color: black;
  ul {
    margin-top: .5em;
  }
}
</style>
