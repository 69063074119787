import { IS_PRODUCTION_SITE, PREFIX_BROKER_DOMAIN, PREFIX_LP_DOMAIN } from '~/settings'

export const state = () => {
  return {}
}

export const actions = {
  async nuxtServerInit ({ commit, dispatch, getters }, context) {
    const { req, app, route } = context
    const isLPDomain = req?.headers?.host?.startsWith(PREFIX_LP_DOMAIN) || false
    const isBrokerDomain = req?.headers?.host?.startsWith(PREFIX_BROKER_DOMAIN) || false
    const headers = app.$cookies.get('headers') || {}

    commit('authentication/update', {
      isLPDomain,
      isBrokerDomain,
      headers
    })

    try {
      if (headers.uid) {
        await dispatch('authentication/getPersonalInformation')
        if (isBrokerDomain) {
          // Email redỉrect
          if (route.query?.vendor_company_id) {
            commit('authentication/updateHeaders', { ...headers, 'vendor-company-id': route.query?.vendor_company_id })
          }

          await dispatch('broker/getBrokerCompanies')
        }
      }

      await dispatch('master/sync')
    } catch (e) {
      // eslint-disable-next-line no-console
      if (!IS_PRODUCTION_SITE) console.error(e)
    }
  },

  async nuxtClientInit ({ dispatch }, context) {
    await dispatch('master/clientSync', context)
  }
}
