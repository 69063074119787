<template lang="pug">
.sider.sider--catalog-cart(:style="{ height }")
  client-only

    loading(slot="placeholder")

    .sider__header
      .sider__title
        .pf-ellipsis Cart
      .sider__actions
        .counter-items(v-if="itemsCount")
          strong {{ itemsCount | number('0') }}
          | &nbsp;{{ itemsCount > 1 ? 'items' : 'item' }}

    .sider__body.with-scroller(style="padding: 0;")
      loading(v-if="initializing")

      .empty.tc(v-else-if="items.length === 0")
        p Your cart is currently empty.
        //- el-button(size="small") Your previous orders

      .cart__items(v-else)
        .cart__item(
          v-for="i in items"
          :key="i.id"
          :class="{'cart__item--minimum-unreach': i._unreach}")
          .cart__item-preview
            nuxt-link.cart__item-image(
              v-image="i.image_urls.thumbnail"
              :to="getSkuLink(i)"
              :title="i.product_name"
              v-click="() => $amplitude.trackProduct({zone:'CATALOG_CART_SIDER', sku_id: i.product_variant_id})")

          .cart__item-info
            nuxt-link.cart__item-brand.pf-ellipsis(
              :to="getBrandLink(i)"
              v-text="i.brand_name"
              v-ellipsis
              v-tooltip="i.brand_name"
              v-click="() => $amplitude.trackBrand({zone:'CATALOG_CART_SIDER', sku_id: i.product_variant_id})")
            nuxt-link.cart__item-product.pf-ellipsis(
              :to="getSkuLink(i)"
              v-text="i.product_name"
              v-ellipsis
              v-tooltip="i.product_name"
              v-click="() => $amplitude.trackProduct({zone:'CATALOG_CART_SIDER', sku_id: i.product_variant_id})")
            nuxt-link.cart__item-sku.pf-ellipsis(
              :to="getSkuLink(i)"
              v-text="i.product_variant_name"
              v-ellipsis
              v-tooltip="i.product_variant_name"
              v-click="() => $amplitude.trackProduct({zone:'CATALOG_CART_SIDER', sku_id: i.product_variant_id})")
            .cart__item-case-price.pf-nowrap
              template(v-if="i._applied")
                em {{ i.case_price_cents | cents }}
                strong {{ i._applied.price | cents }}
              template(v-else)
                strong {{ i.case_price_cents | cents }}
              price-change-stamp(:variant="i")

          .cart__item-metas
            .cart__item-unit-upc.pf-nowrap
              strong(v-text="i.barcode_code" v-tooltip="'Unit UPC/EAN'")
            .cart__item-limit.pf-nowrap
              span.mov(v-if="!i.is_pod_grow_variant && i.limit_type === LIMIT_TYPES.MOV && i.mov") MOV:&nbsp;
                strong {{ i.mov | cents }}
              span.moq(
                v-else-if="!i.is_pod_grow_variant && i.limit_type === LIMIT_TYPES.MOQ && i.moq > 1") MOQ:&nbsp;
                strong {{ i.moq | number('0') }}
            el-tag.cart__item-order-code(
              type="order-code"
              size="small") \#{{ i.product_variant_id }}

          .cart__item-action.tc.asc
            el-button.cart__item-remove(
              @click="removeItem(i)"
              :disabled="updating"
              icon="bx bx-trash-alt"
              size="small")

          .cart__item-quantity.asc
            el-input-number(
              v-model="form[i.id].quantity"
              size="small"
              :disabled="updating"
              :min="1"
              :step="1"
              @change="val => debouncedUpdateItem(i, val)")

          .cart__item-summary.tr.asc.pf-nowrap
            .cart__item-original-total(v-if="i._applied") {{ i.case_price_cents * i.quantity | cents }}
            .cart__item-total {{ i.total_price_cents | cents }}

    .sider__footer
      cart-summary.cart__summary(plain)

      nuxt-link.el-button.el-button--primary.db(
        :to="{ name: 'cart' }"
        v-click="() => $amplitude.track('CLICK_CART')")
          i.pf-icon.bx.bx-cart-alt.mr
          span View cart
</template>

<script>
import { debounce, cloneDeep, sumBy } from 'lodash'
import { mapGetters } from 'vuex'
import { DEBOUNCE_DELAY, LIMIT_TYPES } from '~/settings'
import { calculateItemPrice } from '~/utilities/promotions'

import PriceChangeStamp from '~/components/stamps/PriceChangeStamp'
import CartSummary from '~/components/carts/CartSummary'

export default {

  components: {
    PriceChangeStamp,
    CartSummary
  },

  props: {
    height: {
      type: String,
      default: '100vh'
    }
  },

  data () {
    return {
      form: {},
      debouncedUpdateItem: () => {}
    }
  },

  computed: {
    LIMIT_TYPES: () => LIMIT_TYPES,

    ...mapGetters({
      order: 'cart/order',
      itemsCount: 'cart/itemsCount',
      updating: 'cart/updating',
      initializing: 'cart/initializing'
    }),

    items () {
      const items = this.$store.getters['cart/items']

      return cloneDeep(items)
        .reverse()
        .map(item => {
          const _unreach =
            !item.is_pod_grow_variant &&
            item.limit_type === LIMIT_TYPES.MOQ &&
            sumBy(items, i =>
              i.product_id === item.product_id &&
              i.region_type === item.region_type
                ? i.quantity
                : 0) < item.moq

          return {
            ...item,
            _unreach,
            _applied: this.getAppliedPromotion(item)
          }
        })
    }
  },

  watch: {
    items: {
      immediate: true,
      deep: true,
      handler (val) {
        const cloned = cloneDeep(this.form)
        const items = val
          .reduce((res, i) => {
            res[i.id] = {
              ...(res[i.id] || {}),
              quantity: i.quantity
            }
            return res
          }, cloned)
        this.$set(this, 'form', items)
      }
    }
  },

  mounted () {
    this.debouncedUpdateItem = debounce(this.updateItem, DEBOUNCE_DELAY)
  },

  methods: {
    getBrandLink (product) {
      return this.$links.brand({
        id: product.brand_id,
        name: product.brand_name
      })
    },

    getSkuLink (product) {
      return this.$links.product({
        id: product.product_id,
        name: product.product_name,
        variant: product.product_variant_name,
        variantId: product.product_variant_id,
        brand: product.brand_name
      })
    },

    getAppliedPromotion (item) {
      const promotion = item?.promotions?.[0]
      if (!promotion) return null
      const quantity = item?.quantity || 1
      const applied = {
        promotion,
        price: calculateItemPrice(item, quantity) / quantity
      }
      return item.case_price_cents !== applied.price
        ? applied
        : null
    },

    removeItem (item) {
      this
        .$confirm('Are you sure you want to remove this item from your shopping cart?', 'Warning', {
          confirmButtonText: 'Remove',
          type: 'warning'
        })
        .then(() => {
          this.$store.dispatch('cart/removeItem', item)
        })
        .catch(this.$debug.log)
    },

    updateItem (item, quantity) {
      // this.$amplitude.track('CLICK_ADD_TO_CART', { zone: 'CART_SIDE_BAR_LINE_ITEM_QUANTITY_BOX', quantity })
      this.$store.dispatch('cart/updateItem', {
        id: item.id,
        quantity
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.cart {
  &__summary {
    margin: 0 0 1em 0;
    ::v-deep {
      dl {
        font-size: 13px;
        grid-gap: .3em 0;
      }
    }
  }
  &__item {
    padding: 1em;
    display: grid;
    grid-template-columns: 64px 1fr 1fr;
    grid-gap: 12px 8px;
    &:not(:last-child) {
      border-bottom: $--border-light;
    }
    &--minimum-unreach {
      .cart__item {
        &-limit {
          color: $--color-warning;
        }
        &-quantity {
          color: $--color-warning;
          ::v-deep {
            .el-input-number__decrease,
            .el-input-number__increase {
              background: lighten($--color-warning, 40);
              border-color: lighten($--color-warning, 35);
            }
            input {
              background: lighten($--color-warning, 40);
              border-color: $--color-warning;
            }
          }
        }
      }
    }
    > * {
      min-width: 0;
    }
    &-image {
      display: block;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      // background-color: $--color-placeholder;
      border-radius: $--radius;
      height: 0;
      padding-top: 100%;
    }
    &-brand {
      display: block;
      color: $--color-primary;
      font-size: 12px;
    }
    &-product {
      display: block;
      font-weight: $--font-weight-bold;
      font-size: 14px;
      margin-top: 3px;
    }
    &-sku {
      display: block;
      font-size: 13px;
      color: $--color-text-secondary;
      margin-top: 3px;
    }
    &-case-price {
      margin-top: 6px;
      em {
        font-style: normal;
        text-decoration: line-through;
        color: $--color-text-light;
        margin-right: .5em;
      }
    }
    &-metas {
      text-align: right;
      font-size: 11px;
      strong {
        font-weight: normal;
      }
    }
    &-limit {
      margin-top: 3px;
    }
    &-order-code {
      margin-top: 5px;
    }
    &-original-total {
      color: $--color-text-light;
      text-decoration: line-through;
      font-size: 12px;
      margin-bottom: 3px;
    }
    &-total {
      font-weight: $--font-weight-bold;
      font-size: 16px;
    }
  }
}
</style>
