import common from './common'
import lp from './lp'
import buyer from './buyer'
import vendor from './vendor'
import broker from './broker'
import headBuyer from './headBuyer'
import authentication from './authentication'
import catalog from './catalog'
import Vue from 'vue'

export default (context, inject) => {
  const { isDev, app, store } = context

  const api = {
    authentication: authentication(context),
    catalog: catalog(context),
    common: common(context),
    lp: lp(context),
    buyer: buyer(context),
    headBuyer: headBuyer(context),
    vendor: vendor(context),
    broker: broker(context)
  }

  if (isDev) {
    Vue.prototype.$api = api
    app.$api = api
    store.$api = api
  }

  inject('api', api)
}
