<template lang="pug">
#app.layout.layout--plain
  #content
    nuxt
</template>

<script>
export default {
  name: 'PlainLayout'
}
</script>

<style lang="scss" scoped>
.layout {
  background: white;
}
</style>
