<template lang="pug">
  el-dialog(
    custom-class="page__dialog shipping-address-modal"
    visible
    :disabled="saving"
    :close-on-click-modal="false"
    @close="$emit('close')")

    .page__dialog-header(slot="title")
      .page__dialog-preview
        i.page__dialog-icon.bx.bxs-eyedropper
      .page__dialog-info.flex.aic
        .page__dialog-title Shipping Address

    ShippingAddressForm(ref="addressForm" v-model="form")

    .page__dialog-footer(slot="footer")
      el-button(@click="$emit('close')") Cancel
      el-button(type="primary" @click="submit" :disabled="!hasChange") Submit

</template>

<script>
import { cloneDeep, isEqual } from 'lodash'
import ShippingAddressForm from '~/components/samples/ShippingAddressForm'

export default {
  name: 'HeadBuyerShippingAddressModal',

  components: {
    ShippingAddressForm
  },

  props: {
    address: {
      type: Object,
      required: true,
      default: () => ({})
    }
  },

  data () {
    return {
      saving: false,
      form: cloneDeep(this.address.address_attributes),
      formInit: cloneDeep(this.address.address_attributes)
    }
  },

  computed: {
    hasChange () {
      return !isEqual(this.form, this.formInit)
    }
  },

  methods: {
    submit () {
      this.$refs.addressForm.validate().then(() => {
        this.saving = true

        this.$api.headBuyer.updateAddress(this.form)
          .then(() => {
            this.$emit('done', this.form)
            this.$emit('close')
          })
          .finally(() => {
            this.saving = false
          })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
