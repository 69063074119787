import { groupBy, cloneDeep, keyBy } from 'lodash'
import { update, merge } from '@/utilities/store'
import { REGION_TYPES, LIMIT_TYPES, WITHIN_7_BUSINESS_DAYS } from '~/settings'

export const state = () => {
  return {
    order: null,
    updating: false,
    smallOrderSurchargeStructure: null,
    logisticsSurchargeStructure: null
  }
}

export const mutations = {
  update,
  merge,

  updateCart (state, res) {
    if (!res) return {}
    state.order = res.order
    state.smallOrderSurchargeStructure = res.small_order_surcharge_structure
    state.logisticsSurchargeStructure = res.logistics_surcharge_structure
    return res
  },

  reset (state) {
    state.order = null
    state.smallOrderSurchargeStructure = null
    state.logisticsSurchargeStructure = null
    state.updating = false
  },

  lock (state) {
    state.updating = true
  },

  unlock (state) {
    state.updating = false
  }
}

export const getters = {
  updating: s => s.updating,
  order: s => s.order || null,
  initializing: (s, g) => !g.order && g.updating,
  items: (s, g) => g.order?.line_items_attributes || [],
  itemsCount: s => s.order?.item_total || 0,
  checkoutable: (s, g) => !g.updating && g.itemsCount > 0,
  sosStructure: s => s.smallOrderSurchargeStructure,
  sosAmount: s => s.smallOrderSurchargeStructure?.amount_cents || 0,
  amountToDismissSos: (s, g) => {
    if (!g.order?.store_has_surcharge) return 0
    if (!g.order?.small_order_surcharge) return 0

    // If the cart has at least one express item, we compare total express items amount with sosAmount (spec 2309)
    if (g.groups[REGION_TYPES.WAREHOUSED]) {
      return g.sosAmount - g.groups[REGION_TYPES.WAREHOUSED]?.moq?.total
    }

    // Else, we compare total cart amount with sosAmount
    return g.sosAmount - g.summary.currentValue + g.summary.sponsoredDiscount
  },
  cutoffTimeMessage: (s, g, rootState, rootGetters) => {
    if (rootGetters['authentication/isPodDirectBuyer'] ||
      !Array.isArray(g?.order?.receiving_week_day) ||
      !g.order.receiving_week_day.length)
      return null

    // Hard-coded for spec task #1812
    if (g.order.receiving_week_day.includes(i => i === WITHIN_7_BUSINESS_DAYS)) {
      return 'Place orders at any time for delivery within 7 business days. ETA in dashboard will reflect the delivery by date'
    }
    return g?.order?.cutoff_time_message || null
  },
  receivingWeekdays: (s, g) => {
    if (!Array.isArray(g?.order?.receiving_week_day) ||
      g.order.receiving_week_day.includes(i => i === WITHIN_7_BUSINESS_DAYS))
      return []

    return g.order.receiving_week_day
  },
  address: (s, g) => g.order ? g.order.address_attributes || {} : {},

  summary: (s, g) => {
    if (!g.order) return null
    const serviceFee = parseFloat(g.order.service_fee)
    const total = parseFloat(g.order.total_payment)
    const originalValue = g.items
      .reduce((sum, i) => sum + i.case_price_cents * i.quantity, 0)
    const currentValue = g.items
      .reduce((sum, i) => sum + i.total_price_cents, 0)
    const discount = originalValue - currentValue
    const sponsoredDiscount = g.order.promotions?.[0]?.amount_cents || 0
    return {
      serviceFee,
      total,
      originalValue,
      currentValue,
      discount,
      sponsoredDiscount
    }
  },

  groups (s, g) {
    const grouping = items => {
      const groups = {}
      if (!g.order) return {}
      const movs = keyBy(g.order.vendor_company_movs, 'vendor_company_id')
      items.forEach(item => {
        let group = null
        const key = [item.is_pod_grow_variant ? REGION_TYPES.PFG : item.region_type, item.limit_type].join('_')
        switch (key) {
          case [REGION_TYPES.PFD, LIMIT_TYPES.MOV].join('_'):
            group = groups[item.vendor_company_id] || {}
            group = {
              ...group,
              limitType: group.limitType || item.limit_type,
              mov: group.mov || movs?.[item.vendor_company_id]?.mov || 0,
              total: (group.total || 0) + item.total_price_cents,
              title: group.title || item.brand_name,
              company: group.company || item.vendor_company_id,
              items: [...(group.items || []), item],
              explore: group.explore || {
                query: {
                  'q[region_type]': item.region_type,
                  'q[vendor_company_id]': item.vendor_company_id
                }
              }
            }
            group.reach = group.total >= group.mov
            groups[item.vendor_company_id] = group
            break

          case [REGION_TYPES.PFG, LIMIT_TYPES.MOV].join('_'):
          case [REGION_TYPES.PFG, LIMIT_TYPES.MOQ].join('_'):
            group = groups[item.vendor_company_id] || {}
            group = {
              ...group,
              limitType: LIMIT_TYPES.NONE,
              total: (group.total || 0) + item.total_price_cents,
              title: group.title || item.brand_name,
              company: group.company || item.vendor_company_id,
              items: [...(group.items || []), { ...item, limit_type: LIMIT_TYPES.NONE }],
              explore: group.explore || {
                query: {
                  'q[region_type]': item.region_type,
                  'q[vendor_company_id]': item.vendor_company_id
                }
              }
            }
            group.reach = group.total >= group.mov
            groups[item.vendor_company_id] = group
            break

          case [REGION_TYPES.PFD, LIMIT_TYPES.MOQ].join('_'):
          case [REGION_TYPES.WAREHOUSED, LIMIT_TYPES.MOV].join('_'):
          case [REGION_TYPES.WAREHOUSED, LIMIT_TYPES.MOQ].join('_'):
            group = groups[LIMIT_TYPES.MOQ] || {}
            group = {
              ...group,
              limitType: LIMIT_TYPES.MOQ,
              total: (group.total || 0) + item.total_price_cents,
              title: 'Other vendors with MOQ',
              items: [...(group.items || []), item]
            }
            groups[LIMIT_TYPES.MOQ] = group
            break
        }
      })

      // compute reachable flag for moq group
      const group = groups[LIMIT_TYPES.MOQ]
      if (group) {
        let items = group?.items || []
        items = items
          .map(item => ({
            ...item,
            reach: item.limit_type === LIMIT_TYPES.MOQ
              ? item.moq <= items
                .filter(i =>
                  i.product_id === item.product_id &&
                  i.region_type === item.region_type)
                .reduce((sum, i) => sum + i.quantity, 0)
              : true
          }))
        groups[LIMIT_TYPES.MOQ] = {
          ...group,
          items,
          reach: !items.filter(i => !i.reach).length
        }
      }

      return groups
    }
    // group by region type
    const items = cloneDeep(g.items.map((i) => ({ ...i, FE_region_type: i.is_pod_grow_variant ? REGION_TYPES.PFG : i.region_type })))
    const itemGroups = groupBy(items, i => i.FE_region_type)

    return Object
      .values(REGION_TYPES)
      .reduce((res, type) => {
        res[type] = grouping(itemGroups[type] || [])
        return res
      }, {})
  },

  movs: (s, g) => Object.values(g.groups?.[REGION_TYPES.PFD] || {}),

  addedQuantityByProduct: (s, g) => ({ variant }) => {
    return g.items
      .filter(i => i.product_id === variant.product_id && i.region_type === variant.region_type && !i.is_pod_grow_variant)
      .reduce((sum, i) => sum + i.quantity, 0)
  },

  addedQuantityBySku: (s, g) => ({ variant }) => {
    return g.items
      .filter(i => i.product_variant_id === variant.id)
      .reduce((sum, i) => sum + i.quantity, 0)
  },

  addedValueByVendorCompany: (s, g) => ({ variant }) => {
    return g.items
      .filter(i => i.vendor_company_id === variant.vendor_company_id && i.region_type === variant.region_type)
      .reduce((sum, i) => sum + i.total_price_cents, 0)
  }
}

export const actions = {
  fetch ({ commit }) {
    commit('lock')
    return this.$api.buyer.getCartInfo()
      .then(res => {
        commit('updateCart', res)
        return res
      })
      .finally(() => {
        commit('unlock')
      })
  },

  update ({ commit }, params) {
    return this.$api.buyer.updateCartInfo(params)
      .then(res => {
        commit('updateCart', res)
        return res
      })
  },

  updateItem ({ commit, dispatch, getters }, item) {
    commit('lock')
    return this.$api.buyer
      .updateCartItem(item)
      .then(res => {
        commit('updateCart', res)
        return res
      })
      .finally(() => {
        commit('unlock')
      })
  },

  removeItem ({ commit }, item) {
    commit('lock')
    return this.$api.buyer
      .removeCartItem(item)
      .then(res => {
        commit('updateCart', res)
        this.$workflow.notify('Item removed from cart!')
        return res
      })
      .finally(() => {
        commit('unlock')
      })
  },

  addItem ({ commit, state }, params) {
    return this.$api.buyer.addCartItem(params)
      .then(res => {
        commit('updateCart', res)
        return res
      })
  },

  addItems ({ commit, state }, params) {
    return this.$api.buyer.addCartItems(params)
      .then(res => {
        commit('updateCart', res)
        return res
      })
  },

  moveCartItemToForLater ({ commit }, item) {
    commit('lock')
    return this.$api.buyer
      .moveCartItemToForLater(item.id)
      .then(res => {
        commit('updateCart', res)
        this.$workflow.notify('Item moved to Saved for later section!')
        return res
      })
      .finally(() => {
        commit('unlock')
      })
  },

  moveForLaterItemToCart ({ commit }, item) {
    commit('lock')
    return this.$api.buyer
      .moveForLaterItemToCart(item.id)
      .then(res => {
        commit('updateCart', res)
        this.$workflow.notify('Item moved to cart!')
        return res
      })
      .finally(() => {
        commit('unlock')
      })
  },

  // eslint-disable-next-line
  checkout ({ commit, getters, rootGetters }, { payment_type, ...params } = {}) {
    commit('lock')
    const order = getters.order
    if (!order) return Promise.reject(new Error('Cart order not found'))
    const cart = {
      id: order.id,
      order: {
        ...params
      }
    }
    const payment = {
      order_id: order.id,
      payment_type
    }
    return this.$api.buyer
      .updateCartInfo(cart)
      .then(() => this.$api.buyer.payment(payment))
      .then(res => {
        commit('authentication/updateUser', {
            ...(rootGetters['authentication/user'] || {}),
            order_id: res.new_order_id
        }, { root: true })
        commit('reset')
        return res
      })
      .finally(() => commit('unlock'))
  }
}
