<template lang="pug">
el-dialog(
  custom-class="page__dialog privacy-policy-modal"
  visible
  :close-on-click-modal="false"
  :show-close="false")

  el-tabs(v-model="tab" slot="title")
    el-tab-pane(
      v-if="!policyAgreed"
      label="Privacy Policy"
      :name="TABS.POLICY")
      .title(slot="label") Privacy Policy
    el-tab-pane(
      v-if="!termAgreed"
      label="Site Terms of Use"
      :name="TABS.TERM")
      .title(slot="label") Site Terms of Use

  loading(v-if="loading")
  article.page__article.with-scroller(v-else-if="data[tab]")
    vue-markdown(
      :source="data[tab]"
      :linkify="false")

  el-checkbox.mt-3(v-if="!policyAgreed" v-model="read[TABS.POLICY]") I have read, understood and agree to the Privacy Policy.
  el-checkbox.mt-1(v-if="!termAgreed" v-model="read[TABS.TERM]") I have read, understood and agree to the Site Terms of Use.
  el-alert.mt-1(
    v-if="!policyAgreed && !termAgreed"
    type="warning"
    :closable="false") Please check both checkboxes in order to accept the Privacy Policy and Terms of Use.

  .page__dialog-footer(slot="footer")
    el-button(
      type="primary"
      @click="accept"
      :disabled="disabledSubmit || submitting")
      i.bx.bx-loader.bx-spin.bx-5.icon.mr(v-if="submitting")
      i.bx.bx-check-circle.bx-5.icon.mr(v-else)
      span I accept
</template>

<script>
import VueMarkdown from 'vue-markdown'
import Loading from '~/components/Loading'

const TABS = {
  POLICY: 'POLICY',
  TERM: 'TERM'
}

export default {
  components: {
    VueMarkdown,
    Loading
  },

  props: {
    api: {
      type: Object,
      required: true
    },
    termAgreed: {
      type: Boolean,
      default: false
    },
    policyAgreed: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      TABS,
      tab: this.policyAgreed ? TABS.TERM : TABS.POLICY,
      loading: false,
      submitting: false,
      data: {},
      read: {
        [TABS.POLICY]: this.policyAgreed,
        [TABS.TERM]: this.termAgreed
      }
    }
  },

  computed: {
    disabledSubmit () {
      return Object.values(this.read).some(e => !e)
    }
  },

  mounted () {
    return this.fetch()
  },

  methods: {
    fetch () {
      this.loading = true
      return Promise.all([
        this.api.common.getPrivacyPolicy(),
        this.api.common.getTermOfUse()
      ])
        .then(([policyRes, termRes]) => {
          this.data = {
            [TABS.POLICY]: policyRes?.content || '',
            [TABS.TERM]: termRes?.content || ''
          }
        })
        .finally(() => {
          this.loading = false
        })
    },

    accept () {
      this.submitting = true
      return this.api.common
        .acceptAgreements({
          agree_to_policies: true,
          agree_to_terms: true
        })
        .then(() => {
          this.$emit('done', true)
          this.$emit('close')
        })
        .finally(() => {
          this.submitting = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .privacy-policy-modal {
    max-width: 720px;
  }
}
.title {
  font-weight: 800;
  font-size: 18px;
}
article {
  max-height: 480px;
  overflow-y: scroll;
  overflow-x: hidden;
  ::v-deep {
    h1, h2, h3 {
      margin: .5em 0 .25em 0;
    }
    p {
      margin: .75em 0 0 0;
      line-height: 1.5em;
    }
  }
}
</style>
