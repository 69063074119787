<script>
import { mapGetters } from 'vuex'
import Select from './BaseSelect'

export default {
  name: 'ProductVariantSelect',
  extends: Select,

  props: {
    filterable: {
      type: Boolean,
      default: true
    },

    api: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    ...mapGetters({
      apiRole: 'authentication/apiRole'
    })
  },

  methods: {
    params (q) {
      return {
        ...this.queries,
        'q[state]': 'active',
        'q[any_text]': q
      }
    },

    search (...args) {
      const service = this.$api[this.apiRole].getProductVariants(...args)

      return service || Promise.reject(new Error('No handler provided'))
    },

    fetch (...args) {
      const service = this.$api[this.apiRole].getProductVariants(...args)

      return service || Promise.reject(new Error('No handler provided'))
    }
  }
}
</script>
