<template lang="pug">
.catalog-header-bar
  .top.flex.inner-gap(:class="{searching}")
    .logo
      nuxt-link(:to="{ name: 'index' }" title="Pod Foods")
        img(src="/img/logo.svg")
    .search
      client-only
        search-box(ref="search")
    a.search-toggler(href="#" @click.prevent="searching = !searching")
      i.pf-icon.bx.bx-x(v-if="searching")
      i.pf-icon.bx.bx-search(v-else)

    .notification(v-if="showNotificationBox")
      notification-box

    .user
      user-box

  .bottom.flex.inner-gap
    .expander

    .menu
      nuxt-link(
        :to="$links.catalog()"
        title="Catalog"
        v-click="() => $amplitude.track('CLICK_CATALOG')") Catalog

      nuxt-link(
        :to="{ name: 'brands' }"
        title="Brands"
        v-click="() => $amplitude.track('CLICK_BRANDS')") Brands

      template(v-if="isVendor")
        a(:href="`${SUPPORT_URL}`" target="_blank" @click="$amplitude.track('CLICK_BRAND_GUIDE')") Support
      template(v-if="isHeadBuyer || (isBuyer && !isPreApprovalBuyer)")
        nuxt-link(
          v-if="!isHeadBuyer"
          :to="{ name: 'promotions' }"
          title="promotions"
          v-click="() => $amplitude.track('CLICK_PROMOTIONS')") Promotions
        nuxt-link(
          :to="{ name: 'order-guide' }"
          title="Order guide"
          v-click="() => $amplitude.track('CLICK_ORDER_GUIDE')") Order guide
        nuxt-link(
          :to="{ name: 'favorites' }"
          title="Favorites"
          v-click="() => $amplitude.track('CLICK_FAVORITES')") Favorites
      template(v-if="isBuyer || isHeadBuyer")
        nuxt-link.invite-brands(
          :to="{ name: 'brands-invite' }"
          title="Invite brands"
          v-click="() => $amplitude.track('CLICK_REFER_BRAND')") Refer a Brand
        a.weekly-promo(
          v-if="weeklyPromo"
          href="#"
          title="Weekly promo"
          @click.prevent="openWeeklyPromo")
          strong Weekly promo
          span.ml-1 🎉

  categories-bar(v-if="showCategories")
</template>

<script>
import { mapGetters } from 'vuex'
import NotificationBox from './NotificationBox'
import SearchBox from './SearchBox'
import UserBox from './UserBox'
import CategoriesBar from './CategoriesBar'
import WeeklyPromoModal from '~/components/modals/WeeklyPromoModal'
import { SUPPORT_URL } from '~/settings'

export default {
  components: {
    NotificationBox,
    SearchBox,
    UserBox,
    CategoriesBar,
    WeeklyPromoModal
  },

  props: {
    showCategories: {
      type: Boolean,
      default: true
    },
    showNotification: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      searching: false,
      state: null,
      showStates: false,
      SUPPORT_URL
    }
  },

  computed: {
    ...mapGetters({
      addressStates: 'master/addressStates',
      isBroker: 'authentication/isBroker',
      isVendor: 'authentication/isVendor',
      isHeadBuyer: 'authentication/isHeadBuyer',
      isBuyer: 'authentication/isBuyer',
      isPreApprovalBuyer: 'authentication/isPreApprovalBuyer',
      weeklyPromo: 'master/weeklyPromo'
    }),

    showNotificationBox () {
      return this.showNotification && this.isVendor && !this.isBroker
    }
  },

  watch: {
    searching (val) {
      if (!val) return
      this.$nextTick(() => {
        this.$refs.search && this.$refs.search.focus()
      })
    }
  },

  methods: {
    openWeeklyPromo () {
      this.$amplitude.track('CLICK_WEEKLY_PROMO')
      this.$dialogs.open({
        parent: this,
        component: WeeklyPromoModal
      })
    }
  }
}
</script>

<style lang="scss">
.categories-popper {
  padding: 0;
  width: 100vw;
  max-width: 640px;
  margin-top: 9px !important;
  border-radius: 0 0 $--radius $--radius;
  overflow: hidden;
}
</style>

<style lang="scss" scoped>
@keyframes bubble {
  0% { transform: scale(1); }
  20% { transform: scale(1.5); }
  100% { transform: scale(1); }
}

@keyframes textclip {
  to {
    background-position: 200% center;
  }
}

.catalog-header-bar {
  $divider: $--border-light;
  position: relative;
  background: rgba(white, .95);
  border-bottom: $divider;
  a {
    outline: none;
  }
  .top {
    align-items: center;
    min-height: 50px;
    @include media(md) {
      min-height: 58px;
    }
    &.searching {
      .logo {
        display: none;
      }
      .search {
        display: block;
      }
      .user {
        display: none;
      }
    }
    .logo {
      padding: 10px 0;
      flex: 1;
      @include media(sm) {
        flex: 0 120px;
        margin-right: 20px;
      }
      @include media(md) {
        flex: 200px 0;
        margin-right: 30px;
      }
      img {
        display: block;
        width: 120px;
        @include media(md) {
          width: 160px;
        }
      }
    }
    .search {
      flex: 1;
      display: none;
      @include media(sm) {
        display: block;
      }
    }
    .search-toggler {
      display: block;
      padding: 12px;
      margin-right: -10px;
      @include media(sm) {
        display: none;
      }
      > span {
        cursor: pointer;
      }
    }
    .user {
      margin-left: 10px;
      text-align: right;
      @include media(md) {
        margin-left: 1em;
      }
    }
    .notification {
      margin-left: 10px;
      text-align: right;
      @include media(md) {
        margin-left: 1em;
      }
    }
  }
  .bottom {
    border-top: $divider;
    font-size: 12px;
    @include media(sm-down) {
      padding-left: 0;
      padding-right: 0;
    }
    @include media(md) {
      font-size: 13px;
    }
    .expander {
      margin-right: 1.5em;
      display: none;
      @include media(md) {
        display: block;
        margin-right: 30px;
        flex: 0 200px;
      }
    }
    .menu {
      display: flex;
      overflow-x: scroll;
      white-space: nowrap;
      @include no-scroller();
      > a {
        &.invite-brands {
          color: $--color-primary;
        }
        &.weekly-promo {
          strong {
            background-image: linear-gradient(
                -225deg,
                #231557 0%,
                #44107a 29%,
                $--color-danger 67%,
                $--color-highlight 100%
            );
            background-size: auto auto;
            background-clip: border-box;
            background-size: 200% auto;
            background-clip: text;
            text-fill-color: transparent;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            animation: textclip 2s linear infinite;
            animation-direction: reverse;
          }
          span {
            display: inline-block;
            animation: bubble infinite 2s;
            animation-delay: 1s;
          }
        }
      }
      > * {
        min-width: auto;
        padding: .6em 0;
        color: $--color-text-regular;
        line-height: 16px;
        @include media(sm-down) {
          &:first-child {
            margin-left: 1em;
          }
          &:last-child {
            margin-right: 1em;
          }
        }
        a {
          color: $--color-text-regular;
        }
        &:not(:last-child) {
          margin-right: 1.5em;
        }
        &:hover {
          color: $--color-primary;
        }
      }
    }
  }
  .address-state-select {
    font-size: 13px;
    ::v-deep {
      .el-select {
        &__caret {
          line-height: 14px;
        }
      }
      .el-input {
        display: flex;
        align-items: center;
        > * {
          min-width: 0;
          height: 36px;
        }
        input {
          order: 2;
          font-size: 13px;
        }
        &__prefix {
          position: relative;
          font-size: 13px;
          text-align: left;
          white-space: nowrap;
          margin-right: .5em;
          display: flex;
          align-items: center;
        }
        &__inner {
          border: none;
          padding: 0;
        }
      }
    }
  }
}
</style>
