<template lang="pug">
component.product-tag-stamp(
  :is="linked ? 'nuxt-link' : 'span'"
  :to="{name: 'products', query: {'q[tag_ids]': data.id}}"
  v-text="data.name"
)
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true
    },

    linked: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.product-tag-stamp {
  background: #d881be;
  color: white;
  font-size: 12px;
  line-height: 100%;
  letter-spacing: 0.02em;
  font-weight: $--font-weight-bold;
  padding: 4px 6px;
  border-radius: 3px;
  user-select: none;
  display: inline-block;
}
</style>
