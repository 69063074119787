import { WEBSITE_BASE_URL, WEBSITE_BROKER_BASE_URL, WEBSITE_LP_BASE_URL } from '~/settings'

export const LOGIN_ENDPOINTS = {
  buyer: '/buyer/buyer_auth/sign_in.json',
  vendor: '/vendor/vendor_auth/sign_in.json',
  broker: '/broker/broker_auth/sign_in.json',
  logistics_partner: '/logistics_partner/logistics_partner_auth/sign_in.json',
  head_buyer: '/head_buyer/buyer_auth/sign_in.json'
}

export const RESET_PASSWORD_ENDPOINTS = {
  buyer: '/buyer/buyer_auth/password.json',
  vendor: '/vendor/vendor_auth/password.json',
  broker: '/broker/broker_auth/password.json',
  logistics_partner: '/logistics_partner/logistics_partner_auth/password.json'
}

export const NEW_PASSWORD_ENDPOINTS = {
  buyer: '/buyer/buyer_auth/password.json',
  vendor: '/vendor/vendor_auth/password.json',
  logistics_partner: '/logistics_partner/logistics_partner_auth/password.json'
}

export default ({ $axios }) => ({
  register: data =>
    $axios.$post('/onboarding/capture_pre_onboardings.json', data),

  login ({ user_role, ...params }) {
    const endpoint = LOGIN_ENDPOINTS[user_role]
    if (!endpoint) return Promise.reject(new Error('Unsupported user role'))
    return $axios.post(endpoint, params)
  },

  resetPassword ({ email, role }) {
    const endpoint = RESET_PASSWORD_ENDPOINTS[role]
    if (!endpoint) return Promise.reject(new Error('Unsupported user role'))

    let baseUrl = {
      lp: WEBSITE_LP_BASE_URL,
      broker: WEBSITE_BROKER_BASE_URL
    }[role]

    if (!baseUrl) baseUrl = WEBSITE_BASE_URL

    const redirectUrl = `${baseUrl}/setup-password/${role}`

    return $axios
      .$post(endpoint, {
        email,
        redirect_url: redirectUrl
      })
    // TODO: show notification message
    // app.$workflow.openNotifyMessage('We have sent you an email with further instructions. Please check your inbox.')
  },

  setupPassword ({ data, userRole }) {
    const endpoint = RESET_PASSWORD_ENDPOINTS[userRole]
    if (!endpoint) return Promise.reject(new Error('Unsupported user role'))
    return $axios
      .$put(endpoint, data.body, {
        headers: data.headers
      })
  }
})
