<template lang="pug">
.progress-bar
  .dollars-progress-bar(:class="backgroundClasses" :style="`width: ${progressBarPercentage}%`")
</template>

<script>
import { mapGetters } from 'vuex'
import { REGION_TYPES } from '~/settings'

export default {
  computed: {
    ...mapGetters({
      REGION_TYPES: () => REGION_TYPES,
      groups: 'cart/groups',
      sosAmount: 'cart/sosAmount',
      amountToDismissSos: 'cart/amountToDismissSos'
    }),

    expressItemsAmount () {
      return this.groups[REGION_TYPES.WAREHOUSED]?.moq?.total || 0
    },

    showExpressMovSos () {
      return this.amountToDismissSos > 0
    },

    progressBarPercentage () {
      const percentage = (this.expressItemsAmount * 100 / this.sosAmount).toFixed(2)
      return percentage < 100 ? percentage : 100
    },

    backgroundClasses () {
      return {
        'pf-success-background': !this.showExpressMovSos,
        'pf-alert-background': this.showExpressMovSos
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.progress-bar {
  background-color: rgba(black, .05);
  border-radius: 8px;
  height: 10px;
  .dollars-progress-bar {
    padding-left: 4px;
    white-space: nowrap;
    border-radius: 8px;
    height: 10px;
  }
}
</style>
