import objectToFormData from '~/utilities/object-to-formdata'

export default ({ store, $axios, $moment }) => ({
  referBrands: data =>
    $axios.$post('/buyer/referral_brand_forms.json', {
      referral_brand_form: data
    }),

  getTermsOfService: () =>
    $axios.$get('/term_of_service.json')
      .then(res => res.term_of_service),

  getStores: () =>
    $axios.$get('/buyer/stores.json'),

  getPaymentInformation: () =>
    $axios.$get('/buyer/payment_information.json'),

  updateCompanyInformation: data =>
    $axios.$put('/buyer/buyer_companies.json', objectToFormData({
      buyer_company: data
    })),

  updatePaymentInformation: data =>
    $axios.$put('/buyer/payment_information.json', data),

  updatePlaidAccessToken: data =>
    $axios.$put('/plaids/access_token.json', data),

  createPaymentInformation: data =>
    $axios.$post('/buyer/payment_information.json', data),

  deletePaymentInformation: () =>
    $axios.$delete('/buyer/payment_information.json'),

  getCartInfo: () =>
    $axios.$get('/buyer/cart.json'),

  createPreOrder: data =>
    $axios.$post('/buyer/pre_order/orders.json', data),

  getPreOrderDetails: (id) =>
    $axios.$get(`/buyer/pre_order/orders/${id}.json`),

  createSampleRequest: data =>
    $axios.$post('/buyer/sample_requests.json', data),

  addCartItem: data =>
    $axios.$put('/buyer/cart_item.json', data),

  addCartItems: data =>
    $axios.$post('/buyer/multiple/cart_item.json', data),

  updateCartInfo: data =>
    $axios.$put('/buyer/cart.json', data),

  updateCartItem: ({ id, ...data }) =>
    $axios.$put('/buyer/cart_item.json', data, {
      params: {
        id
      }
    }),

  removeCartItem: data =>
    $axios.$delete(`/buyer/cart_item.json?id=${data.id}`),

  fetchForLaterCartItems: params =>
    $axios.$get('/buyer/saved_line_items.json', { params }),

  deleteForLaterCartItem: id =>
    $axios.$delete(`/buyer/saved_line_items/${id}.json`),

  moveCartItemToForLater: id =>
    $axios.$post('/buyer/update_to_cart/saved_line_items.json', { line_item_id: id }),

  moveForLaterItemToCart: id =>
    $axios.$delete(`/buyer/update_to_cart/saved_line_items/${id}.json`),

  payment: data =>
    $axios.$post('/buyer/payment.json', data),
    // /* eslint-disable */
  // async checkOutOrder (data) {
  //   const department = (data.department1 === 'Other') ? data.department2 : data.department1
  //   const dataForUpdate = {
  //     id: data.order_id,
  //     order: {
  //       custom_store_name: data.custom_store_name,
  //       buyer_special_note: data.buyer_special_note,
  //       department,
  //       customer_po: data.customer_po
  //     }
  //   }
  //   await $axios.$put('/cart.json', dataForUpdate).then(() => {
  //     const payment_type = data.payment.split('-')[1]
  //     const dataForCheckout = {
  //       order_id: data.order_id,
  //       payment_type,
  //       num_of_delay: (payment_type === 'delayed') ? +data.payment.split('-')[2] : null
  //     }

  //     return $axios.$post('/payment.json', dataForCheckout).then((res) => {
  //       const user = store.state.authentication.user
  //       store.commit('authentication/update', {
  //         user: {
  //           ...user,
  //           order_id: res.new_order_id
  //         }
  //       })
  //       store.commit('cart/update', { order: null, small_order_surcharge_structure: null })
  //     })
  //   })
  // },
  // /* eslint-enable */

  addItemToFavorites: id =>
    $axios.$post('/buyer/favorite_products.json', { favorite_product: { product_variant_id: id } }),

  getFavoriteItems: () =>
    $axios.$get('/buyer/favorite_products.json'),

  removeFavoriteItem: id =>
    $axios.$delete(`/buyer/favorite_products/${id}.json`),

  getOrderHistory: params =>
    $axios.$get('/buyer/ordered_variants.json', { params }),

  getSubBuyerOrderHistory: params =>
    $axios.$get('/buyer/order_guide/ordered_variants.json', { params }),

  getPromotions: params =>
    $axios.$get('/buyer/promotions.json', { params }),

  getPromotionsBrands: params =>
    $axios.$get('/buyer/promotions/brands.json', { params }),

  getPromotionsBrandsDetail: (id, params) =>
    $axios.$get(`/buyer/promotions/brands/${id}.json`, { params }),

  getOrders: params =>
    $axios.$get('/buyer/orders.json', { params }),

  getStoreBuyerOrders: params =>
    $axios.$get('/buyer/sub_buyer/orders.json', { params }),

  exportOrders: params =>
    $axios.$get('/buyer/export/orders.json', { params, responseType: 'blob' }),

  exportOrderDetailsCSV: id =>
    $axios.$get(`/buyer/export/orders/${id}.json`, { responseType: 'blob' }),

  getOrderDetails: id =>
    $axios.$get(`/buyer/orders/${id}.json`),

  getSubInvoiceDetails: id =>
    $axios.$get(`/buyer/sub_invoices/${id}.json`),

  getSampleRequests: params =>
    $axios.$get('/buyer/sample_requests.json', { params }),

  getStoreBuyerSampleRequests: params =>
    $axios.$get('/buyer/sub_buyer/sample_requests.json', { params }),

  getSampleDetails: id =>
    $axios.$get(`/buyer/sample_requests/${id}.json`),

  getCompanies: () =>
    $axios.$get('/buyer/buyer_companies.json'),

  changePassword: data =>
    $axios.$put('/buyer/buyer_auth.json', data),

  getPersonalInformation: data =>
    $axios.$get('/buyer/buyers.json'),

  updatePersonalInformation: data =>
    $axios.$put('/buyer/buyers.json', { buyer: data }),

  updateStoreInformation: data =>
    $axios.$put('/buyer/stores.json', data),

  getPlaidLinkToken: () =>
    $axios.$get('/plaids/link_token.json').then(res => res.link_token),

  getCreditMemos: (params) =>
    $axios.get('/buyer/credit_memos.json', { params }),

  getTotalOrderNumber: (type, params) =>
     $axios.$get(`/buyer/analytics/${type}/total_order_number.json`, { params })
       .then(({ data }) => data),

  getTotalOrderValue: (type, params) =>
    $axios.$get(`/buyer/analytics/${type}/total_order_value.json`, { params })
      .then(({ data }) => data),

  getTop5PurchasedProductByQty: (type, params) =>
    $axios.$get(`/buyer/analytics/${type}/top5_purchased_product_by_qty.json`, { params })
      .then(({ data }) => data),

  getTop5PurchasedProductBySale: (type, params) =>
    $axios.$get(`/buyer/analytics/${type}/top5_purchased_product_by_sale.json`, { params })
      .then(({ data }) => data),

  getStatements: params =>
    $axios.$get('/buyer/store_statements.json', { params }),

  getStatementDetail: id =>
    $axios.$get(`/buyer/store_statements/${id}.json`),

  exportStatementPDF: id =>
    $axios.$get(`/buyer/pdf_downloads/store_statements/${id}`, { responseType: 'blob' }),

  exportStatementXlsx: id =>
    $axios.$get(`/buyer/excel_downloads/store_statements/${id}.xlsx`, { responseType: 'blob' }),

  exportOrdersGuide: params =>
    $axios.$get('/buyer/export/ordered_variants.json', { params, responseType: 'blob' }),

  exportRecommendedProducts: params =>
    $axios.$get('/buyer/export/recommended_product/products.json', { params }),

  getProductVariants: params =>
    $axios.$get('/buyer/product_variants.json', { params }),

  getRecommendationProductVariants: params =>
    $axios.$get('/buyer/recommendation/product_variants.json', { params }),

  inviteColleages: (params) =>
    $axios.$post('/buyer/buyer_auth/invitation.json', params),

  acceptInvitation: (params) =>
    $axios.$put('/buyer/buyer_auth/invitation.json', params),

  createOnboardingAccount: params =>
    $axios.$post('/buyer/onboarding/buyer_companies.json', {
      buyer_company: params
    }),

  getOnboading: (buyerId) =>
    $axios.$get(`/buyer/onboarding/buyer_companies/${buyerId}.json`),

  updateOnboading: (buyerId, data, isFormData, finished) => {
    let payload = isFormData ? objectToFormData({ buyer_company: data, finished }) : { buyer_company: data }
    if (finished) payload = {
      finished: true,
      ...payload
    }

    return $axios.$put(`/buyer/onboarding/buyer_companies/${buyerId}.json`, payload)
  },

  getSubInvoices: (params) =>
    $axios.$get('/buyer/sub_invoices.json', { params }),

  claims: (data) =>
    $axios.$post('/buyer/claims.json', objectToFormData({ claim: data })),

  getRecommendationDirectSKUs: (params) =>
    $axios.$get('/buyer/recommendation/pod_direct_variants.json', { params }),

  getWeeklyPromo: () =>
    $axios.$get('/buyer/weekly_ads.json', { params: { date: $moment().format('YYYY/MM/DD') } })
      .then(({ results }) => results[0])
})
