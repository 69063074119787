export const state = () => ({
  unreadNotifications: 0,
  hasChangeNotification: true
})

export const getters = {
  unreadNotifications: s => s.unreadNotifications,
  hasChangeNotification: s => s.hasChangeNotification
}

export const mutations = {
  reset (state) {
    state.unreadNotifications = 0
    state.hasChangeNotification = true
  },
  updateUnreadNotifications (state, payload) {
    state.unreadNotifications = payload
  },
  updateHasChangeNotification (state, payload) {
    state.hasChangeNotification = payload
  }
}

export const actions = {
  connect ({ rootGetters }) {
    this.$cable.connection.disconnect()
    this.$cable.connection.connect(rootGetters['authentication/socketUrl'])
  }
}
