import { VuexPersistence } from 'vuex-persist'
import { version } from '~/package.json'

export default ({ store }) => {
  new VuexPersistence({
    key: version,
    modules: [
      'preferences',
      'history'
    ]
  }).plugin(store)
}
