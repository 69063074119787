const middleware = {}

middleware['buyer'] = require('../middleware/buyer.js')
middleware['buyer'] = middleware['buyer'].default || middleware['buyer']

middleware['head-buyer'] = require('../middleware/head-buyer.js')
middleware['head-buyer'] = middleware['head-buyer'].default || middleware['head-buyer']

middleware['logistics-partner'] = require('../middleware/logistics-partner.js')
middleware['logistics-partner'] = middleware['logistics-partner'].default || middleware['logistics-partner']

middleware['redirect'] = require('../middleware/redirect.js')
middleware['redirect'] = middleware['redirect'].default || middleware['redirect']

middleware['vendor'] = require('../middleware/vendor.js')
middleware['vendor'] = middleware['vendor'].default || middleware['vendor']

export default middleware
