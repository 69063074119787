<template lang="pug">
.debug(v-if="show")
  el-button(
    size="mini"
    @click="toggle"
    v-if="!static"
    icon="pf-icon bx bx-bug-alt icon mr") Debug
  pre(v-if="!collapsed || static")
    code(v-text="json")
</template>

<script>
export default {
  name: 'Debug',

  props: {
    data: {
      type: null,
      required: true
    },

    static: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      collapsed: true
    }
  },

  computed: {
    show () {
      return this.$nuxt?.context?.isDev
    },

    json () {
      return JSON.stringify(this.data, null, 2)
    }
  },

  methods: {
    toggle () {
      this.collapsed = !this.collapsed
    }
  }
}
</script>

<style lang="scss" scoped>
.debug {
  font-size: 11px;
  margin-bottom: 2em;
  pre {
    background: #0F111A;
    color: #eee;
    padding: 1em;
    border-radius: $--radius;
    code {
      font-size: 13px;
      font-family: 'Fantasque Sans Mono', 'Cascadia Mono', monaco, consolas, monospace;
      display: block;
      overflow: hidden;
      white-space: pre-wrap;
      word-break: break-word;
    }
  }
}
</style>
