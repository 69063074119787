import Vue from 'vue'

export default ({ app, store, router, isDev }, inject) => {
  const Dialogs = {
    get container () {
      const container = document.getElementById('global-dialogs')
      return container || document.body
    },

    open ({ parent, component, props = {}, events = {}, destroyOnRouteChanged = true } = {}) {
      if (!window.$nuxt?.$root) {
        return new Error('This methods must run after Vue Hydration.')
      }

      return new Promise((resolve, reject) => {
        const InstanceClass = Vue.extend(component)
        let instance = new InstanceClass({
          store,
          router,
          propsData: props,
          parent: parent || window.$nuxt.$root
        })
        instance.$on('done', resolve)
        Object
          .keys(events)
          .forEach(name => {
            instance.$on(name, events[name].bind(parent))
          })
        instance.$on('close', () => {
          instance.$destroy()
          instance.$el.remove()
          instance = null
          if (typeof events.closed === 'function') events.closed.bind(parent)()
        })
        if (destroyOnRouteChanged) {
          instance.$watch('$route', () => {
            instance.$destroy()
            instance.$el.remove()
            instance = null
          })
        }
        instance.$mount()
        this.container.appendChild(instance.$el)
      })
    },

    flush () {
      this.container.innerHTML = ''
    }
  }

  if (isDev) {
    Vue.prototype.$dialogs = Dialogs
    app.$dialogs = Dialogs
    store.$dialogs = Dialogs
  }

  inject('dialogs', Dialogs)
}
