<template lang="pug">
  .infinite-fetcher
    slot
    .fetcher(
      v-if="canFetchMore && !loading && !disable"
      v-intersect="{ handler: fetchMore, options: options }")
    loading.loading(
      v-if="loading"
      :text="loadingText")
</template>

<script>
import { throttle } from 'lodash'
import Loading from './Loading'

const DELAY_TIME = 1000
const DEFAULT_OPTIONS = {
  threshold: 0.5
}

export default {
  components: {
    Loading
  },

  props: {
    fetch: {
      type: Function,
      required: true
    },

    options: {
      type: Object,
      default: () => (DEFAULT_OPTIONS)
    },

    paginator: {
      type: null,
      required: true
    },

    loadingText: {
      type: String,
      default: 'Loading'
    },

    disable: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      loading: false,
      fetchThrottle: throttle(() => {
        this.fetch(
          { page: this.paginator.page + 1 },
          { clear: false }
        ).finally(() => {
          this.loading = false
        })
      }, DELAY_TIME)
    }
  },

  computed: {
    canFetchMore () {
      if (!this.paginator) return false
      const { page, pages } = this.paginator
      return page < pages
    }
  },

  methods: {
    fetchMore (entries, observer, isIntersecting) {
      if (!this.canFetchMore || this.loading || !isIntersecting) return

      this.loading = true

      this.fetchThrottle()
    }
  }
}
</script>

<style lang="scss" scoped>
.infinite-fetcher {
  .loading {
    transition: opacity 1s ease-in-out;
    &.hidden {
      opacity: 0;
    }
  }
}
</style>
