<script>
import { TableColumn } from 'element-ui'

export default {
  mixins: [TableColumn],

  props: {
    sortOrders: {
      type: Array,
      default () {
        return ['ascending', 'descending']
      },
      validator (val) {
        return val.every(order => ['ascending', 'descending', null].includes(order))
      }
    }
  }
}
</script>
