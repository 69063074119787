<template lang="pug">
#app.layout.layout--cart
  .cart__wrapper
    .cart__header
      announcements-bar
      header-bar

    .cart__content
      nuxt
      footer-bar
  #footer
</template>

<script>
import AnnouncementsBar from '~/components/menus/AnnouncementsBar'
import CatalogHeaderBar from '~/components/menus/CatalogHeaderBar'
import CatalogCartSider from '~/components/CatalogCartSider'
import StaticFooterBar from '~/components/menus/StaticFooterBar'

export default {
  name: 'CartLayout',

  components: {
    AnnouncementsBar,
    HeaderBar: CatalogHeaderBar,
    CartSider: CatalogCartSider,
    FooterBar: StaticFooterBar
  }
}
</script>
