<template lang="pug">
el-dialog(
  custom-class="page__dialog mov-alert-stricted-modal"
  visible
  :close-on-click-modal="!showExpressMovSos"
  :close-on-press-escape="false"
  @close="$emit('close')")

  .page__dialog-header.page__dialog-header--separated(slot="title")
    .page__dialog-preview
      i.page__dialog-icon.pf-alert-background.bx.bxs-error.bx-7(v-if="showExpressMovSos")
      i.page__dialog-icon.pf-success-background.bx.bx-check.bx-7(v-else)

    .page__dialog-info.flex.fdc
      .page__dialog-title Add these items to save on the
        span(:class="colorClasses") &nbsp;{{ sosStructure.flat_fee_cents | cents }}&nbsp;
        | small order surcharge!

      .money-info
        .flex.jcsb
          .remain-money.mb-1
            i.icon.pf-success-background.bx.bx-check.bx-2(v-if="!showExpressMovSos")
            span &nbsp;Add
              strong(:class="colorClasses") &nbsp;{{ amountToDismissSos | cents }}&nbsp;
              | more to meet minimum order value
          .minimum-order-value(v-if="showExpressMovSos")
            small Minimum order value&nbsp;&nbsp;
            strong.value {{ sosAmount | cents }}
        MovStrictedProgressBar

  .skus-grid
    template(v-if="!recommendedSkus.length")
      QuantityAdjustmentSkuCard(
        v-for="i, k in placeholders"
        :key="k")
    template(v-else)
      QuantityAdjustmentSkuCard(v-for="i, k in recommendedSkus" :sku="i" :key="k")
</template>

<script>
import { mapGetters } from 'vuex'
import { REGION_TYPES } from '~/settings'
import QuantityAdjustmentSkuCard from '~/components/sku/QuantityAdjustmentSkuCard'
import MovStrictedProgressBar from '~/components/ProgressBars/MovStrictedProgressBar'

export default {
  components: {
    QuantityAdjustmentSkuCard,
    MovStrictedProgressBar
  },

  props: {
    recommendedSkus: { type: Array, default: () => [] }
  },

  data () {
    return {
      skus: [],
      placeholders: 8
    }
  },

  computed: {
    ...mapGetters({
      REGION_TYPES: () => REGION_TYPES,
      groups: 'cart/groups',
      sosAmount: 'cart/sosAmount',
      sosStructure: 'cart/sosStructure',
      amountToDismissSos: 'cart/amountToDismissSos'
    }),

    showExpressMovSos () {
      return this.amountToDismissSos > 0
    },

    colorClasses () {
      return {
        'pf-success-color': !this.showExpressMovSos,
        'pf-alert-color': this.showExpressMovSos
      }
    }
  },

  methods: {
    addToCart () {
      if (!this.sku.cartable) return
      this.saving = true
      this.$api.catalog.getProductDetails(this.sku.product_id)
        .then((res) => {
          this.$workflow.addItemToCart({
            parent: this,
            product: res,
            variant: res.variants[this.sku.id],
            quantity: 1,
            events: {
              closed: () => {
                this.saving = false
              }
            }
          })
          .finally(() => {
            this.saving = false
          })
        })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .mov-alert-stricted-modal {
    margin-top: 1vh !important;
    max-width: 900px;
    @include media(md) {
      width: 900px;
    }
    .page__dialog-icon {
      color: white;
    }
    .page__dialog-title {
      color: #484748;
    }
    .el-dialog__body {
      background-color: #f4f6f8;
      padding-top: 1em;
    }
  }
}
.skus-grid {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  @include media(sm) {
    grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  }
}
.money-info {
  font-size: 16px;
  margin: 15px 0;
  .remain-money {
    color: #484748;
    .icon {
      color: white;
      border-radius: 10px;
    }
  }
  .minimum-order-value {
    color: #7E858B;
    .value {
      color: #484748;
    }
  }
}
</style>
