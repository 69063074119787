import Vue from 'vue'
import ActionCableVue from 'actioncable-vue'

export default function ({ store }) {
  Vue.use(ActionCableVue, {
    debug: true,
    debugLevel: 'all',
    connectImmediately: false,
    store
  })
}
