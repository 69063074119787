<template lang="pug">
  .el-input.address-autocomplete.street1(:class="{'is-disabled': disabled}")
    input.el-input__inner(ref="address" v-model="street1" :disabled="disabled")
</template>

<script>
import { mapGetters } from 'vuex'
import { Loader } from '@googlemaps/js-api-loader'
import { GOOGLE_MAP_API_KEY } from '@/settings'

export default {
  props: {
    value: {
      type: String,
      default: null
    },
    countries: {
      type: Array,
      default: () => ['us']
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      autoComplete: null,
      street1: this.value ? this.value : ''
    }
  },

  computed: {
    ...mapGetters({
      addressStates: 'master/addressStates'
    }),
    autocompleteOptions () {
      return {
        componentRestrictions: {
          country: this.countries
        },
        fields: ['address_components', 'geometry']
      }
    }
  },

  watch: {
    value (val) {
      if (this.street1 !== val) this.street1 = val
    },

    street1 (a, b) {
      if (a !== b) {
        this.$emit('input', this.street1)
      }
    }
  },

  mounted () {
    const loader = new Loader({
      apiKey: GOOGLE_MAP_API_KEY,
      libraries: ['places']
    })
    loader.load().then(() => {
      this.autoComplete = new window.google.maps.places.Autocomplete(this.$refs.address, this.autocompleteOptions)
      this.autoComplete.addListener('place_changed', this.setAddress)
    })
  },

  methods: {
    setAddress () {
      const val = this.autoComplete.getPlace()
      if (!val) return
      const obj = val.address_components
      if (!obj) return

      const number = obj.find(i => i.types.includes('street_number'))
      const street = obj.find(i => i.types.includes('street_address')) ||
        obj.find(i => i.types.includes('route'))
      const state = obj.find(i => i.types.includes('administrative_area_level_1'))
      const city = obj.find(i => i.types.includes('locality')) ||
        obj.find(i => i.types.includes('administrative_area_level_2'))
      const zip = obj.find(i => i.types.includes('postal_code'))
      const country = obj.find(i => i.types.includes('country'))
      const stateObj = this.addressStates.find(i => i.code === state.short_name)
      const address = {
        number: number ? number.long_name : null,
        street: street ? street.long_name : null,
        address_state: stateObj || null,
        address_state_id: stateObj ? stateObj.id : null,
        city: city ? city.long_name : '',
        zip: zip ? zip.long_name : '',
        country: country ? country.short_name : ''
      }
      address.street1 = [address.number, address.street].join(' ')
      this.$emit('done', address)
      this.$nextTick(() => {
        this.$refs.address.value = address.street1
      })
    }
  }
}
</script>

<style lang="scss">
.pac-container {
  z-index: 3000;
}
</style>
