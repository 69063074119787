import objectToFormData from '~/utilities/object-to-formdata'

export default ({ $axios, store, app }) => ({
  getPersonalInformation: data =>
    $axios.$get('/broker/brokers.json'),
  updatePersonalInformation: data =>
      $axios.$put('/broker/brokers.json', { broker: data }),
  changePassword: data =>
      $axios.$put('/broker/broker_auth.json', data),
  getBrokerCompanies: () =>
    $axios.$get('/broker/broker_companies.json')
      .then(({ broker_company }) => broker_company),
  updateCompanyInformation: data =>
    $axios.$put('/broker/broker_companies.json', objectToFormData({ broker_company: data })),

  // api vendor for this.$api[this.apiRole]
  getInventories: params =>
    $axios.$get('/vendor/inventories.json', { params }),
  getProductVariants: params =>
    $axios.$get('/vendor/product_variants.json', { params }),
  getStores: params =>
    $axios.$get('/vendor/stores.json', { params }),
  getClaim: ({ id, ...params }) =>
      $axios.$get(`/broker/vendor_claims/${id}.json`, { params }),
  getClaims: params =>
    $axios.$get('/broker/vendor_claims.json', { params }),
  createClaim: data =>
    $axios.$post('/broker/vendor_claims.json', objectToFormData({ vendor_claim: data })),
  updateClaim: ({ id, ...data }) =>
    $axios.$put(`/broker/vendor_claims/${id}.json`, objectToFormData({ vendor_claim: data }))
})
