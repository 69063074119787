<script>
import { mapGetters } from 'vuex'
import Select from './BaseSelect'

export default {
  name: 'DepapartmentSelect',
  extends: Select,

  props: {
    filterable: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    ...mapGetters({
      options: 'authentication/paymentOptions'
    })
  }
}
</script>
