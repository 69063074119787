<template lang="pug">
component(
  v-if="summary"
  :class="plain ? '' : 'el-card--modern'"
  :is="plain ? 'div' : 'el-card'"
  shadow="never"
  header="Summary"
  size="small")
  dl.cart__summary
    dt Order Value
    dd.cart__order-value {{ summary.originalValue | cents }}

    template(v-if="summary.discount")
      dt Promotional Discount
      dd.cart__discount -{{ summary.discount | cents }}

    dt.cart__items-subtotal Items Subtotal
    dd.cart__items-subtotal {{ summary.currentValue | cents }}

    template(v-if="order.small_order_surcharge")
      dt.cart__sos
        help-tag(:tooltip="sosTooltip") Small order surcharge
      dd.cart__sos {{ order.small_order_surcharge | cents }}

    //template(v-if="order.logistics_surcharge && order.has_surcharge")
    //  dt.cart__ls
    //    help-tag(tooltip="Temporary delivery surcharge due to global supply chain conditions") Fuel surcharge
    //  dd.cart__ls {{ order.logistics_surcharge | cents }}

    template(v-if="summary.sponsoredDiscount")
      dt.cart__special-discount Special discount
      dd.cart__special-discount -{{ summary.sponsoredDiscount | cents }}

    template(v-if="order.total_state_fee")
      dt.cart__taxes
        help-tag(:tooltip="taxesTooltip") Taxes
      dd.cart__taxes {{ order.total_state_fee | cents }}

    dt.cart__total Total
    dd.cart__total {{ summary.total | cents }}
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    plain: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapGetters({
      order: 'cart/order',
      summary: 'cart/summary',
      sosAmount: 'cart/sosAmount'
    }),

    sosTooltip () {
      return {
        content: `Small order surcharge for<br/>Pod Express order less than <strong>${this.$options.filters.cents(this.sosAmount, true, false)}</strong>`,
        html: true
      }
    },

    taxesTooltip () {
      if (!this.order) return null
      return {
        content: this.order.state_fee_labels.join('</br>'),
        html: true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.cart {
  &__summary {
    display: grid;
    grid-template-columns: 1fr auto;
    margin: 0;
    font-size: 14px;
    grid-gap: .4em 0;
    dd {
      text-align: right;
      margin-left: 0;
      &.cart__discount,
      &.cart__special-discount {
        color: $--color-primary;
      }
    }
    dt {
      &.cart__sos,
      &.cart__ls,
      &.cart__special-discount,
      &.cart__taxes {
        margin-left: 1em;
      }
    }
  }
  &__items-subtotal,
  &__total {
    font-weight: $--font-weight-bold;
  }
  &__total {
    border-top: $--border-light;
    padding-top: .5em;
    margin-top: .5em;
  }
}
</style>
