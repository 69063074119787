<template lang="pug">
.print-view
  nuxt
</template>

<script>
export default {
  head () {
    return this.$seo.head({
      index: false,
      follow: false
    })
  }
}
</script>

<style lang="scss" scoped>
.print-view {
  background: white;
  zoom: .8;
  padding: 15px 20px;
  max-width: 900px;
  margin: 0 auto;

  ::v-deep {
    th {
      text-align: left;
    }
  }
}
</style>
