<template lang="pug">
span.help-tag(v-tooltip="tooltip")
  slot
  i.bx.bxs-help-circle
</template>

<script>
export default {
  props: {
    tooltip: {
      type: [String, Object],
      default: null
    }
  }
}
</script>

<style lang="scss" scoped>
.help-tag {
  cursor: help;
  > i.bx {
    color: $--color-primary;
    vertical-align: middle;
    margin-left: .5em;
  }
}

</style>

<style lang="scss">
.large {
  .tooltip-inner {
    max-width: 320px !important;
  }
}
</style>
