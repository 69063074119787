import numeral from 'numeral'
import { isEmpty, sortBy } from 'lodash'
import { PROMOTION_ACTIONS, PROMOTION_ACTIONS_TYPES } from '~/settings'

export function calculatePromotionPrice (price, promotion, quantity) {
  let discountPrice = price
  let promotionAction = promotion.promotion_actions[0] || {}

  const isStacked = promotion?.promo_action_type === PROMOTION_ACTIONS_TYPES.STACKED
  if (isStacked) {
    const matchedStackPromotionAction = sortBy(promotion?.promotion_actions, 'min_qty').find((i, index, arr) => {
      if (arr[index + 1]) return quantity >= i.min_qty && quantity < arr[index + 1].min_qty
      return quantity >= i.min_qty
    })

    if (!matchedStackPromotionAction) return discountPrice

    promotionAction = matchedStackPromotionAction
  }

  if (promotionAction.type === PROMOTION_ACTIONS.FIX_RATE_ADJUSTMENT) {
    discountPrice = (price >= promotionAction.discount_value) ? numeral(price).subtract(promotionAction.discount_value).value() : 0
  } else if (promotionAction.type === PROMOTION_ACTIONS.PERCENTAGE_ADJUSTMENT) {
    discountPrice = numeral(price).multiply(numeral(1).subtract(promotionAction.discount_value).value()).value()
  }
  return discountPrice
}

export function calculateItemPrice (item, quantity) {
  let promotion = null
  if (Array.isArray(item.promotions) && !isEmpty(item.promotions)) {
    promotion = item.promotions[0]
  }

  if (item.promotion) {
    promotion = item.promotion
  }

  if (!promotion) return numeral(item.case_price_cents).multiply(quantity).value()

  const promotionPrice = calculatePromotionPrice(item.case_price_cents, promotion, quantity)
  if (typeof promotionPrice === 'undefined' || quantity < promotion.minimum_num_case) {
    return numeral(item.case_price_cents).multiply(quantity).value()
  }

  if (promotion.case_limit === null || quantity <= promotion.case_limit) {
    return numeral(promotionPrice).multiply(quantity).value()
  }

  if (quantity > promotion.case_limit) {
    const discountPrice = numeral(promotion.case_limit).multiply(promotionPrice)
    const nonDiscountPrice = numeral(item.case_price_cents).multiply(numeral(quantity).subtract(promotion.case_limit).value()).value()
    return numeral(discountPrice).add(nonDiscountPrice).value()
  }
}

export const caculatePodSponsoredPromotion = (promotions) => {
  if (!Array.isArray(promotions) || isEmpty(promotions)) return 0
  return promotions[0].amount_cents
}
