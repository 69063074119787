export const state = () => ({
  vendorCompanySelected: null,
  vendorCompanies: null,
  brokerCompany: null
})

export const getters = {
  vendorCompanySelected: s => s.vendorCompanySelected,
  vendorCompanies: s => s.vendorCompanies,
  brokerCompany: s => s.brokerCompany
}

export const mutations = {
  updateVendorCompanySelected (state, payload) {
    state.vendorCompanySelected = payload
  },
  updateVendorCompanies (state, payload) {
    state.vendorCompanies = payload
  },
  updateBrokerCompany (state, payload) {
    state.brokerCompany = payload
  },

  reset (state) {
    state.vendorCompanySelected = null
    state.vendorCompanies = null
    state.brokerCompany = null
  }
}

export const actions = {
  getBrokerCompanies ({ commit, dispatch, getters, rootGetters, ...context }, params) {
    return this.$api.broker.getBrokerCompanies(params)
      .then(({ vendor_companies, ...brokerCompany }) => {
        let vendorCompanyId = rootGetters['preferences/byKey']('lastVendorCompany')

        if (process.server) {
          // refresh page
          const headers = rootGetters['authentication/headers']
          vendorCompanyId = headers['vendor-company-id']
        }

        const vendorCompany = vendor_companies.find(e => e.id === +vendorCompanyId)

        commit('updateBrokerCompany', brokerCompany)
        commit('updateVendorCompanies', vendor_companies)
        commit('updateVendorCompanySelected', vendorCompany)
      })
  }
}
