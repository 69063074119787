<template lang="pug">
i.price-change-stamp.pf-icon.bx(
  v-if="show"
  v-tooltip="tooltip"
  :class="classes")
</template>

<script>

export default {
  name: 'PriceChangeStamp',

  props: {
    variant: {
      type: Object,
      required: true
    }
  },

  computed: {
    classes () {
      return this.variant.old_case_price_cents < this.variant.case_price_cents
        ? 'increased bx-up-arrow-alt'
        : 'decreased bx-down-arrow-alt'
    },

    appliedDays () {
      return this.$moment().diff(this.$moment(this.variant.price_applied_since), 'd')
    },

    show () {
      return this.variant.old_case_price_cents && this.appliedDays <= 15
    },

    tooltip () {
      const label = `The SKU price has been ${this.variant.old_case_price_cents < this.variant.case_price_cents ? 'increased' : 'decreased'} `
      const dayText = this.appliedDays > 1 ? 'days' : 'day'

      return this.appliedDays === 0 ? label + 'today' : label + `since the last ${this.appliedDays} ${dayText}`
    }
  }
}
</script>

<style lang="scss" scoped>
.price-change-stamp {
  &.increased {
    color: $--color-success;
  }
  &.decreased {
    color: $--color-danger;
  }
}
</style>
