import { normalizeInactiveSku, normalizeProductDetails } from '~/utilities/normalizers'
import { deserializeQuery } from '~/utilities/catalog'
import { PRODUCT_RECOMMENDATION_GROUP } from '~/settings'
import { isEmpty } from 'lodash'

export default ({ store, app, $axios, error }) => ({
  searchProducts (query = {}) {
    const role = store.getters['authentication/apiRole']
    const isApprovedCompany = store.getters['authentication/isApprovedCompany']
    const headBuyerHasAssignedRegions = store.getters['authentication/headBuyerHasAssignedRegions']

    let endpoint

    if (isApprovedCompany)
       endpoint = {
        buyer: '/buyer/search.json',
        headBuyer: headBuyerHasAssignedRegions && '/head_buyer/search.json',
        vendor: '/vendor/search.json',
        broker: '/vendor/search.json'
      }[role]

    // GUEST
    if (!endpoint) endpoint = '/search.json'

    const params = deserializeQuery(query)

    return $axios.$get(endpoint, { params })
  },

  getProductDetails (id, config) {
    const role = store.getters['authentication/apiRole']
    const isApprovedCompany = store.getters['authentication/isApprovedCompany']
    const headBuyerHasAssignedRegions = store.getters['authentication/headBuyerHasAssignedRegions']

    let endpoint

    if (isApprovedCompany)
      endpoint = {
        buyer: `/buyer/products/${id}.json`,
        headBuyer: headBuyerHasAssignedRegions && `/head_buyer/products/${id}.json`,
        vendor: `/vendor/catalog/products/${id}.json`,
        broker: `/vendor/catalog/products/${id}.json`
      }[role]

    // GUEST
    if (!endpoint) endpoint = `/products/${id}.json`

    return $axios.$get(endpoint, config)
      .then((res) => {
        if (['buyer', 'headBuyer'].includes(role) && !res.variants && res.all_variants) {
          res.variants = normalizeInactiveSku(res.all_variants)
        }

        if (!res.variants || isEmpty(res.variants)) {
          error({ statusCode: 404 })

          return Promise.reject(new Error('Variants not found!'))
        }

        return res
      })
      .then(role !== 'headBuyer' && normalizeProductDetails)
  },

  getProductRecommendations: ({ id } = {}) => {
    const role = store.getters['authentication/apiRole']
    const isApprovedCompany = store.getters['authentication/isApprovedCompany']
    const headBuyerHasAssignedRegions = store.getters['authentication/headBuyerHasAssignedRegions']
    const productRecommendationGroup = store.getters['master/productRecommendationGroup']

    let endpoint

    if (isApprovedCompany)
      endpoint = {
        buyer: (productRecommendationGroup === PRODUCT_RECOMMENDATION_GROUP.B)
          ? '/buyer/recommendation/pod_direct_products.json'
          : '/buyer/recommendation/products.json',
        headBuyer: headBuyerHasAssignedRegions && '/head_buyer/recommendation/products.json'
      }[role]

    // GUEST
    if (!endpoint) endpoint = '/recommendation/products.json'

    return $axios.$get(endpoint, { params: { product_id: id } })
  },

  searchBrands (params) {
    const role = store.getters['authentication/apiRole']
    const isApprovedCompany = store.getters['authentication/isApprovedCompany']
    const headBuyerHasAssignedRegions = store.getters['authentication/headBuyerHasAssignedRegions']

    let endpoint

    if (isApprovedCompany)
      endpoint = {
        buyer: '/buyer/brands.json',
        headBuyer: headBuyerHasAssignedRegions && '/head_buyer/brands.json',
        vendor: '/vendor/catalog/brands.json',
        broker: '/vendor/catalog/brands.json',
        lp: '/logistics_partner/brands.json'
      }[role]

    // GUEST
    if (!endpoint) endpoint = '/brands.json'

    return $axios.$get(endpoint, { params })
  },

  getBrandDetails (id, config) {
    const role = store.getters['authentication/apiRole']
    const endpoint = {
      buyer: `/buyer/brands/${id}.json`,
      vendor: `/vendor/catalog/brands/${id}.json`,
      broker: `/vendor/catalog/brands/${id}.json`,
      lp: `/logistics_partner/brands/${id}.json`
    }[role] || `/brands/${id}.json`

    return $axios.$get(endpoint, config)
  },

  fetchAddressStates: () =>
    $axios.$get('/address_states.json'),

  fetchProductCategories: () =>
    $axios.$get('/product_categories.json'),

  fetchFilterFields: () =>
    $axios.$get('/filter_fields.json'),

  createPurchaseRequest: data =>
    $axios.$post('/purchase_requests.json', data)
})
