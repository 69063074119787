<template lang="pug">
client-only
  #app.layout.layout--dashboard
    announcements-bar
    dashboard-header-bar
    #content
      client-only
        .inner
          dashboard-side-bar
          main
            .dashboard-content
              nuxt
            //- nuxt(keep-alive)
            static-footer-bar
</template>

<script>
import AnnouncementsBar from '~/components/menus/AnnouncementsBar'
import DashboardHeaderBar from '~/components/menus/DashboardHeaderBar'
import StaticFooterBar from '~/components/menus/StaticFooterBar'
import DashboardSideBar from '~/components/menus/DashboardSideBar'

export default {
  name: 'CatalogLayout',

  components: {
    AnnouncementsBar,
    DashboardHeaderBar,
    StaticFooterBar,
    DashboardSideBar
  },

  head () {
    return this.$seo.head({
      index: false,
      follow: false
    })
  }
}
</script>

<style lang="scss" src="~/assets/dashboard.scss"></style>

<style lang="scss" scoped>
.inner {
  @include media(sm) {
    display: flex;
    flex-direction: row;
  }
  > main {
    flex: 1;
    min-width: 0;
    ::v-deep .container {
      @include media(sm) {
        padding-left: 1.5em;
      }
    }
    .dashboard-content {
      min-height: calc(100vh - 96px - 310px);
    }
  }
}
</style>
