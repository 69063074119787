import objectToFormData from '~/utilities/object-to-formdata'

export default ({ $axios, store, app }) => ({
  getSupportData: () =>
    $axios.$get('/logistics_partner/supports/dashboard_data.json'),

  getTermsOfService: () =>
    $axios.$get('/logistics_partner/lp_term_of_service.json')
      .then(res => res.term_of_service),

  getDropOrder: ({ id, ...params }) =>
    $axios.$get(`/logistics_partner/drop_purchase_orders/${id}.json`, { params }),

  getOrder: ({ id, ...params }) =>
    $axios.$get(`/logistics_partner/purchase_orders/${id}.json`, { params }),

  updateOrder: ({ id, ...data }) =>
    $axios.$put(`/logistics_partner/purchase_orders/${id}.json`, objectToFormData({
      purchase_order: data
    })),

  confirmOrder: ({ id, ...params }) =>
    $axios.$put(`/logistics_partner/purchase_orders/${id}/confirm.json`, params),

  confirmOrders: ({ ...params }) =>
    $axios.$put('logistics_partner/multiple/purchase_orders/confirm.json', params),

  getOrders: params =>
    $axios.$get('/logistics_partner/purchase_orders.json', { params }),

  getInventory: (id) =>
    $axios.$get(`/logistics_partner/inventories/${id}.json`),

  updateInventory: ({ id, ...data }) =>
    $axios.$put(`/logistics_partner/inventories/${id}.json`, objectToFormData({
      inventory: data
    })),

  createInventory: (params) =>
    $axios.$post('/logistics_partner/inventories.json', objectToFormData({
      inventory: params
    })),

  getInboundInventory: (id) =>
    $axios.$get(`/logistics_partner/inbound_inventories/${id}.json`),

  exportInboundInventories: () =>
    $axios.$get('logistics_partner/export/inventories.json'),

  exportPdfInboundInventory: (id) =>
    $axios.$get(`/logistics_partner/export/inbound_inventories/${id}/pdf`, { responseType: 'blob' }),

  updateInboundInventory: ({ id, ...data }) =>
    $axios.$put(`/logistics_partner/inbound_inventories/${id}.json`, objectToFormData({
      inbound_inventory: data
    }, {
      allowEmptyArrays: true
    })),

  getInventories: params =>
    $axios.$get('/logistics_partner/inventories.json', { params }),

  getInboundInventories: params =>
    $axios.$get('/logistics_partner/inbound_inventories.json', { params }),

  getInventoryLowQty: params =>
    $axios.$get('/logistics_partner/regional_inventory/product_variants.json', { params }),

  getProductVariants: params =>
    $axios.$get('/logistics_partner/product_variants.json', { params }),

  exportOrders: params =>
    $axios.$get('/logistics_partner/export/purchase_orders.json', { params })
      .then(({ results }) => results),

  getPersonalInformation: () =>
    $axios.$get('/logistics_partner/logistics_partners.json')
      .then(res => res.logistics_partner),

  updatePersonalInformation: (data) =>
    $axios.$put('/logistics_partner/logistics_partners.json', objectToFormData({
      logistics_partner: data
  })),

  changePassword: data =>
    $axios.$put('/logistics_partner/logistics_partner_auth.json', data),

  getInventoryWithdrawalRequests: params =>
    $axios.$get('/logistics_partner/withdraw_inventories.json', { params }),

  getInventoryWithdrawalRequest: ({ id, ...params }) =>
    $axios.$get(`/logistics_partner/withdraw_inventories/${id}.json`, { params }),

  updateInventoryWithdrawalRequest: ({ id, ...data }) =>
    $axios.$put(`/logistics_partner/withdraw_inventories/${id}.json`, { withdraw_inventory: data }),

  getCompanyInformation: () =>
    $axios.$get('/logistics_partner/logistics_companies.json'),

  updateCompanyInformation: (data) =>
    $axios.$put('/logistics_partner/logistics_companies.json', objectToFormData({
      logistics_company: data
    })),

  getInventoryRequests: params =>
    $axios.$get('/logistics_partner/inventory_requests.json', { params }),

  getInventoryRequest: ({ id, ...params }) =>
    $axios.$get(`/logistics_partner/inventory_requests/${id}.json`, { params }),

  updateInventoryRequest: ({ id, ...data }) =>
    $axios.$put(`/logistics_partner/inventory_requests/${id}.json`, objectToFormData({ inventory_request: data })),

  markCompleteInventoryRequest: ({ id, ...params }) =>
    $axios.$put(`/logistics_partner/inventory_requests/${id}/mark_as_completed.json`, { params }),

  exportLineItems: ({ ...params }) =>
    $axios.$get('/logistics_partner/export/line_items.json', { params }),

  downloadSubInvoices: ({ ...params }) =>
    $axios.$get('/logistics_partner/export/multiple/pdf/sub_invoices.pdf', { params, responseType: 'blob' }),

  downloadPackingSlips: ({ ...params }) =>
    $axios.$get('/logistics_partner/export/multiple/packing_slip/purchase_orders.pdf', { params, responseType: 'blob' }),

  confirmDropPurchaseOrder: ({ id, ...params }) =>
    $axios.$put(`/logistics_partner/drop_purchase_orders/${id}/confirm.json`, params),

  updateDropPurchaseOrder: ({ id, ...data }) =>
    $axios.$put(`/logistics_partner/drop_purchase_orders/${id}.json`, objectToFormData({
      purchase_order: data
    })),

  getPodFreights: params => $axios.$get('/logistics_partner/pod_freights.json', { params }),

  getPodFreight: ({ id }) => $axios.$get(`/logistics_partner/pod_freights/${id}.json`),

  updatePodFreight: ({ id, ...data }) =>
    $axios.$put(`/logistics_partner/pod_freights/${id}.json`, objectToFormData({
      pod_freight: data
    }))
})
