<template lang="pug">
el-dialog(
  custom-class="page__dialog consignment-instructions-modal"
  visible
  :show-close="false"
  :close-on-click-modal="false"
  @close="$emit('close')")

  .page__dialog-header.page__dialog-header--separated(slot="title")
    .page__dialog-preview
      i.page__dialog-icon.bx.bx-package

    .page__dialog-info
      .page__dialog-title General Instructions
      .page__dialog-description
        strong IMPORTANT -&nbsp;
        | Please read and follow all steps carefully for delivery

  .instructions
    ol
      li By submitting this inbound you agree to the&nbsp;
        a.linked(
          :href="CONSIGNMENT_TERMS_URL"
          target="_blank"
          title="Consignment Terms") Consignment Terms.
      li
        strong Complete this Inbound inventory form with ETA and case count, freight carrier, and pallet breakdown at least 72 hours prior to arrival.&nbsp;
        span.underline.important Completing the inbound inventory form will generate an Inbound Reference Number that must be added to all shipping documents.
        ul
          li If you're sending a mixed pallet, cases must be organized by SKU
          li Only 1 expiration date per SKU is allowed
          li Without a delivery appointment, freight will be turned away
          li Inventory must follow to&nbsp;
            a.linked(
              target="_blank"
              title="Case pack requirements"
              :href="CASE_PACK_REQUIREMENTS_URL") case pack requirements
      li
        p If delivering via freight carrier (i.e. TForce, YRC, XPO, Saia, Fedex Freight) the carrier MUST make the appointment with the warehouse directly.
      li
        p Your BOL (Bill of Lading) must indicate the number of master cases per pallet.
      li
        p All inbound shipments must be labeled with a&nbsp;
          strong Pod Inbound Label.

    img(alt="Inbound inventory general instructions" src="/img/consignment-instructions.webp" width="100%")

  .page__dialog-footer(slot="footer")
    el-button(
      type="primary"
      @click="submit") Confirm
</template>

<script>
import { CONSIGNMENT_TERMS_URL, CASE_PACK_REQUIREMENTS_URL } from '~/settings'
export default {
  data () {
    return {
      CONSIGNMENT_TERMS_URL,
      CASE_PACK_REQUIREMENTS_URL
    }
  },

  watch: {
    $route () {
      this.$emit('close')
    }
  },

  methods: {
    submit () {
      this.$emit('done')
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .consignment-instructions-modal {
    max-width: 600px;
  }
}
.page__dialog-icon {
  color: $--color-warning;
  background: lighten($--color-warning, 40);
}
.instructions {
  ol {
    margin: 0;
    padding: 0 0 0 1.5rem;
    > li {
      &:not(:last-child) {
        margin-bottom: .5em;
      }
    }
  }
  ul {
    margin-top: .5em;
  }
  .yellow {
    font-weight: $--font-weight-bold;
    color: $--color-warning;
  }
  .important {
    font-weight: $--font-weight-bold;
    color: $--color-primary;
  }
  .underline {
    font-weight: bold;
    text-decoration: underline;
  }
  a.linked {
    font-weight: $--font-weight-bold;
    text-decoration: underline;
  }
}
</style>
