<script>
import { TimeSelect } from 'element-ui'
import { ELEMENT_TIME_FORMAT } from '~/settings'

export default {
  mixins: [TimeSelect],

  props: {
    format: {
      type: String,
      default: ELEMENT_TIME_FORMAT
    },
    placeholder: {
      type: String,
      default: 'Select'
    },
    pickerOptions: {
      type: Object,
      default () {
        return {
          start: '00:00',
          end: '23:30'
        }
      }
    }
  }
}
</script>
