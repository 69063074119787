<template lang="pug">
.express-tag
  img.icon(src="/img/tag.express.svg")
  span(v-if="explained") {{ POD_EXPRESS_EXPLANATION }}
</template>

<script>
import { POD_EXPRESS_EXPLANATION } from '~/settings'
export default {
  props: {
    explained: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return { POD_EXPRESS_EXPLANATION }
  }
}
</script>

<style lang="scss" scoped>
.express-tag {
  font-size: 12px;
  color: $--color-text-secondary;
  display: inline-flex;
  > *:not(:last-child) {
    margin-right: 1em;
  }
}
</style>
