import { take, uniq } from 'lodash'

export const HISTORY_LENGTH = 9

export const state = () => ({
  brands: [],
  products: [],
  keywords: []
})

export const getters = {
  keywords: (s, g) => take(s.keywords || [], HISTORY_LENGTH),
  brands: (s, g) => take(s.brands || [], HISTORY_LENGTH),
  products: (s, g) => take(s.products || [], HISTORY_LENGTH)
}

export const mutations = {
  addKeyword (state, term) {
    const keyword = term?.trim() || ''
    if (!keyword) return
    state.keywords = take(uniq([keyword, ...state.keywords]), HISTORY_LENGTH)
  },

  addBrand (state, record) {
    const brand = {
      thumbnail: record.logo_url?.medium_url,
      id: record.id,
      name: record.name
    }
    const log = state.brands.filter(i => i.id !== brand.id)
    state.brands = take([brand, ...log], HISTORY_LENGTH)
  },

  addProduct (state, record) {
    const product = {
      brand: {
        id: record.brand?.id,
        name: record.brand?.name
      },
      id: record.id,
      name: record.name,
      thumbnail: record.variants?.[record.master_variant_id]?.image_urls?.thumbnail
    }
    const log = state.products.filter(i => i.id !== product.id)
    state.products = take([product, ...log], HISTORY_LENGTH)
  },

  clearKeywords (state) {
    state.keywords = []
  },

  clearBrands (state) {
    state.brands = []
  },

  clearProducts (state) {
    state.products = []
  },

  reset (state) {
    state.keywords = []
    state.brands = []
    state.products = []
  }
}
