<template lang="pug">
el-dialog.vendor-capital-modal(
  visible
  @close="close")
  .background
    .message.request(data-aos="fade") Get the credit you deserve!
    .message.response(data-aos="fade" data-aos-delay="300") Approved!
      i.bx.bxs-check-circle

  .content
    .icon
      i.bell.bx.bxs-bell-ring
    h1 Pod Foods Capital is now live!
    p Get your business moving and accelerate growth with quick and easy access to funding.

    a.el-button.el-button--primary.db(
      @click.prevent="navigate"
      href="/capital"
      title="Click for details") See details

    el-checkbox.toggler(v-model="hidden" label="Never show this again")
</template>

<script>
export default {
  data () {
    return {
      hidden: false
    }
  },

  methods: {
    close () {
      this.$emit('done', this.hidden)
      this.$emit('close')
    },

    navigate () {
      this.close()
      this.$store?.$router.push({
        name: 'capital'
      })?.catch(this.$debug.log)
    }
  }
}
</script>

<style lang="scss" scoped>
.vendor-capital-modal {
  padding: 0;
  ::v-deep {
    .el-dialog {
      border-radius: $--radius-sm;
      max-width: 560px;
      position: relative;
      overflow: hidden;
      @include media(xs-only) {
        max-width: 320px;
      }
      &__header {
        position: absolute;
        width: 100%;
        left: 0;
        right: 0;
        top: 0;
        z-index: 2;
      }
      &__body {
        padding: 0;
        display: flex;
        flex-direction: column;
        word-break: break-word;
        align-items: center;
        @include media(sm) {
          flex-direction: row;
        }
        > * {
          min-width: 0;
        }
      }
    }
  }
}

@keyframes ring {
  0% { transform: rotateZ(0); }
  1% { transform: rotateZ(30deg); }
  3% { transform: rotateZ(-28deg); }
  5% { transform: rotateZ(34deg); }
  7% { transform: rotateZ(-32deg); }
  9% { transform: rotateZ(30deg); color: lighten($--color-primary, 4); }
  11% { transform: rotateZ(-28deg); }
  13% { transform: rotateZ(26deg); }
  15% { transform: rotateZ(-24deg); }
  17% { transform: rotateZ(22deg); }
  19% { transform: rotateZ(-20deg); }
  21% { transform: rotateZ(18deg); }
  23% { transform: rotateZ(-16deg); }
  25% { transform: rotateZ(14deg); }
  27% { transform: rotateZ(-12deg); }
  29% { transform: rotateZ(10deg); }
  31% { transform: rotateZ(-8deg); }
  33% { transform: rotateZ(6deg); }
  35% { transform: rotateZ(-4deg); }
  37% { transform: rotateZ(2deg); }
  39% { transform: rotateZ(-1deg); }
  41% { transform: rotateZ(1deg); }
  43% { transform: rotateZ(0); color: $--color-primary; }
  100% { transform: rotateZ(0); }
}

.background {
  background-image: url(/img/vendor-capital.cover.webp);
  background-size: cover;
  background-position: center;
  background-color: $--color-placeholder;
  align-self: stretch;
  flex: 0 280px;
  min-height: 300px;
  padding: 150px 48px 0;
  @include media(sm) {
    min-height: 360px;
    padding: 180px 24px 0;
  }
  .message {
    font-size: 13px;
    line-height: 1.35em;
    width: fit-content;
    margin: 12px 0;
    &.request {
      color: white;
      background: $--color-primary;
      border-radius: 20px 20px 20px 4px;
      padding: 10px 16px;
      margin-right: auto;
    }
    &.response {
      color: white;
      background: white;
      border-radius: 20px 20px 4px 20px;
      padding: 10px 16px;
      color: $--color-text-regular;
      margin-left: auto;
      i {
        color: $--color-success;
        font-size: 16px;
        margin-left: 4px;
        vertical-align: middle;
      }
    }
  }
}

.content {
  padding: 0 32px;
  flex: 1;
  @include media(xs-only) {
    text-align: center;
    padding: 24px 48px 32px;
  }
  .icon {
    background: lighten($--color-primary, 50);
    color: $--color-primary;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    border-radius: 48px;
    margin-bottom: 16px;
    font-size: 24px;
    @include media(xs-only) {
      display: none;
    }
    i {
      animation: ring 4s .7s ease-in-out infinite;
      transform-origin: 50% 4px;
    }
  }
  h1 {
    font-size: 24px;
    line-height: 1.25em;
  }
  p {
    font-size: 13px;
    color: $--color-text-secondary;
    line-height: 1.6em;
  }
  .links {
    margin-top: 24px;
    font-size: 0;
    a {
      &:not(:last-child) {
        margin-right: 8px;
      }
    }
  }
  .toggler {
    font-size: 13px;
    margin-top: 2em;
    ::v-deep {
      .el-checkbox {
        &__label {
          font-size: 13px;
        }
      }
    }
  }
}
</style>
