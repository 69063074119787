import Vue from 'vue'
import * as amplitude from '@amplitude/analytics-browser'
import { AMPLITUDE_API_KEY, IS_PRODUCTION_SITE } from '~/settings'
import { isString, isObject } from 'lodash'

const ZONES_EXCLUDES = ['CATALOG_CART_SIDER']

export default function (context, inject) {
  const { store, isDev, app } = context
  amplitude
    .init(
      AMPLITUDE_API_KEY,
      undefined, {
      minIdLength: 1,
      defaultTracking: {
        attribution: true,
        pageViews: true,
        sessions: true,
        formInteractions: false,
        fileDownloads: true
      }
    })

  const amp = {
    instance: amplitude,
    sync: () => {
      const user = store.getters['authentication/user']
      const role = store.getters['authentication/role']
      const isVendor = store.getters['authentication/isVendor']
      const isBroker = store.getters['authentication/isBroker']
      const isBuyer = store.getters['authentication/isBuyer']
      const isHeadBuyer = store.getters['authentication/isHeadBuyer']
      const vendorCompanySelected = store.getters['broker/vendorCompanySelected']

      const identifyObj = new amplitude.Identify()

      identifyObj.set('email', user?.email || null)
      identifyObj.set('user_role', role || null)

      if (window.PODFOODS_INTERNAL) {
        identifyObj.set('internal', true)
      }

      if (isHeadBuyer || isBuyer) {
        identifyObj.set('is_head_buyer', user?.is_head_buyer)
        identifyObj.set('buyer_company_id', user?.buyer_company_id)
        identifyObj.set('store_id', user?.store_id)
      }

      if (isVendor && !isBroker) {
        identifyObj.set('vendor_id', user?.id)
        identifyObj.set('vendor_company_id', user?.vendor_company_id)
      }

      if (isBroker) {
        identifyObj.set('broker_id', user?.id)
        identifyObj.set('broker_company_id', user?.broker_company_id)
        identifyObj.set('vendor_company_id', vendorCompanySelected?.id)
      }

      amplitude.identify(identifyObj)
      amplitude.setUserId(user?.id)
    },
    track: (event, props) => {
      amplitude.track(event, props)
        .promise.then(() => {
        // eslint-disable-next-line no-console
        if (!IS_PRODUCTION_SITE) console.log(`${event}:`, props)
      })
    },
    trackProduct: (payload) => {
      const { from, route } = context

      let eventProps = {
        product_id: route.params?.id,
        route_name: from.name,
        route_full_path: from.fullPath
      }

      if (from.name === 'products' && !ZONES_EXCLUDES.includes(payload.zone || payload)) eventProps.keyword = from.query?.term
      if (isString(payload)) eventProps.zone = payload
      if (isObject(payload)) eventProps = { ...eventProps, ...payload }

      amplitude.track('VIEW_PRODUCT_DETAIL', eventProps)
        .promise.then(() => {
          // eslint-disable-next-line no-console
          if (!IS_PRODUCTION_SITE) console.log('VIEW_PRODUCT_DETAIL: ', eventProps)
        })
    },
    trackBrand: (payload) => {
      const { from, route } = context

      let eventProps = {
        brand_id: route.params?.id,
        route_name: from.name,
        route_full_path: from.fullPath
      }

      if (from.name === 'brands') eventProps.keyword = from.query?.term
      if (isString(payload)) eventProps.zone = payload
      if (isObject(payload)) eventProps = { ...eventProps, ...payload }

      amplitude.track('VIEW_BRAND_DETAIL', eventProps)
        .promise.then(() => {
        // eslint-disable-next-line no-console
        if (!IS_PRODUCTION_SITE) console.log('VIEW_BRAND_DETAIL', eventProps)
      })
    }
  }

  if (isDev) {
    Vue.prototype.$amplitude = amp
    app.$amplitude = amp
    store.$amplitude = amp
  }

  inject('amplitude', amp)
}
