<script>
import { Select } from 'element-ui'

export default {
  extends: Select,

  computed: {
    readonly () {
      const isIE = !this.$isServer && !isNaN(Number(document.documentMode))
      // return !this.filterable || this.multiple || (!isIE && !this.visible && !this.remote)
      if (!this.filterable) return true
      return !(this.filterable || this.multiple || !isIE) && !this.visible
    }
  }
}
</script>
