<script>
import { mapGetters } from 'vuex'
import Select from './BaseSelect'

export default {
  name: 'AddressStateSelect',
  extends: Select,

  props: {
    filterable: {
      type: Boolean,
      default: true
    },

    country: {
      type: Number,
      default: null
    }
  },

  computed: {
    ...mapGetters({
      allOptions: 'master/addressStates'
    }),

    options () {
      return this.country
        ? this.allOptions.filter(i => i.country_id === this.country)
        : this.allOptions
    }
  }
}
</script>
