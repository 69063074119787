<template lang="pug">
section.announcements-bar(v-if="current")
  component.announcement.pf-clamp-3(
    :is="current.link ? 'a' : 'div'"
    :href="current.link"
    target="_blank"
    @click="learnMore")
    a.announcement__close(
      href="#"
      @click.prevent="close"
      v-if="!marking")
      i.bx.bx-x

    i.announcement__icon.bx.bx-volume-full
    span.announcement__title(v-if="current.title" v-text="current.title")
    span.announcement__description(v-if="current.body" v-text="current.body")
    span.announcement__external(
      v-if="current.link"
      v-text="externalText")

</template>

<script>
import { mapGetters } from 'vuex'

export default {
  data () {
    return {
      marking: false
    }
  },

  computed: {
    ...mapGetters({
      current: 'authentication/firstAnnouncement'
    }),

    externalText () {
      return this.current?.link_title || 'Learn more.'
    }
  },

  methods: {
    markAsRead () {
      this.marking = true
      return this.$store
        .dispatch('authentication/markAnnouncementAsRead', this.current)
        .finally(() => {
          this.marking = false
        })
    },

    learnMore () {
      if (this.current?.link)
        return this.markAsRead()
    },

    close () {
      this.markAsRead()
    }
  }
}
</script>

<style lang="scss" scoped>
@keyframes gradient {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}
.announcements-bar {
  $first: darken($--color-primary, 0);
  $second: darken(#fbb122, 10);
  font-size: 0;
  background: linear-gradient(45deg, $first, $second, $first);
  background-size: 400% 400%;
  animation: gradient 6s ease infinite;
  filter: contrast(1.1);
  cursor: default;
  background-clip: border-box;
  position: relative;
  z-index: 1;
  // text-shadow: 0 1px 0px rgba(black, .25);
  .announcement {
    position: relative;
    display: block;
    color: white;
    font-size: 13px;
    text-align: left;
    word-break: break-word;
    padding: .75em 3.5em .75em .75em;
    @include media(md) {
      padding: .75em 3.5em;
      font-size: 14px;
      text-align: center;
    }
    > * {
      vertical-align: middle;
      display: inline;
      &:not(:last-child) {
        margin-right: .5em;
      }
    }
    &__icon {
      font-size: 1.5em;
      line-height: 1em;
      animation: uneven-blinker 3s linear infinite;
    }
    &__title {
      font-weight: $--font-weight-bold;
      line-height: 1.25em;
    }
    &__description {
      line-height: 1.25em;
    }
    &__external {
      text-decoration: underline;
    }
    &__close {
      position: absolute;
      margin: 0 !important;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 24px;
      width: 44px;
      height: 44px;
      right: 0;
      top: 0;
    }
  }
}
</style>
