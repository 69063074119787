<template lang="pug">
el-dialog.credit-card-editor.md(
  visible
  :close-on-click-modal="false"
  @close="$emit('close')"
  :title="title")

  el-alert.mb-2(
    v-if="showNotice"
    type="warning"
    :closable="false"
    title="Please input your business credit card to easily pay us for your orders moving forward.")

  el-form(
    ref="form"
    :model="form"
    :rules="rules"
    v-loading="submitting")
    el-row(:gutter="20")
      el-col(:md="12")
        el-form-item
          .title Credit Card
        el-form-item.boxed(prop="name" label="Name on card")
          el-input.card-name(v-model="form.name")
        el-form-item.boxed.is-required(label="Card number")
          .card-input.card-number(id="card-number" ref="cardNumber" :class="{ 'is-error': stripeError.cardNumber }")
          .el-form-item__error(v-if="stripeError.cardNumber") {{ stripeError.cardNumber }}
        el-form-item.boxed.is-required(label="Expiration date")
          .card-input.card-expiry(id="card-expiry" ref="cardExpiry" :class="{ 'is-error': stripeError.cardExpiry }")
          .el-form-item__error(v-if="stripeError.cardExpiry") {{ stripeError.cardExpiry }}
        el-form-item.boxed.is-required(label="CVC")
          .card-input.card-cvc(id="card-cvc" ref="cardCvc" :class="{ 'is-error': stripeError.cardCvc }")
          .el-form-item__error(v-if="stripeError.cardCvc") {{ stripeError.cardCvc }}
      el-col(:md="12")
        el-form-item
          .title Billing Information
        el-form-item.boxed(prop="address_line1" label="Address line 1")
          el-input.address_line_1(v-model="form.address_line1")
        el-form-item.boxed(prop="address_line2" label="Address line 2")
          el-input.address_line_2(v-model="form.address_line2")
        el-form-item.boxed(prop="address_city" label="City")
          el-input.city(v-model="form.address_city")
        el-form-item.boxed(prop="address_state" label="State")
          el-input.zip(v-if="form.address_country !== 'United States'" v-model="form.address_state")
          el-select.entity-select.db(
            v-else
            autocomplete="new-password"
            ref="element"
            v-model="form.address_state"
            :filterable="true")
            el-option(v-for="i, key in allStates" :key="key" :label="i.name" :value="i.name")
        el-form-item.boxed(prop="address_zip" label="Zip")
          el-input.zip(v-model="form.address_zip")
        el-form-item.boxed(prop="address_country" label="Country")
          country-select.db(v-model="form.address_country" :countryOptions="COUNTRIES")

    .tr
        el-button(type="primary" @click="submit") Submit
</template>

<script>
import { mapGetters } from 'vuex'
import COUNTRIES from '~/assets/countries.json'

export default {
  props: {
    edit: {
      type: Number,
      default: null
    },

    showNotice: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      submitting: false,
      COUNTRIES,
      form: {
        address_line1: '',
        address_line2: '',
        address_city: '',
        address_zip: '',
        address_state: '',
        address_country: 'United States'
      },
      element: {
        cardNumber: null,
        cardExpiry: null,
        cardCvc: null
      },
      stripeError: {
        cardNumber: null,
        cardExpiry: null,
        cardCvc: null
      },
      stripeFilled: {
        cardNumber: false,
        cardExpiry: false,
        cardCvc: false
      }
    }
  },

  computed: {
    ...mapGetters({
      payments: 'authentication/payments',
      allStates: 'master/addressStates'
    }),

    title () {
      return 'New Credit Card'
    },

    rules () {
      return {
        name: {
          required: true,
          message: 'This field cannot be blank.'
        },
        address_line1: {
          required: true,
          message: 'This field cannot be blank.'
        },
        address_city: {
          required: true,
          message: 'This field cannot be blank.'
        },
        address_state: {
          required: true,
          message: 'This field cannot be blank.'
        },
        address_zip: {
          required: true,
          message: 'This field cannot be blank.'
        },
        address_country: {
          required: true,
          message: 'This field cannot be blank.'
        }
      }
    }
  },

  watch: {
    'form.address_country' () {
      this.form.address_state = ''
    }
  },

  mounted () {
    this.setupStripe()
  },

  methods: {
    setupStripe () {
      if (this.$stripe) {
        this.$nextTick(() => {
          const elements = this.$stripe.elements()
          const options = {
            style: {
              base: {
                fontSize: '16px',
                fontWeight: 'bold',
                '::placeholder': {
                  color: '#C0C4CC'
                }
              },
              invalid: {
                color: '#000'
              }
            }
          }
          this.element.cardNumber = elements.create('cardNumber', { ...options })
          this.element.cardExpiry = elements.create('cardExpiry', { ...options })
          this.element.cardCvc = elements.create('cardCvc', { ...options })
          this.element.cardNumber.mount(this.$refs.cardNumber)
          this.element.cardExpiry.mount(this.$refs.cardExpiry)
          this.element.cardCvc.mount(this.$refs.cardCvc)

          this.handleStripeError()
        })
      }
    },

    handleStripeError () {
      this.element.cardNumber.addEventListener('change', (event) => {
        if (event.complete) {
          this.stripeFilled.cardNumber = true
          this.stripeError.cardNumber = null

          return
        }

        if (event.empty) {
          this.stripeFilled.cardNumber = false
          this.stripeError.cardNumber = 'This field cannot be blank'

          return
        }

        this.stripeError.cardNumber = (event.error?.message || 'Your card number is invalid')
      })

      this.element.cardExpiry.addEventListener('change', (event) => {
        if (event.complete) {
          this.stripeFilled.cardExpiry = true
          this.stripeError.cardExpiry = null

          return
        }

        if (event.empty) {
          this.stripeFilled.cardExpiry = false
          this.stripeError.cardExpiry = 'This field cannot be blank'

          return
        }

        this.stripeError.cardExpiry = (event.error?.message || 'Your expiration date is invalid')
      })

      this.element.cardCvc.addEventListener('change', (event) => {
        if (event.complete) {
          this.stripeFilled.cardCvc = true
          this.stripeError.cardCvc = null

          return
        }

        if (event.empty) {
          this.stripeFilled.cardCvc = false
          this.stripeError.cardCvc = 'This field cannot be blank'

          return
        }

        this.stripeError.cardCvc = (event.error?.message || 'Your CVC is invalid')
      })
    },

    validateCard () {
      Object.entries(this.stripeFilled).forEach(([key, value]) => {
        this.stripeError[key] = value ? null : 'This field cannot be blank'
      })

      return Object.values(this.stripeError).every(e => !e)
    },

    submit () {
      this.$refs.form.validate(ok => {
        const cardValid = this.validateCard()

        if (!ok || !cardValid) return

        this.submitting = true
        this.$stripe.createToken(this.element.cardNumber, this.form)
          .then(res => {
            const { token, error } = res
            if (error) {
              this.$workflow.error(error.message)
              return Promise.reject(new Error(error.message))
            }

            const action = this.payments?.stripe_customer_id ? 'authentication/updatePaymentInformation' : 'authentication/createPaymentInformation'

            return this.$store.dispatch(action, { stripe_token: token.id, payment_type: 'credit_card' })
          })
          .then((res) => {
            this.$workflow.notify('Updated successfully.')
            this.$emit('done')
            this.$emit('close')
          })
          .finally(() => {
            this.submitting = false
          })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.help-text {
  margin-bottom: 1em;
}
.card-input {
  position: relative;
  font-size: 16px;
  display: block;
  width: 100%;
  background-color: #fff;
  background-image: none;
  border-radius: $--radius;
  border: 1px solid #DCDFE6;
  box-sizing: border-box;
  color: #fff;
  line-height: 40px;
  outline: none;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  padding-top: 30px;
  padding-bottom: 9px;
  height: 60px;
  &.is-error{
    border-color: $--color-danger;
  }
}
.__PrivateStripeElement{
  width: 100%;
  height: auto;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.35em;
}
</style>
