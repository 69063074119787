import Vue from 'vue'
import { get } from 'lodash'

export function validatorsFn ({ $moment }) {
  return {
    required: {
      required: true,
      message: 'This field cannot be blank'
    },

    requiredFile: {
      required: true,
      message: 'Please upload an image'
    },

    phone: {
      message: 'Please enter a valid 10-digits phone number',
      pattern: /^\d{10}$/ig
    },

    zip: {
      pattern: /^\d{5}$/g,
      message: 'Please enter a valid 5-digits zip code'
    },

    zipUC: {
      pattern: /^[\w\d]{5,6}$/g,
      message: 'Please enter a valid zip code'
    },

    ein: {
      pattern: /^\d{9}$/g,
      message: 'Please enter a valid 9-digits EIN'
    },

    upc: {
      type: 'string',
      pattern: /^\d{12}$/,
      message: 'UPC must be 12-digit number'
    },

    ean: {
      type: 'string',
      pattern: /^\d{13}$/,
      message: 'EAN must be 13-digit number'
    },

    email: {
      type: 'email',
      message: 'Please enter a valid email'
    },

    password: {
      type: 'string',
      pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d\W]{8,}$/,
      message: 'At least 1 letter, a number, at least 8 characters.'
    },

    address: {
      validator: (rule, value, callback) => {
        if (!value.street1)
          return callback(new Error('Please enter more specific address'))
        if (!value.zip)
          return callback(new Error('Please enter more specific address with zip code included'))
        if (!value.address_state_id)
          return callback(new Error('Please entere an address in supported stated'))
        return callback()
      }
    },

    startEnd ({ start, end, onlyTime = false }) {
      return {
        validator: (rule, value, callback) => {
          if (!start || !end) return callback()

          const startT = onlyTime ? $moment(start, 'hh:mm') : $moment(start)
          const endT = onlyTime ? $moment(end, 'hh:mm') : $moment(end)
          const errorMessage = onlyTime ? 'Latest Time must be after Earliest Time.' : 'Start date must be earlier than end date'

          if (startT.isAfter(endT))
            return callback(new Error(errorMessage))

          return callback()
        }
      }
    },

    greater (num, equal = true) {
      return {
        validator: (rule, value, callback) => {
          if ([null, undefined, ''].includes(value)) return callback()

          const invalid = equal ? +value < num : +value <= num

          if (invalid) {
            return callback(new Error(`Value must be greater than ${equal ? 'or equal to ' : ''}${num}`))
          }

          return callback()
        }
      }
    },

    less (num, equal = true) {
      return {
        validator: (rule, value, callback) => {
          if ([null, undefined].includes(value)) return callback()

          const invalid = equal ? +value > num : +value >= num

          if (invalid) {
              return callback(new Error(`Value must be less than ${equal ? 'or equal to ' : ''}${num}`))
          }

          return callback()
        }
      }
    },

    integer: {
      validator: (rule, value, callback) => {
        if ([null, undefined].includes(value)) return callback()

        if (!Number.isInteger(+value)) {
          return callback(new Error('Value must be an integer.'))
        }
        return callback()
      }
    },

    requestDuplicate (form, isArray = false) {
      return {
        validator: ({ field }, newVal, callback) => {
          let key = field
          if (isArray) key = field.split('.').pop()

          const currentKey = field.replace(key, `__${key}`)
          const currentVal = get(form, currentKey)

          if (newVal === currentVal)
            return callback(new Error('The current value and new value cannot be identical.'))

          return callback()
        }
      }
    }

  }
}

export default (context, inject) => {
  const validators = validatorsFn(context)
  const { app } = context

  if (context.isDev) {
    Vue.prototype.$validators = validators
    context.app.$validators = validators
    context.store.$validators = validators
  }

  inject('validators', validators)
  inject('validator', type => validators[type])

  inject('validate', form => {
    return new Promise((resolve, reject) => {
      if (!form) return resolve(true)
      form.validate(ok => {
        if (!ok) {
          // notifying
          app.$workflow.error('Please correct the errors on this form before continuing.')
          // translating
          for (const i in form.fields) {
            const field = form.fields[i]
            if (field.validateState === 'error') {
              const rect = field.$el.getBoundingClientRect()
              if (rect) window.scrollTo(0, rect.top + window.scrollY - 72)
              // focusing
              // for (const j in field.$children) {
              //   const focuser = field.$children[j]
              //   if (focuser.focus) {
              //     focuser.focus()
              //     break
              //   }
              // }
              break
            }
          }
          return resolve(false)
        }
        return resolve(true)
      })
    })
  })
}
