<template lang="pug">
#app.layout.layout--catalog(:class="classes")
  .catalog__wrapper(:class="wrapperClasses")
    .catalog__header(v-intersect="{handler: handleSticky, options: { threshold: 1 }}")
      announcements-bar(ref="announcement")
      header-bar(ref="headerBar")

    .catalog__content
      client-only
        .catalog__toggler(
          v-if="shouldShowCartSider"
          @click="toggle")
          i.bx(:class="collapsed ? 'bx-chevron-left' : 'bx-chevron-right'")

      nuxt(
        keep-alive
        :keep-alive-props="{ max: 4, include: cachePages }")

      footer-bar

    .catalog__sider(v-if="shouldShowCartSider")
      client-only(v-if="!collapsed")
        cart-sider(:height="height")
  #footer
</template>

<script>
import { mapGetters } from 'vuex'
import AnnouncementsBar from '~/components/menus/AnnouncementsBar'
import CatalogHeaderBar from '~/components/menus/CatalogHeaderBar'
import CatalogCartSider from '~/components/CatalogCartSider'
import StaticFooterBar from '~/components/menus/StaticFooterBar'

export default {
  name: 'CatalogLayout',

  components: {
    AnnouncementsBar,
    HeaderBar: CatalogHeaderBar,
    CartSider: CatalogCartSider,
    FooterBar: StaticFooterBar
  },

  data () {
    return {
      height: '100vh'
    }
  },

  computed: {
    ...mapGetters({
      isBuyer: 'authentication/isBuyer',
      isPreApprovalBuyer: 'authentication/isPreApprovalBuyer'
    }),

    shouldShowCartSider () {
      return this.isBuyer && !this.isPreApprovalBuyer
    },

    classes () {
      return {
        'layout--with-sider': this.shouldShowCartSider
      }
    },

    wrapperClasses () {
      return {
        'catalog__wrapper--collapsed': this.collapsed,
        'catalog__wrapper--expanded': !this.collapsed && this.shouldShowCartSider
      }
    },

    cachePages () {
      return ['Brands', 'Catalogs']
    },

    collapsed: {
      get () {
        return this.$preferences?.get('catalogSidebarCollapsed', false) || false
      },

      set (val) {
        return this.$preferences.set('catalogSidebarCollapsed', val)
      }
    }
  },

  mounted () {
    this.$nextTick(() => {
      const isCollapsedCartSidebar = this.$preferences?.get('catalogSidebarCollapsed', false) || false
      this.$bus.$emit('re-render-catalog-recommended-product-swiper-slide', isCollapsedCartSidebar)
    })
  },

  methods: {
    toggle () {
      this.collapsed = !this.collapsed
      this.$bus.$emit('re-render-catalog-recommended-product-swiper-slide', this.collapsed)
    },

    handleSticky (entries, observer, isIntersecting) {
      const heightAnnouncement = this.$refs.announcement?.$el?.clientHeight || 0
      const heightHeaderBar = this.$refs.headerBar?.$el?.clientHeight || 160
      this.height = isIntersecting ? `calc(100vh - ${heightHeaderBar + heightAnnouncement}px)` : '100vh'
    }
  }
}
</script>

<style lang="scss" scoped>
.layout {
  @mixin for-supported-layout() {
    @media only screen and (min-width: 1397px) {
      @content;
    }
  }

  &--with-sider {
    .catalog {
      &__wrapper {
        @include for-supported-layout {
          display: grid;
          grid-template-columns: 1fr 360px;
          grid-template-rows: auto 1fr;
          grid-template-areas:
            'header header'
            'content sider';
        }
        &--collapsed {
          grid-template-columns: 1fr 0px;
        }
        > * {
          min-width: 0;
        }
      }
      &__header {
        grid-area: header;
      }
      &__content {
        grid-area: content;
        position: relative;
      }
      &__sider {
        grid-area: sider;
        display: none;
        position: relative;

        @include for-supported-layout {
          display: block;
;
        }
      }
      &__toggler {
        cursor: pointer;
        position: sticky;
        margin-bottom: -40px;
        margin-left: auto;
        display: none;
        right: 0;
        top: 45px;
        width: 24px;
        height: 40px;
        font-size: 20px;
        align-items: center;
        justify-content: center;
        color: white;
        background: $--color-primary;
        border-radius: $--radius 0 0 $--radius;
        @include for-supported-layout {
          display: flex;
        }
      }
    }
  }
}
</style>
