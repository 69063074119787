<script>
import CatalogHeaderBar from './CatalogHeaderBar'

export default {
  extends: CatalogHeaderBar,

  props: {
    showCategories: {
      type: Boolean,
      default: false
    },
    showNotification: {
      type: Boolean,
      default: true
    }
  }
}
</script>
