<script>
import { mapGetters } from 'vuex'
import Select from './BaseSelect'

export default {
  name: 'RelevantStoreSelect',
  extends: Select,

  props: {
    filterable: {
      type: Boolean,
      default: true
    },

    api: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    ...mapGetters({
      role: 'authentication/role',
      apiRole: 'authentication/apiRole'
    })
  },

  methods: {
    search (...args) {
      const stores = this.$api.vendor.getRelevantStores(...args)
      return stores || Promise.reject(new Error('No handler provided'))
    },

    fetch (args) {
      const modifier = { ...args, 'q[store_id]': args.id }
      const stores = this.$api.vendor.getRelevantStores(modifier)
      return stores || Promise.reject(new Error('No handler provided'))
    }
  }
}
</script>
