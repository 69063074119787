<template lang="pug">
.paginator(:class="{'paginator--flex': !inlined}")
  .paginator__stats Showing&nbsp;
    strong.paginator__count {{ data.count | number }}
    | &nbsp;over&nbsp;
    strong.paginator__total {{ data.total | number }}
    | &nbsp;
    span {{ data.total > 1 ? 'records' : 'record' }}.

  el-pagination.paginator__wrapper(
    layout="prev, pager, next"
    hide-on-single-page
    :small="small"
    :pager-count="pagerCount"
    :current-page="data.page"
    :page-count="data.pages"
    @current-change="push")
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => ({
        total: 0,
        page: 1,
        pages: 0
      })
    },

    manuallyPageChange: {
      type: Boolean,
      default: false
    },

    inlined: {
      type: Boolean,
      default: false
    },

    pagerCount: {
      type: Number,
      default: 7
    },

    small: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    push (page) {
      this.$emit('change', page)

      if (this.manuallyPageChange) return

      this.$router.push({
        query: {
          ...this.$route.query,
          page
        }
      })?.catch(this.$debug.log)
    }
  }
}
</script>

<style lang="scss" scoped>
.paginator {
  &--flex {
    display: flex;
    align-items: center;
    flex-direction: column;
    @include media(md) {
      flex-direction: row;
    }
    > * {
      min-width: 0;
    }
    .paginator {
      &__stats {
        flex: 1;
        color: $--color-text-secondary;
        @include media(md) {
          margin-right: .5em;
        }
        strong {
          color: $--color-primary;
          margin: 0 .35em;
        }
      }
    }
  }
  &--chart {
    text-align: left;
    .paginator {
      &__stats {
        min-height: 32px;
        line-height: 32px;
      }
    }
  }
}
</style>
