<template lang="pug">
.empty(:style="{height: `${height}px`}" :class="{ 'large': large }")
  .empty__animation(
    v-if="typeof image === 'object'"
    :class="{'empty__animation--small': image.small}")
    lottie(:options="image")

  img.empty__image(:src="image" v-else)

  .empty__info
    .empty__title
      span(v-if="title" v-text="title")
      slot(v-else name="title")

    .empty__description
      span(v-if="description" v-text="description")
      slot(v-else name="description")

  .empty__actions(v-if="$slots.default")
    slot
</template>

<script>
import Lottie from 'vue-lottie'

export default {
  components: {
    Lottie
  },

  props: {
    height: {
      type: [Number],
      default: null
    },

    title: {
      type: String,
      default: 'No results found...'
    },

    description: {
      type: String,
      default: 'We couldn\'t find any matches.'
    },

    type: {
      type: [String, Number],
      default: null
    },
    large: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    image () {
      switch (this.type) {
        case 500:
        case 403:
        case 404:
          return {
            animationData: require('~/assets/lotties/error.json'),
            animationSpeed: 1,
            loop: true,
            autoplay: true
          }

        case 'payments':
          return `/img/empty.${this.type}.svg`

        case 'empty.cart':
        case 'empty.catalog':
          return {
            animationData: require(`~/assets/lotties/${this.type}.json`),
            animationSpeed: 1,
            loop: true,
            autoplay: true
          }

        default:
          return {
            animationData: require('~/assets/lotties/empty.json'),
            animationSpeed: 1,
            loop: true,
            autoplay: true,
            small: true
          }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.empty {
  padding: 2em 0;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  max-width: 640px;
  align-items: center;
  justify-content: center;
  > * {
    padding: 0 10px;
  }
  &__animation {
    min-height: 180px;
    &--small {
      max-width: 200px;
    }
  }
  &__info {
    text-align: center;
    max-width: 320px;
    margin: 1em auto 0 auto;
  }
  &__actions {
    margin-top: 1.5em;
    border: none;
  }
  &__image {
    margin-bottom: 1em;
    height: 150px;
  }
  &__title {
    font-size: 1.25em;
    font-weight: bold;
  }
  &__description {
    font-size: .9em;
    line-height: 1.5em;
    margin-top: .5em;
    color: $--color-text-secondary;
  }

  &.large {
    .empty__info {
      @include media(md) {
        max-width: 640px;
      }
    }
  }
}
</style>
