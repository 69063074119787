<template lang="pug">
  el-select.entity-select(
    autocomplete="new-password"
    ref="element"
    v-model="transfer"
    v-bind="{...$attrs, ...bind}"
    default-first-option
    :loading="filtering"
    :remote="api"
    :filterable="isFilterable"
    :remote-method="remote"
    :class="selectClass"
    :popper-class="popperClass"
    @change="change"
    @clear="clear"
  )
    slot
    el-option(v-if="none" :value="null" :label="none")

    el-option(v-if="limit" v-for="i, key in limit" :key="key" :label="i.name" :value="i.id")
      slot(name="option" v-bind="i")

    template(v-if="!limit")
      el-option(v-if="!api && options" v-for="i, key in options" :key="key" :label="i.name" :value="i.id")
        slot(name="option" v-bind="i")
      el-option(v-if="api && results" v-for="i, key in results" :key="key" :label="i.name" :value="i.id")
        slot(name="option" v-bind="i")
</template>

<script>
import { kebabCase, isEqual, uniqBy } from 'lodash'
export default {
  name: 'BaseSelect',

  props: {
    value: {
      type: [Number, String, Array],
      default: null
    },

    none: {
      type: String,
      default: null
    },

    api: {
      type: Boolean,
      default: false
    },

    preload: {
      type: Boolean,
      default: false
    },

    autoFetch: {
      type: null,
      default: true
    },

    limit: {
      type: Array,
      default: null
    },

    filterable: {
      type: Boolean,
      default: false
    },

    fill: {
      type: Array,
      default: () => []
    },

    queries: {
      type: Object,
      default: () => ({})
    },

    bind: {
      type: Object,
      default: () => ({})
    }
  },

  data () {
    return {
      transfer: this.value,
      filtering: false,
      results: [...this.fill]
    }
  },

  computed: {
    options: () => [],

    isFilterable () {
      if (this.filterable) return true
      if (this.api) return true
      return false
    },

    selectClass () {
      return `${kebabCase(this.$options.name)}`
    },

    popperClass () {
      const val = this.bind ? this.bind.popperClass || this.bind['popper-class'] : null
      return val || `popper-${kebabCase(this.$options.name)}`
    }
  },

  watch: {
    value () {
      if (!isEqual(this.transfer, this.value)) {
        this.transfer = this.value
        if (!this.offline && this.autoFetch && this.fetch && this.value) this.prefetch()
      }
    },

    transfer (a, b) {
      if (!isEqual(a, b)) this.$emit('input', this.transfer)
    }
  },

  mounted () {
    if (!this.offline && this.preload) this.remote()
    if (!this.offline && this.autoFetch && this.fetch && this.value) this.prefetch()
  },

  methods: {
    params (q) {
      return {
        ...this.queries,
        'q[name]': q
      }
    },

    remote (q) {
      if (!q) return
      if (this.search && q.length > 0) {
        this.filtering = true
        this.search(this.params(q))
          .then(({ results }) => {
            if (Array.isArray(this.value)) {
              const selected =
                this.results
                  .filter(i => this.value.includes(i.id))
                  .map(i => ({ ...i, hidden: true }))
              this.results = uniqBy([
                ...results,
                ...selected
              ], 'id')
            } else {
              this.results = [
                ...results
              ]
            }
          })
          .finally(() => {
            this.filtering = false
          })
      }
    },

    async prefetch () {
      if (typeof this.autoFetch === 'object') {
        this.results = [
          ...(Array.isArray(this.autoFetch) ? this.autoFetch : [this.autoFetch]),
          ...this.results
        ]
        return
      }
      if (this.value === null) return
      this.filtering = true
      const get = id => this.fetch({ id })
        .then(res => {
          const data = res.results && res.results.length ? res.results[0] : res
          this.$emit('reselect', data)
          const exist = this.results.find(i => i.id === data.id)
          if (!exist) this.results.push(data)
        })
      if (Array.isArray(this.value)) await Promise.all(this.value.map(i => get(i)))
      else await get(this.value)
      this.filtering = false
    },

    focus (...args) {
      const element = this.$refs.element
      if (element) element.focus(...args)
    },

    blur (...args) {
      const element = this.$refs.element
      if (element) element.blur(...args)
    },

    change (id) {
      const data = this.api ? this.results : this.options
      const record = data.find(i => i.id === id)
      if (record) this.$emit('select', record)
      this.$emit('change', id)
    },

    clear () {
      this.$emit('clear')
    }
  }
}
</script>
