<script>
import { mapGetters } from 'vuex'
import Select from './BaseSelect'

export default {
  name: 'RouteSelect',
  extends: Select,

  props: {
    filterable: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    ...mapGetters({
      routes: 'master/routes'
    }),

    options () {
      return this.routes
    }
  }
}
</script>
