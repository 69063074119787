<template lang="pug">
.shippo-attribute-tag(:type="type") {{ type | cap }}
</template>

<script>

export default {
  name: 'ShippoAttributeType',

  props: {
    type: {
      type: String,
      default: 'None'
    }
  }
}
</script>

<style lang="scss">
.shippo-attribute-tag {
  border-radius: 3px;
  padding: 2px 5px;
  display: inline-block;
  white-space: nowrap;
  font-size: 0.8rem;
  font-weight: normal;
  color: white;
  &[type=BESTVALUE] {
    background: #22b9ca;
  }
  &[type=FASTEST] {
    background: #b02386;
  }
  &[type=CHEAPEST] {
    background: #fa3;
  }
}
</style>
