<template lang="pug">
.broker
  el-dropdown(trigger="click" @command="hideBrokerOnboardingPopup(POPUP_REFS.BROKER_VENDOR_USER_INFO)")
    el-popover(
      :ref="POPUP_REFS.BROKER_VENDOR_USER_INFO"
      placement="bottom"
      trigger="manual"
      width="280"
      popper-class="el-popover--success onboarding-popover")
      i.icon.pf-icon.bx.bx-user.mr-2.broker-icon(slot="reference")
      .content Click here to manage your account.
      .tr.mt-2
        el-button(type="text" @click="hideBrokerOnboardingPopup(POPUP_REFS.BROKER_VENDOR_USER_INFO)") Close
    el-dropdown-menu(slot="dropdown")
      .broker-info
        b {{ user.first_name }} {{ user.last_name }}
        span {{ user.email }}
      el-dropdown-item
        nuxt-link(:to="{ name: 'brokers-settings-general' }")
          i.icon.pf-icon.bx.bx-cog
          span Manage my account
      el-dropdown-item
        nuxt-link(:to="{ name: 'brokers-settings-notifications' }")
          i.icon.pf-icon.bx.bx-envelope
          span Manage email notifications
      el-dropdown-item
        .broker-logout(@click="logout")
          i.icon.pf-icon.bx.bx-log-out
          span Log out

  .broker-select
    el-popover(
      :ref="POPUP_REFS.BROKER_VENDOR_COMPANY_SELECT"
      placement="bottom"
      trigger="manual"
      width="280"
      popper-class="el-popover--success onboarding-popover")
      el-select(
        slot="reference"
        v-model="company"
        @change="handleVendorCompanyChange"
        placeholder="Company")
        el-option(v-for="com in vendorCompanies" :value="com.id" :label="com.name" :key="com.id")
      .content Click here to switch between other vendor companies.
      .tr.mt-2
        el-button(type="text" @click="hideBrokerOnboardingPopup(POPUP_REFS.BROKER_VENDOR_COMPANY_SELECT)") Close
</template>

<script>
import { mapGetters } from 'vuex'
import { POPUP_REFS } from '~/settings'

export default {
  name: 'GetCapitalCta',

  data () {
    if (this.$route.query?.vendor_company_id) {
      this.$preferences.update({
        lastVendorCompany: +this.$route.query?.vendor_company_id,
        lastBrandId: null,
        lastIndexBrandId: null
      })
    }

    const company = this.$preferences.get('lastVendorCompany')

    return {
      POPUP_REFS,
      company
    }
  },

  computed: {
    ...mapGetters({
      vendorCompanySelected: 'broker/vendorCompanySelected',
      vendorCompanies: 'broker/vendorCompanies',
      isBroker: 'authentication/isBroker',
      user: 'authentication/user'
    })
  },

  mounted () {
    this.$nextTick(() => {
      if (
        this.isBroker &&
        !this.$preferences.get(`$hide_${POPUP_REFS.BROKER_VENDOR_COMPANY_SELECT}`)
      ) {
        this.$refs[POPUP_REFS.BROKER_VENDOR_COMPANY_SELECT]?.doShow()
      }
    })
  },

  methods: {
    logout () {
      return this
        .$confirm('Are you sure you want to log out?', {
          title: 'Log out',
          confirmButtonText: 'Log out',
          cancelButtonText: 'Cancel',
          type: 'warning'
        })
        .then(_ => {
          return this.$store.dispatch('authentication/logout')
        })
        .catch(this.$debug.log)
    },
    hideBrokerOnboardingPopup (refName) {
      this.$refs[refName]?.doClose()
      this.$preferences.set(`$hide_${refName}`, true)

      if (
        refName === POPUP_REFS.BROKER_VENDOR_COMPANY_SELECT &&
        !this.$preferences.get(`$hide_${POPUP_REFS.BROKER_VENDOR_USER_INFO}`)
      ) {
        this.$refs[POPUP_REFS.BROKER_VENDOR_USER_INFO].doShow()
      }
    },
    handleVendorCompanyChange (value) {
      const vCompany = this.vendorCompanies.find(e => e.id === value)
      const headers = this.$store.getters['authentication/headers']

      this.$store.commit('broker/updateVendorCompanySelected', vCompany)
      this.$store.commit('authentication/updateHeaders', { ...headers, 'vendor-company-id': value })
      this.$store.dispatch('master/sync')

      this.$preferences.update({
        lastVendorCompany: value,
        lastBrandId: null,
        lastIndexBrandId: null
      })

      this.$router.push({ name: 'welcome-vendor' })

      this.hideBrokerOnboardingPopup(POPUP_REFS.BROKER_VENDOR_COMPANY_SELECT)
    }
  }
}
</script>

<style lang="scss" scoped>
.broker {
  display: inline-block;
  &-select {
    display: inline-block;
    max-width: 180px;
  }
  &-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-bottom: $--border-light;
    padding-bottom: 10px;
  }
  .broker-icon {
    cursor: pointer;
  }
}
</style>
