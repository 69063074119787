<template lang="pug">
#app.layout.layout--form
  announcements-bar
  static-header-bar
  .page-form.with-cover
    .cover
    .content
      nuxt
</template>

<script>
import AnnouncementsBar from '~/components/menus/AnnouncementsBar'
import StaticHeaderBar from '~/components/menus/StaticHeaderBar'

export default {
  name: 'FormLayout',

  components: {
    AnnouncementsBar,
    StaticHeaderBar
  }
}
</script>
