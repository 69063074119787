<template lang="pug">
  el-dialog(
    custom-class="page__dialog sample-request-modal"
    visible
    :disabled="saving"
    :close-on-click-modal="false"
    @close="$emit('close')")

    .page__dialog-header(slot="title")
      .page__dialog-preview
        i.page__dialog-icon.bx.bxs-eyedropper
      .page__dialog-info
        .page__dialog-title Request sample
        .page__dialog-description(v-text="product.name")

    el-form(
      ref="form"
      :model="form"
      :rules="rules"
      :disabled="saving"
      label-position="top")

      el-form-item(label="Shipping Address" prop="address_id")
        .default-address
          el-radio-group.db(v-model="form.address_id" @change="handleChangeAddress")
            el-radio.address--info(v-for="store in storeAddresses" :key="store.address_attributes.id" :label="store.address_attributes.id")
              address
                .store-name {{ store.store_attributes.name }}
                address-stamp(:address="store.address_attributes" inline)
                .store-phone {{ store.store_attributes.phone_number }}

            el-radio.address--info(v-for="(address, k) in customAddresses" :key="address.address_attributes.id" :label="address.address_attributes.id")
              .address--wrap
                address
                  .store-name {{ address.address_attributes.full_name }}
                  address-stamp(:address="address.address_attributes" inline)
                  .store-phone {{ address.address_attributes.phone_number }}
                .flex.aic
                  el-button-group
                    el-button(size="mini" @click="handleEditAddress(address)")
                      i.bx.bx-edit.bx-5
                    el-button(size="mini" @click="handleDeleteAddress(address)" :disabled="address.__deleting")
                      i.bx.bx-trash.bx-5

            el-radio.address--info(:label="CUSTOM_ADDRESS")
              span Choose another address

      shipping-address-form(
        ref="addressForm"
        v-if="form.address_id === CUSTOM_ADDRESS"
        v-model="form.shipping_address")

      el-form-item(label="Select flavors" prop="items")
        .skus-grid
          .sku(
            v-for="v, vk in skus"
            :key="vk"
            :class="{ selected: form.items.includes(v.id) }"
            @click="toggleSku(v)")
            .select(@click.stop)
              el-checkbox-group(v-model="form.items")
                el-checkbox(:label="v.id") {{ '' }}
            .preview
              product-variant-stamp.plain(
                :data="v"
                showImage
                show-variant-name
                show-case-units
                show-badge
                show-price
                show-price-change
                show-code)
        el-alert(v-if="!skus.length" type="warning" :closable="false")
          span No SKU available for this selected store.

      el-form-item.boxed.comments(label="Comments" rop="sample_request.comment")
        el-input(type="textarea" rows="3" v-model="form.sample_request.comment")

    .page__dialog-footer(slot="footer")
      el-button(type="primary" @click="submit") Submit
</template>

<script>
import { intersection } from 'lodash'
import { mapGetters } from 'vuex'
import AddressStamp from '~/components/stamps/AddressStamp'
import ProductVariantStamp from '~/components/stamps/ProductVariantStamp'
import ShippingAddressForm from '~/components/samples/ShippingAddressForm'
import HeadBuyerShippingAddressModal from '~/components/modals/HeadBuyerShippingAddressModal'

const CUSTOM_ADDRESS = 'CUSTOM_ADDRESS'

export default {
  components: {
    AddressStamp,
    ProductVariantStamp,
    ShippingAddressForm
  },

  props: {
    product: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      CUSTOM_ADDRESS,
      customAddresses: [],
      storeAddresses: [],
      form: {
        items: [],
        sample_request: {},
        shipping_address: {},
        address_attributes: {},
        address_id: undefined
      },
      formAddresses: {},
      rules: {
        items: {
          type: 'array',
          required: true,
          min: 1,
          message: 'Please select at least one flavor'
        },
        address_id: { required: true, message: 'Please select a shipping address.' }
      },
      saving: false
    }
  },

  fetch () {
    return this.getAddresses()
  },

  computed: {
    ...mapGetters({
      currentUser: 'authentication/user',
      isHeadBuyer: 'authentication/isHeadBuyer',
      availabilities: 'master/productVariantAvailabilities',
      store: 'authentication/store'
    }),

    allowed () {
      return this.availabilities.filter(i => i.samplable).map(i => i.id)
    },

    storeAddressSelected () {
      return this.storeAddresses.find(e => e.address_attributes.id === this.form.address_id)
    },

    skus () {
      const variantAvailabilities = Object.values(this.product.variants).filter(i => {
        const availabilities = i.variants_regions_info?.region_info?.map(e => e.availability)

        return intersection(this.allowed, availabilities).length
      })

      if (this.storeAddressSelected) {
        return variantAvailabilities.filter(e => e.variants_regions_info?.region_info?.some(({ region_id }) => this.storeAddressSelected?.store_attributes?.region_available_ids.includes(region_id)))
      }

      return variantAvailabilities
    },

    title () {
      return 'Sample Request - ' + this.product.name
    }
  },

  mounted () {
    this.resetSelectedSku()
  },

  methods: {
    resetSelectedSku () {
      if (this.skus.length === 1) this.toggleSku(this.skus[0])
    },

    handleChangeAddress () {
      this.$set(this.form, 'items', [])
      this.resetSelectedSku()
    },

    handleEditAddress (address) {
      this.$set(this.form, 'address_attributes', address.address_attributes.id)

      this.$dialogs.open({
        component: HeadBuyerShippingAddressModal,
        props: {
          address
        },
        events: {
          done: (data) => {
            this.$set(address, 'address_attributes', data)
          }
        }
      })
    },

    getAddresses () {
      return this.$api.headBuyer.getAddresses()
        .then(({ results: { store_addresses, custom_addresses } }) => {
          this.storeAddresses = store_addresses
          this.customAddresses = custom_addresses

          const lastAddress = this.$preferences.get('$lastAddress')

          if ([...this.customAddresses, ...this.storeAddresses].some(e => e.address_attributes.id === lastAddress)) {
            this.form.address_id = lastAddress
          }
        })
    },

    handleDeleteAddress (address) {
      this.$confirm('Are you sure you want to remove this address?')
        .then(_ => {
          this.$set(address, '__deleting', true)

          this.$api.headBuyer.deleteAddress(address.address_attributes.id)
            .then(() => {
              this.$workflow.notify('Deleted successfully.')
              this.customAddresses = this.customAddresses.filter(e => e.address_attributes?.id !== address.address_attributes?.id)

              if (address.address_attributes?.id === this.form.address_id) {
                this.form.address_id = undefined
              }
            })
            .finally(() => {
              this.$set(address, '__deleting', false)
            })
        })
    },

    toggleSku (sku, index) {
      const exists = this.form.items.includes(sku.id)

      this.$set(
        this.form,
        'items',
        exists
          ? this.form.items.filter(id => id !== sku.id)
          : [...this.form.items, sku.id]
      )
    },

    validate () {
      return Promise.all([
        this.$refs.form.validate(),
        this.$refs.addressForm?.validate()
      ]).catch(e => Promise.reject(e))
    },

    submit () {
      const payload = {
        sample_request: { ...this.form.sample_request },
        product_id: this.product.id,
        items: this.form.items.map(e => ({ product_variant_id: e }))
      }

      this.validate().then(async () => {
        this.saving = true

        if (this.form.address_id === CUSTOM_ADDRESS) {
          const res = await this.$api.headBuyer.createAddress({ address: this.form.shipping_address })

          payload.address_id = res.id
        } else {
          payload.address_id = this.form.address_id
        }

        this.$api.headBuyer.createSampleRequest(payload)
          .then(res => {
            this.$emit('done', res)
            this.$emit('close')
            this.$preferences.set('$lastAddress', payload.address_id)
          })
          .catch(() => {
            if (this.form.address_id !== CUSTOM_ADDRESS) return

            this.$preferences.set('$lastAddress', payload.address_id)
            this.getAddresses()
          })
          .finally(() => {
            this.saving = false
          })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .sample-request-modal {
    max-width: 600px;
  }
}
.default-address {
  .address--info {
    display: flex;
    margin-bottom: 1em;
    margin-right: 0;
    white-space: break-spaces;

    ::v-deep {
      .el-radio__input {
        display: flex;
        align-items: center;
      }
      .el-radio__label {
        width: 100%;
      }
    }
  }

  .address--wrap {
    display: grid;
    grid-template-columns: 1fr 100px;
    background: $--color-placeholder;
    border-radius: $--radius;
  }

  address {
    background: $--color-placeholder;
    border-radius: $--radius;
    padding: .75em 1.25em;
    font-size: 13px;
    font-style: normal;

    .store-name {
      font-weight: $--font-weight-bold;
      font-size: 14px;
    }
    .store-phone {
      color: $--color-primary;
    }
    > * {
      &:not(:first-child) {
        margin-top: .35em;
      }
    }
  }
}
.skus-grid {
  display: grid;
  grid-gap: 1em;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));

  .sku {
    cursor: pointer;
    display: flex;
    padding: .5em;
    align-items: center;
    border: $--border-light;
    border-radius: $--radius;

    &.selected {
      border-color: $--color-primary;
    }
    > * {
      min-width: 0;
    }
    > .preview {
      flex: 1;
      ::v-deep {
        .variant-name {
          font-weight: $--font-weight-bold;
        }
      }
    }
    > .price {
      filter: blur(3px);
    }
  }
}
</style>
