import { set as vset } from 'vue'
import { BUYER_EMAIL_GROUPS, REGION_TYPES, SOCKET_BASE_URL } from '~/settings'
import { update, merge } from '@/utilities/store'
import { pick, keyBy } from 'lodash'

export const state = () => {
  return {
    announcements: [],
    user: null,
    headers: null,
    payments: null,
    isReviewingTerms: null,
    isLPDomain: null,
    isBrokerDomain: null
  }
}

export const getters = {
  rum (s, g) {
    if (!g.logged) return null
    let user = {
      id: g.user?.id,
      email: g.user?.email,
      name: `${g.user?.first_name} ${g.user?.last_name}`,
      role: g.role
    }
    switch (g.role) {
      case 'vendor':
        user = {
          ...user,
          vendor_company_id: g.user?.vendor_company_id,
          vendor_company_name: g.user?.vendor_company_name
        }
        break
      case 'buyer':
        user = {
          ...user,
          buyer_company_id: g.user?.buyer_company_id,
          buyer_company_name: g.user?.buyer_company_name,
          store_id: g.user?.store_id,
          store_name: g.user?.store_name
        }
        break
    }
    return user
  },

  announcements: s => s.announcements || [],
  lastAnnouncement: (s, g) => g.announcements[0] || null,
  firstAnnouncement: (s, g) => g.announcements[g.announcements.length - 1] || null,

  user: s => s.user,
  headers: s => s.headers,
  socketUrl: s => {
    const headers = new URLSearchParams(s.headers).toString()

    return `${SOCKET_BASE_URL}?${headers}`
  },
  logged: s => !!s.user,
  role: s => s.headers?.role || 'guest',
  apiRole: (s, g) => {
    return {
      buyer: 'buyer',
      head_buyer: 'headBuyer',
      vendor: 'vendor',
      broker: 'broker',
      logistics_partner: 'lp'
    }[g.role]
  },
  isHeadBuyer: (s, g) => g.role === 'head_buyer',
  isBuyer: (s, g) => g.role === 'buyer',
  isSubBuyer: (s, g) => g.role === 'buyer' && !!g.user?.sub_buyer,
  isWarehouseHeadBuyer: (s, g) => {
    if (!g.isHeadBuyer) return false
    return g?.user?.region_types?.includes(REGION_TYPES.WAREHOUSED)
  },
  isPodDirectHeadBuyer: (s, g) => {
    if (!g.isHeadBuyer) return false
    return g?.user?.region_types?.includes(REGION_TYPES.PFD)
  },
  isWarehouseBuyer: (s, g) => {
    if (!g.isBuyer) return false
    return g?.user?.store?.region_type === REGION_TYPES.WAREHOUSED
  },
  isPodDirectBuyer: (s, g) => {
    if (!g.isBuyer) return false
    return g?.user?.store?.region_type === REGION_TYPES.PFD
  },
  isVendor: (s, g) => g.role === 'vendor' || g.role === 'broker',
  isBroker: (s, g) => g.role === 'broker',
  isLP: (s, g) => g.role === 'logistics_partner',
  isWarehouseLP (s, g) {
    if (!g.isLP) return false
    const roles = g.user?.roles_name || []
    return roles.includes('warehousing')
  },
  isDriveLP (s, g) {
    if (!g.isLP) return false
    const roles = g.user?.roles_name || []
    return roles.includes('driver')
  },
  isPodFreightLP (s, g) {
    if (!g.isLP) return false
    return !!g.user?.pod_freight_partner
  },
  isLPDomain: s => !!s.isLPDomain,
  isBrokerDomain: s => !!s.isBrokerDomain,
  isReviewingTerms: s => s.isReviewingTerms,
  didAgreeToTerms: s => s.user ? s.user.did_agree_to_terms : true,
  didAgreeToPolicies: s => s.user ? s.user.did_agree_to_policies : true,
  personal: (s, g) => {
    const user = g.user
    if (!user) return null
    return pick(user, [
      'email',
      'first_name',
      'last_name',
      'contact_number',
      'ein',
      'website'
    ])
  },
  store: (s, g) => g.user?.store || null,
  address: (s, g) => g.store?.address_attributes || null,
  orderId: s => s.user?.order_id || null,
  buyerId: (s, g) => g.isBuyer && g.user ? s.user.id : null,
  vendorCompanyId: (s, g, rs, rg) => {
    if (!g.isVendor) return null
    const vendorCompanySelected = rg['broker/vendorCompanySelected']

    return g.isBroker ? vendorCompanySelected?.id : s.user?.vendor_company_id
  },
  toDashboard: (s, g) => {
    switch (g.role) {
      case 'broker':
      case 'vendor':
        return { name: 'vendors-orders' }
      case 'buyer':
        return { name: 'buyers-orders' }
      case 'head_buyer':
        return { name: 'head-buyer-orders' }
      case 'logistics_partner':
        return { name: 'lp-orders' }
      default:
        return null
    }
  },

  toSettings: (s, g) => {
    switch (g.role) {
      case 'broker':
      case 'vendor':
        return { name: 'vendors-settings' }
      case 'buyer':
        return { name: 'buyers-settings' }
      case 'head_buyer':
        return { name: 'head-buyer-settings' }
      case 'logistics_partner':
        return { name: 'lp-settings' }
      default:
        return { name: 'index' }
    }
  },

  redirectTo: (s, g, rs, rg) => {
    const isNeeded = rg['onboarding/isNeeded']

    switch (g.role) {
      case 'broker':
      case 'vendor':
        return { name: 'vendors-orders' }

      case 'head_buyer':
        return { name: 'products' }

      case 'buyer':
        if (isNeeded) return { name: '_buyer_onboarding' }

        return { name: 'products' }

      case 'logistics_partner':
        return { name: 'lp-orders' }

      default:
        return { name: 'index' }
    }
  },

  payments: s => s.user ? s.user.payments : null,
  paymentOptions: (s, g, rs, rg) => {
    const res = []
    const cart = rg['cart/order']
    const invoiceable = cart ? !!cart.store_invoice_option : false
    const source = g.payments ? g.payments.source : null
    if (source) {
      switch (source.object) {
        case 'bank_account':
          res.push({
            id: 'bank_or_cc',
            default: true,
            name: `Pay by ${source.bank_name} ending in ${source.last4}`,
            message: 'Your bank account will be charged 3 days after fulfillment of each delivery.'
          })
          break
        case 'card':
          res.push({
            default: true,
            id: 'bank_or_cc',
            name: `${source.brand} ending in ${source.last4}`,
            message: 'Your card will be charged 3 days after fulfillment of each delivery.'
          })
          break
      }
    } else {
      res.push({
        id: 'create',
        name: 'Pay via credit card'
      })
    }
    if (invoiceable) {
      res.push({
        id: 'invoice',
        name: 'Pay by invoice'
      })
    }
    return res
  },

  uneditableEmailGroupIds: g => g.user?.uneditable_email_group_ids || [],

  isApprovedCompany: (s, g) => {
    switch (g.role) {
      case 'buyer':
      case 'head_buyer': return !!g.user?.buyer_company_approved

      default:
        return true
    }
  },

  headBuyerHasAssignedRegions: (s, g) => {
    return g.isHeadBuyer && (!!g.user?.has_active_stores || !!g.user?.has_assigned_regions)
  },

  buyerEmailGroupIds: g => g.user?.buyer_email_group_ids || [],
  buyerEmailGroups (s, g) {
    if (!g.isBuyer) return []
    return Object.keys(BUYER_EMAIL_GROUPS)
      .map(id => ({
        ...BUYER_EMAIL_GROUPS[id],
        active: g.buyerEmailGroupIds.includes(+id),
        editable: !g.uneditableEmailGroupIds.includes(+id)
      }))
  },
  isPreApprovalBuyer: (s, g) => {
    if (!g.isBuyer) return false

    // Check by Buyer Company Approved
    if (!g.user?.buyer_company_approved) return true

    // For Credit Limit
    if (typeof g.user?.credit_limit_value !== 'number') return true

    return false
  },

  // true if buyer is the only buyer in its store
  isMasterBuyer: (s, g) => {
    if (!g.isBuyer) return false
    return +g.user?.store_number_of_buyers === 1
  },

  notiGroupIds: s => s.user?.notification_group_ids || [],
  notiGroups (s, g, rs, rg) {
    const notiGroups = rg['master/notiGroups'] || []

    return notiGroups.map(noti => ({
        ...noti,
        active: g.notiGroupIds.includes(+noti.id)
      }))
  },
  vendorEmailGroupIds: s => s.user?.vendor_email_group_ids || [],
  vendorEmailGroups (s, g, rs, rg) {
    if (!g.isVendor) return []

    const emailGroups = keyBy(rg['master/vendorEmailGroups'], e => e.id)

    return Object.keys(emailGroups)
        .map(id => ({
          ...emailGroups[id],
          active: g.vendorEmailGroupIds.includes(+id),
          editable: !g.uneditableEmailGroupIds.includes(+id)
        }))
  },
  brokerEmailGroupIds: s => s.user?.broker_email_group_ids || [],
  brokerEmailGroups (s, g, rs, rg) {
    if (!g.isBroker) return []

    const emailGroups = keyBy(rg['master/brokerEmailGroups'], e => e.id)

    return Object.keys(emailGroups)
        .map(id => ({
          ...emailGroups[id],
          active: g.brokerEmailGroupIds.includes(+id),
          editable: !g.uneditableEmailGroupIds.includes(+id)
        }))
  },
  stripeConnectType: (s) => s.user?.stripe_connect_type,
  allowShopifyIntegration: (s) => s.user?.allow_shopify_integration || false
}

export const mutations = {
  update,
  merge,

  clear (state) {
    state.announcements = []
    state.user = null
    state.headers = null
    state.payments = null
  },

  updateUser (state, user) {
    state.user = user
    // this.$cookies.set('user', user)
  },

  updateHeaders (state, headers) {
    state.headers = headers
    this.$cookies.set('headers', headers)
  },

  agreeToTerms (state, status = true) {
    if (!state.user) return
    vset(state.user, 'did_agree_to_terms', status)
  }
}

export const actions = {
  clear ({ commit, dispatch }) {
    commit('clear')
    commit('preferences/reset', null, { root: true })
    commit('cart/reset', null, { root: true })
    commit('notifications/reset', null, { root: true })
    commit('history/reset', null, { root: true })

    this.$cookies.remove('user')
    this.$cookies.remove('headers')
    if (this.$rum) this.$rum.resync()
    if (this.$amplitude?.instance) {
      this.$amplitude.instance.reset()
      this.$amplitude.sync()
    }
  },

  logout ({ dispatch }, context) {
    dispatch('clear')
    // show login page first
    if (context?.redirect)
      context?.redirect({ name: 'login' })
    else
      this.$router.push({ name: 'login' })?.catch(this.$debug.log)
    // then do a full resync
    return dispatch('master/sync', null, { root: true })
  },

  login ({ commit, dispatch, getters, rootGetters }, params) {
    let role = params.user_role

    return this.$api.authentication
      .login(params)
      .then(({ data, headers }) => {
        let user = data[role]

        if (user.is_head_buyer)
          role = 'head_buyer'

        user = { ...user, role }

        const headersStore = {
          'access-token': headers['access-token'],
          uid: headers.uid,
          client: headers.client,
          role,
          order_id: user.order_id
        }

        if (getters.isBrokerDomain) {
          let vendorCompany = rootGetters['preferences/byKey']('lastVendorCompany')

          if (!vendorCompany) {
            vendorCompany = data.first_vendor_company_id
            commit('preferences/update', { lastVendorCompany: vendorCompany }, { root: true })
          }

          headersStore['vendor-company-id'] = vendorCompany
          dispatch('broker/getBrokerCompanies', undefined, { root: true })
        }

        commit('updateUser', user)
        commit('updateHeaders', headersStore)
        commit('preferences/update', {
          $lastUserEmail: params.email,
          $lastUserRole: params.user_role
        }, { root: true })
        dispatch('master/clientSync', undefined, { root: true })
      })
  },

  getAnnouncements ({ commit }) {
    return this.$api.common.getAnnouncements()
      .then(announcements => {
        commit('update', {
          announcements
        })
      })
  },

  markAnnouncementAsRead ({ commit }, item) {
    return this.$api.common
      .markAnnouncementAsRead(item)
      .then(announcements => {
        commit('update', {
          announcements
        })
      })
  },

  getBuyerStores ({ commit, state }) {
    return this.$api.buyer.getStores()
      .then(res => {
        commit('updateUser', {
          ...state.user,
          ...res
        })
      })
  },

  updateBuyerStoreInformation ({ commit, state }, data) {
    return this.$api.buyer
      .updateStoreInformation(data)
      .then(res => {
        commit('updateUser', {
          ...state.user,
          store: res.store
        })
      })
  },

  getPaymentInformation ({ commit, state, getters }) {
    const request = this.$api[getters.apiRole]?.getPaymentInformation

    if (!request) return Promise.reject(new Error('Not supported user role! (getPaymentInformation)'))

    return request()
      .then(res => {
        commit('updateUser', {
          ...state.user,
          payments: res.result
        })
      })
  },

  updatePaymentInformation ({ commit, state, getters }, data) {
    const request = this.$api[getters.apiRole]?.updatePaymentInformation

    if (!request) return Promise.reject(new Error('Not supported user role! (updatePaymentInformation)'))

    return request(data)
      .then(res => {
        commit('updateUser', {
          ...state.user,
          payments: res.result
        })
      })
  },

  createPaymentInformation ({ commit, state, getters }, data) {
    const request = this.$api[getters.apiRole]?.createPaymentInformation

    if (!request) return Promise.reject(new Error('Not supported user role! (createPaymentInformation)'))

    return request(data)
      .then(res => {
        commit('updateUser', {
          ...state.user,
          payments: res.result
        })
      })
  },

  deletePaymentInformation ({ commit, state, getters }) {
    const request = this.$api[getters.apiRole]?.deletePaymentInformation

    if (!request) return Promise.reject(new Error('Not supported user role! (deletePaymentInformation)'))

    return request()
      .then(res => {
        commit('updateUser', {
          ...state.user,
          payments: res.result
        })
      })
  },

  getPersonalInformation ({ commit, state, getters }) {
    const request = this.$api[getters.apiRole]?.getPersonalInformation

    if (!request) return Promise.resolve({})

    return request()
      .then(res => {
        commit('updateUser', {
          role: getters.role,
          ...state.user,
          ...res
        })
      })
  },

  updatePersonalInformation ({ commit, state, getters }, data) {
    const request = this.$api[getters.apiRole]?.updatePersonalInformation

    if (!request) return Promise.reject(new Error('Not supported user role! (updatePersonalInformation)'))

    return request(data)
      .then(res => {
        const userData = res[getters.role] || res

        commit('updateUser', {
          ...state.user,
          ...userData
        })

        commit('updateHeaders', {
          ...getters.headers,
          uid: userData.email
        })
      })
  }
}
