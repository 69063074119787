import objectToFormData from '~/utilities/object-to-formdata'

export default ({ store, $axios, app }) => ({
  getTestimonials: () => {
    return app.$contentful.client
      .getEntries({
        content_type: 'testimonial',
        order: '-sys.createdAt',
        select: [
          'fields.name',
          'fields.broker',
          'fields.link',
          'fields.content',
          'fields.logo',
          'fields.cover',
          'fields.featured',
          'fields.type',
          'fields.priority',
          'fields.published'
        ].join(',')
      })
      .then(res => res.items
        .map(i => ({
          ...(i?.fields || {}),
          logo: i?.fields?.logo?.fields?.file,
          cover: i?.fields?.cover?.fields?.file
        }))
      )
  },

  getPrivacyPolicy: () => $axios.$get('/privacy_policy.json')
    .then(res => res.privacy_policy),

  getTermOfUse: () => $axios.$get('/terms_of_use.json')
    .then(res => res.terms_of_use),

  acceptAgreements (data) {
    const role = store.getters['authentication/apiRole']
    const endpoint = {
      buyer: '/buyer/term_agreement.json',
      headBuyer: '/head_buyer/term_agreement.json',
      vendor: '/vendor/term_agreement.json',
      lp: '/logistics_partner/term_agreement.json'
    }[role]

    if (!endpoint) return Promise.resolve()

    return $axios.$post(endpoint, data)
  },

  getAnnouncements () {
    const role = store.getters['authentication/apiRole']
    const endpoint = {
      buyer: '/buyer/announcements.json',
      headBuyer: '/head_buyer/announcements.json',
      vendor: '/vendor/announcements.json',
      broker: '/vendor/announcements.json',
      lp: '/logistics_partner/announcements.json'
    }[role]

    if (!endpoint) return Promise.resolve([])

    return $axios
      .$get(endpoint)
      .then(res => res.results)
  },

  markAnnouncementAsRead ({ id }) {
    const role = store.getters['authentication/apiRole']
    const endpoint = {
      buyer: `/buyer/announcements/${id}/mark_as_read.json`,
      headBuyer: `/head_buyer/announcements/${id}/mark_as_read.json`,
      vendor: `/vendor/announcements/${id}/mark_as_read.json`,
      broker: `/vendor/announcements/${id}/mark_as_read.json`,
      lp: `/logistics_partner/announcements/${id}/mark_as_read.json`
    }[role]
    if (!endpoint) return Promise.resolve([])
    return $axios
      .$post(endpoint)
      .then(res => res.results)
  },

  master () {
    const role = store.getters['authentication/apiRole']
    const endpoint = {
      vendor: '/vendor/supports/master_datas.json',
      broker: '/broker/supports/master_datas.json',
      buyer: '/buyer/supports/master_datas.json',
      lp: '/logistics_partner/supports/master_datas.json'
    }[role] || '/supports/master_datas.json'
    return $axios.$get(endpoint)
  },

  getBrandDetails (id) {
    const role = store.getters['authentication/apiRole']
    const endpoint = {
      buyer: `/buyer/brands/${id}.json`,
      vendor: `/vendor/brands/${id}.json`,
      broker: `/vendor/brands/${id}.json`
    }[role] || `/brands/${id}.json`
    return $axios.$get(endpoint).then(({ brand }) => brand)
  },

  claims: (data) =>
    $axios.$post('/claims.json', objectToFormData({ claim: data })),

  submitRequestAccessForm: form =>
    $axios.$post('/monday_forms/inbound_registrations.json', objectToFormData({
      inbound_registration: form
    })),

  submitLogisticsPartnerForm: form =>
    $axios.$post('/monday_forms/logistics_partners.json', {
      logistics_partner: form
    }),

  submitStateOfGroceryForm: form =>
    $axios.$post('/monday_forms/state_of_groceries.json', {
      state_of_grocery: form
    }),

  submit3PLLeadForm: form =>
    $axios.$post('/pod3pl_inquiries.json', form)
})
