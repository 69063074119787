export default function ({ store, redirect, error, route }) {
  const logged = store.getters['authentication/logged']
  const isHeadBuyer = store.getters['authentication/isHeadBuyer']

  if (logged) {
    if (!isHeadBuyer) return error({ status: 403 })
  } else {
    store.dispatch('authentication/logout')
    redirect({
      name: 'login',
      query: {
        to: route.fullPath
      }
    })
  }
}
