<template lang="pug">
el-form(ref="form" :model="form" :rules="rules")
  el-row(:gutter="16")
    el-col(:sm="12")
      el-form-item.boxed.full-name(label="Name" prop="full_name")
        el-input(v-model="form.full_name" ref="focus")
    el-col(:sm="12")
      el-form-item.boxed.attn(label="Attn" prop="attn")
        el-input(v-model="form.attn")

  el-row(:gutter="16")
    el-col(:sm="12")
      el-form-item.boxed.street-1(label="Street" prop="street1")
        el-input(v-model="form.street1")
    el-col(:sm="12")
      el-form-item.boxed.street-2(label="Apt, Suite, etc." prop="street2")
        el-input(v-model="form.street2")

  el-row(:gutter="16")
    el-col(:sm="12")
      el-form-item.boxed.city(label="City" prop="city")
        el-input(v-model="form.city")
    el-col(:sm="12")
      el-form-item.boxed.state(label="State" prop="address_state_id")
        address-state-select.db(v-model="form.address_state_id")

  el-row(:gutter="16")
    el-col(:sm="12")
      el-form-item.boxed.street-1(label="Postal/Zip" prop="zip")
        el-input(v-model="form.zip")
    el-col(:sm="12")
      el-form-item.boxed.street-2(label="Phone number" prop="phone_number")
        el-input(v-model="form.phone_number")
</template>

<script>

import { mapSyncers } from '~/utilities/normalizers'

export default {
  component: 'ShippingAddressForm',

  props: {
    value: {
      type: Object,
      required: true,
      default: () => ({})
    }
  },

  data () {
    return {
      rules: {
        full_name: { required: true, message: 'Required' },
        street1: { required: true, message: 'Required' },
        city: { required: true, message: 'Required' },
        address_state_id: { required: true, message: 'Required' },
        zip: { required: true, message: 'Required' },
        phone_number: this.$validators.phone
      }
    }
  },

  computed: {
    ...mapSyncers({
      form: 'value'
    })
  },

  methods: {
    validate (cb) {
      return this.$refs.form.validate(cb)
    }
  }
}
</script>
