<template lang="pug">
  a.link-tag(:href="link" target="_blank")
    slot
    i.bx.bx-link-external.bx-5
</template>

<script>
export default {
  props: {
    link: {
      type: [String],
      default: null
    }
  }
}
</script>

<style lang="scss" scoped>
.link-tag {
  cursor: pointer;
  color: $--color-primary;

  > i.bx {
    vertical-align: middle;
    margin-left: .5em;
  }
}
</style>
