<template lang="pug">
el-dialog(
  custom-class="page__dialog pre-order-modal"
  visible
  :disabled="saving"
  :close-on-click-modal="false"
  @close="$emit('close')")

  .page__dialog-header.page__dialog-header--separated(slot="title")
    .page__dialog-preview
      i.page__dialog-icon.bx.bx-calendar-check

    .page__dialog-info
      .page__dialog-title Pre-order
      .page__dialog-description These items only available for pre-order.

  .groups
    .group(
      v-for="gk in orderedRegionTypes"
      :key="gk"
      v-if="groups[gk]")
      region-type-stamp.type(:type="gk" size="small")
      .skus
        .sku(v-for="v, vk in groups[gk]" :key="vk")
          .preview
            product-variant-stamp.plain(
              :data="v"
              showImage
              read-only
              show-product
              show-variant-name
              show-price
              show-price-change
              show-case-units)
          .quantity
            el-input-number.pf-block(
              v-model="quantities[v.variants_region_id]"
              :min="0"
              size="small")

  .page__dialog-footer(slot="footer")
    el-button(
      type="primary"
      @click="submit"
      :disabled="submittable === 0 || saving") Pre Order

</template>

<script>
import { groupBy, sum } from 'lodash'
import { PRODUCT_AVAILABILITY, REGION_TYPES } from '~/settings'

import RegionTypeStamp from '~/components/stamps/RegionTypeStamp'
import ProductVariantStamp from '~/components/stamps/ProductVariantStamp'
export default {
  components: {
    ProductVariantStamp,
    RegionTypeStamp
  },

  props: {
    product: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      quantities: {},
      rules: {
      },
      saving: false
    }
  },

  computed: {
    orderedRegionTypes () {
      return [
        REGION_TYPES.PFD,
        REGION_TYPES.WAREHOUSED
      ]
    },

    groups () {
      const items = Object
        .values(this.product.variants)
        .filter(i => i.availability === PRODUCT_AVAILABILITY.COMING_SOON)
      return groupBy(items, 'region_type')
    },

    submittable () {
      return sum(Object.values(this.quantities))
    }
  },

  watch: {
    'product.variants': {
      immediate: true,
      handler (val = {}) {
        this.quantities = Object.values(val)
          .reduce((res, i) => ({
            ...res,
            [i.variants_region_id]: 0
          }), {})
      }
    }
  },

  methods: {
    submit () {
      const items = Object
        .keys(this.quantities)
        .filter(id => this.quantities[id] > 0)
        .map(id => ({
          variants_region_id: id,
          quantity: this.quantities[id]
        }))
      this.saving = true
      this.$api.buyer
        .createPreOrder({
          order: {
            line_items_attributes: items
          }
        })
        .then(res => {
          this.$emit('done', res)
          this.$emit('close')
        })
        .finally(() => {
          this.saving = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .pre-order-modal {
    max-width: 600px;
  }
}
.groups {
  .group {
    &:not(:last-child) {
      margin-bottom: 1.5em;
    }
    .skus {
      margin-top: .5em;
      > .sku {
        display: flex;
        padding: .5em 0;
        align-items: center;
        &:not(:last-child) {
          border-bottom: $--border-light;
        }
        > * {
          min-width: 0;
        }
        > .preview {
          flex: 1;
          ::v-deep {
            .variant-name {
              font-weight: $--font-weight-bold;
            }
          }
        }
        > .quantity {
          flex: 0 120px;
        }
      }
    }
  }
}
</style>
