import Vue from 'vue'
import 'chartjs-adapter-date-fns'
import 'chartjs-adapter-moment'
import Gradient from 'chartjs-plugin-gradient'
// import DataLabels from 'chartjs-plugin-datalabels'
// import Labels from '~/utilities/chartjs-plugin-labels'

import {
  Pie,
  Line,
  Bar,
  Doughnut
} from 'vue-chartjs/legacy'

import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  PointElement,
  BarElement,
  LineElement,
  ArcElement,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  TimeScale,
  LineController,
  Filler
} from 'chart.js'

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  PointElement,
  BarElement,
  LineElement,
  ArcElement,
  CategoryScale,
  LinearScale,
  LogarithmicScale,
  TimeScale,
  LineController,
  Filler,
  Gradient
)

Vue.component('LineChart', { extends: Line })
Vue.component('BarChart', { extends: Bar })
Vue.component('DoughnutChart', { extends: Doughnut })
Vue.component('PieChart', { extends: Pie })
