<script>
import { InputNumber } from 'element-ui'

export default {
  mixins: [InputNumber],

  methods: {
    handleBlur (event) {
      if (this.currentValue === undefined)
        this.setCurrentValue(this.min || 0)
      this.$emit('blur', event)
    }
  }
}
</script>
