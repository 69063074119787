export default {
  inject: ['track', 'notify', 'collapse'],

  props: {
    keyword: {
      type: String,
      default: null
    },

    selection: {
      type: Number,
      default: 0
    }
  },

  watch: {
    records: {
      immediate: true,
      handler (val) {
        this.notify(this.sectionName, val?.length || 0)
      }
    }
  }
}
