<script>
import { Popconfirm } from 'element-ui'

export default {
  mixins: [Popconfirm],

  props: {
    confirmButtonText: {
      type: String,
      default: 'Yes'
    },
    cancelButtonText: {
      type: String,
      default: 'No'
    }
  }
}
</script>
