<template lang="pug">
el-dialog#sample-request-modal(
  custom-class="page__dialog pre-order-modal"
  visible
  :disabled="saving"
  :close-on-click-modal="false"
  @close="$emit('close')")

  .page__dialog-header.page__dialog-header--separated(slot="title")
    .page__dialog-preview
      i.page__dialog-icon.bx.bxs-check-circle

    .page__dialog-info
      .page__dialog-title Add items to cart
      .page__dialog-description

  .groups
    .group(v-for="g, gk in groups" :key="gk")
      region-type-stamp.type(:type="gk" size="small")
      .skus
        .sku(v-for="v, vk in g" :key="vk")
          .preview
            product-variant-stamp.plain(
              :data="v"
              showImage
              read-only
              show-product
              show-variant-name
              show-price
              show-price-change
              show-case-units)
          .quantity
            el-input-number.pf-block(
              v-model="quantities[v.variants_region_id]"
              :min="0"
              :disabled="!v.cartable"
              size="small")
            .negative.mt-1(v-if="!v.cartable") (This flavor is not available!)

  .bottom-sticky
    el-button(
      type="primary"
      @click="submit"
      :loading="saving"
      :disabled="submittable === 0") Add
</template>

<script>
import { groupBy, orderBy, sum, uniqBy } from 'lodash'
import { REGION_TYPES } from '~/settings'
import ProductVariantStamp from '~/components/stamps/ProductVariantStamp'
import RegionTypeStamp from '~/components/stamps/RegionTypeStamp'

export default {
  components: {
    ProductVariantStamp,
    RegionTypeStamp
  },
  props: {
    visible: {
      type: Boolean,
      default: false
    },

    lineItems: {
      type: Array,
      default: () => []
    }
  },

  data () {
    return {
      quantities: {},
      rules: {},
      saving: false
    }
  },

  computed: {
    title () {
      return 'Add items to cart'
    },

    groups () {
      const items = uniqBy(this.lineItems, 'variants_region_id')
        .map(i => ({ ...i, FE_region_type: i.deliverable?.delivery_type === 'pod_grow' ? REGION_TYPES.PFG : i.region_type }))
      const sorted = orderBy(items, ['cartable'], ['desc'])

      return groupBy(sorted, 'FE_region_type')
    },

    submittable () {
      return sum(Object.values(this.quantities))
    }
  },

  watch: {
    lineItems: {
      immediate: true,
      handler (val = {}) {
        this.quantities = Object.values(val)
          .reduce((res, i) => ({
            ...res,
            [i.variants_region_id]: i.cartable ? 1 : 0
          }), {})
      }
    }
  },

  methods: {
    submit () {
      this.$amplitude.track('CLICK_ADD_TO_CART', {
        zone: 'DASHBOARD_ORDER_DETAILS_REORDER_MODAL',
        quantity: Object.values(this.quantities).reduce((sum, v) => sum + v, 0)
      })

      this.saving = true

      const variants = Object
        .values(this.lineItems)
        .filter(i => this.quantities[i.variants_region_id] > 0)
      const cartItems = Object
        .keys(this.quantities)
        .filter(id => this.quantities[id] > 0)
        .map(id => ({
          variants_region_id: id,
          quantity: this.quantities[id]
        }))

      return this.$workflow
        .addItemsToCart({
          variants,
          cartItems
        })
        .then(res => {
          this.$emit('done', res)
          this.$emit('close')
        })
        .catch(this.$debug.log)
        .finally(() => {
          this.saving = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.page__dialog-info {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.bottom-sticky {
  position: sticky;
  bottom: 0;
  z-index: 3;
  background: white;
  padding: 1em 0;
  margin-bottom: -1.5em;
  text-align: right;
}
.groups {
  .group {
    &:not(:last-child) {
      margin-bottom: 1.5em;
    }
    .skus {
      margin-top: .5em;
      > .sku {
        display: flex;
        padding: .5em 0;
        align-items: center;
        &:not(:last-child) {
          border-bottom: $--border-light;
        }
        > * {
          min-width: 0;
        }
        > .preview {
          flex: 1;
          ::v-deep {
            .variant-name {
              font-weight: $--font-weight-bold;
            }
          }
        }
        > .quantity {
          flex: 0 120px;
          @include media(xs-only) {
            flex: 1;
          }
        }
        @include media(xs-only) {
          flex-direction: column;
          align-items: flex-start;
        }
      }

      .negative {
        color: $--color-danger;
        font-size: 12px
      }
    }
  }
}
</style>
