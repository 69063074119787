import ElDatePicker from './DatePicker'
import ElTimeSelect from './TimeSelect'
import ElPopconfirm from './Popconfirm'
import ElInputNumber from './InputNumber'
import ElSelect from './Select'
import ElTableColumn from './TableColumn'

export default {
  ElDatePicker,
  ElTimeSelect,
  ElPopconfirm,
  ElInputNumber,
  ElSelect,
  ElTableColumn
}
