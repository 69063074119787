<script>
import { mapGetters } from 'vuex'
import Select from './BaseSelect'

export default {
  name: 'BuyerCompanySelect',
  extends: Select,

  props: {
    filterable: {
      type: Boolean,
      default: true
    },

    api: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    ...mapGetters({
      role: 'authentication/role',
      apiRole: 'authentication/apiRole'
    })
  },

  methods: {
    search (...args) {
      switch (this.role) {
        case 'vendor':
        case 'broker':
          return this.$api[this.apiRole].getBuyerCompanies(...args)
      }
      return Promise.reject(new Error('No handler provided'))
    },

    fetch (args) {
      switch (this.role) {
        case 'vendor':
        case 'broker':
          /* eslint-disable */
          const modifier = {...args, 'q[buyer_company_id]': args.id }
          return this.$api[this.apiRole].getBuyerCompanies(modifier)
      }
      return Promise.reject(new Error('No handler provided'))
    }
  }
}
</script>
