export default function ({ store, redirect, error, route }) {
  const logged = store.getters['authentication/logged']
  const isBuyer = store.getters['authentication/isBuyer']
  if (logged) {
    if (!isBuyer) return error({ status: 403 })
  } else {
    store.dispatch('authentication/logout')
    redirect({
      name: 'login',
      query: {
        to: route.fullPath
      }
    })
  }
}
